import PropTypes from 'prop-types';
import { Button, Col, Row, Space, TimePicker, theme } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { FORMAT_TIME } from '../../config/constants';
import { useTranslation } from 'react-i18next';
import './ChildrenCollapse.scss';

dayjs.extend(customParseFormat);
const { useToken } = theme;

const ChildrenCollapse = ({
  addActivitiesTo,
  day,
  activities,
  removeActivitiesTo,
  updateActivities,
  ref,
}) => {
  const { t } = useTranslation();
  const { token } = useToken();
  const onChange = (date, dateString, activity) => {
    updateActivities(day, activity, dateString);
  };
  return (
    <Row
      gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}
      ref={ref}
      className="collapse-container"
    >
      {activities[day].map((activity) => (
        <Col xs={24} lg={12} key={activity.key}>
          <Row style={{ margin: 0 }}>
            <Col xs={18} sm={20} md={20} lg={19} xl={20} xxl={20}>
              <TimePicker.RangePicker
                inputReadOnly={true}
                changeOnBlur={true}
                onChange={(date, dateString) =>
                  onChange(date, dateString, activity)
                }
                value={[
                  activity?.from ? dayjs(activity.from, FORMAT_TIME) : '',
                  activity?.to ? dayjs(activity.to, FORMAT_TIME) : '',
                ]}
                style={{
                  width: '100%',
                  color: token.inputTextColor,
                }}
                className="timepickerClass"
                placeholder={[t('from'), t('to')]}
                format={FORMAT_TIME}
              />
            </Col>
            <Col
              className="gutter-row"
              xs={6}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              style={{
                textAlign: 'end',
              }}
            >
              <Space>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => addActivitiesTo(day)}
                />
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => removeActivitiesTo(day, activity.key)}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

ChildrenCollapse.propTypes = {
  addActivitiesTo: PropTypes.func.isRequired,
  day: PropTypes.number.isRequired,
  activities: PropTypes.object.isRequired,
  removeActivitiesTo: PropTypes.func.isRequired,
  updateActivities: PropTypes.func.isRequired,
};

export default ChildrenCollapse;
