import { useContext, useRef, useEffect } from 'react';
import { Modal, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styles from './ModalAdmin.module.scss';
import { getVars } from '../../config/environment';
import { MessageContext } from '../../context/MessageContext';
import CustomPasswordInput from '../CustomPasswordInput';

const { useToken } = theme;

const ModalAdmin = ({ visible, handleOk }) => {
  const { t } = useTranslation();
  const { token } = useToken();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { notification } = useContext(MessageContext);
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value.toLowerCase());
  };

  const onSubmit = () => {
    const password = getValues('password');
    console.log('password', password);
    console.log('adminPass', getVars().adminPass);
    if (getVars().adminPass === password) {
      handleOk();
    } else {
      notification('error', t('admin_invalid_password'));
      navigate('/');
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  const handleKeyPress = (e) => {
    if (e.key == 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  if (visible) {
    return (
      <Modal
        title={`${t('enter_password')}`}
        okText={t('enter')}
        cancelText={t('cancel')}
        open={visible}
        onOk={onSubmit}
        onCancel={handleCancel}
        width={350}
      >
        <CustomPasswordInput
          register={register}
          token={token}
          errors={errors}
          handleInputChange={handleInputChange}
          registerName={'password'}
          placeholder={t('password')}
          containerClass={styles.inputContainer}
          ref={inputRef}
          onKeyDown={handleKeyPress}
        />
      </Modal>
    );
  }
};

export default ModalAdmin;
