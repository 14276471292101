import { put, takeLatest, select } from "redux-saga/effects";
import { failure, request, success } from "../../reducers/signup/signUpSlice";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { success as successPlansTarget } from "../../reducers/plansTarget/plansTargetSlice";

import { auth } from "../../../firebase";
import {
  success as successUser,
  data,
  readyForNextState,
} from "../../reducers/users/usersSlice";
import { upsertDataByEmail } from "../../../firestore";
import { plannerHours } from "../../../utils/plannerHours";

const handleFailure = function* () {
  yield put(
    failure({
      response: { message: "user.alreadyregistered", status: 400 },
    })
  );
};

const handleSuccess = function* (user, input, plan) {
  if (input.wMode === "local") {
    yield put(successPlansTarget(plan));
  }

  yield put(success());
  yield put(successUser({ ...input, ...user }));
  yield put(readyForNextState());
};

const handleLocalSignUp = function* (input) {
  const { data: dataPlans } = yield select((state) => state.plans);

  let dataCopy = { ...dataPlans };

  const { targetHours, hoursWeekly, minutesWeekly } = plannerHours(
    input.periodicity,
    input.hours
  );

  const plan = {
    ...dataCopy,
    [input.email]: {
      targetHours,
      hoursWeekly,
      minutesWeekly,
    },
  };

  yield* handleSuccess({}, input, plan);
};

const handleFirebaseSignUp = function* (input) {
  try {
    const { user } = yield createUserWithEmailAndPassword(
      auth,
      input.email,
      input.password
    );

    yield updateProfile(auth.currentUser, {
      displayName: input.displayName,
      photoURL: JSON.stringify({
        hours: input.hours,
        periodicity: input.periodicity,
        wMode: input.wMode,
      }),
    });

    const { targetHours, hoursWeekly, minutesWeekly } = plannerHours(
      input.periodicity,
      input.hours
    );

    yield upsertDataByEmail("plansTarget", input.email, {
      targetHours,
      hoursWeekly,
      minutesWeekly,
    });

    yield* handleSuccess(user, input);
  } catch (error) {
    console.error(error);
    yield* handleFailure();
  }
};

export function* signUpSaga({ payload: input }) {
  const cleanInput = { ...input, email: input.email.toLowerCase() };

  if (input.wMode === "local") {
    const currentData = yield select(data);
    const alreadyRegistered = Object.keys(currentData).includes(
      cleanInput.email
    );
    if (alreadyRegistered) {
      yield* handleFailure();
      return;
    } else {
      yield* handleLocalSignUp(cleanInput);
    }
  } else {
    yield* handleFirebaseSignUp(cleanInput);
  }
}

export default function* signup() {
  yield takeLatest(request, signUpSaga);
}
