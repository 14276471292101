import { put, takeLatest, select } from "redux-saga/effects";
import { failure, request, success } from "../../reducers/logout/logoutSlice";
import { logout as logoutAction } from "../../reducers/users/usersSlice";
import { signOut } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { resetState as resetStateActivities } from "../../reducers/activities/getActivitiesSlice";
import { resetState as resetStatePlans } from "../../reducers/plans/getPlansSlice";

export function* logoutSaga({ payload: input }) {
  const users = yield select((state) => state.users.data);
  const alreadyRegistered = Object.keys(users).includes(input.email);

  if (alreadyRegistered) {
    try {
      const auth = getAuth();

      yield put(resetStateActivities());
      yield put(resetStatePlans());

      yield signOut(auth);

      yield put(success());
      yield put(logoutAction(input));
    } catch (error) {
      const errorMessage = "user.errorLogout";
      yield put(failure({ message: errorMessage, status: 500 }));
    }
  } else {
    const error = { message: "user.noregistered", status: 400 };
    yield put(failure(error));
  }
}

export default function* logout() {
  yield takeLatest(request, logoutSaga);
}
