import { put, select, takeLatest } from 'redux-saga/effects';
import {
  failureSend,
  requestSend,
  successSend,
} from '../../reducers/comments/commentsSlice';
import { upsertDataByEmail } from '../../../firestore';

export function* sendCommentsSaga({ payload: input }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const { comment, email } = input;

    if (wMode === 'local') {
      yield put(
        successSend({
          email,
          comment,
        }),
      );
      return;
    } else {
      const sendCommentsFirestore = yield upsertDataByEmail(
        'userComments',
        email,
        comment,
      );

      if (sendCommentsFirestore.success) {
        yield put(
          successSend({
            email,
            comment,
          }),
        );
        return;
      }

      throw new Error('error_send_comments');
    }
  } catch (error) {
    yield put(failureSend({ message: error.message, status: 500 }));
  }
}

export default function* sendComments() {
  yield takeLatest(requestSend, sendCommentsSaga);
}
