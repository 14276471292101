import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Col,
  Collapse,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  theme,
  Tooltip,
  Tour,
  Typography,
  Grid,
} from 'antd';
import { ExpandAltOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { MessageContext } from '../../context/MessageContext';
import useGetPlans from './useGetPlans';
import useTour from './useTour';
import useGetPlansTarget from './useGetPlansTarget';
import usePlanner from './usePlanner';
import styles from './Planner.module.scss';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title } = Typography;

const Planner = () => {
  const { t } = useTranslation();
  const { xs } = useBreakpoint();
  const { notification } = useContext(MessageContext);
  const [monthFromCalendar, setMonthFromCalendar] = useState();
  const navigate = useNavigate();
  useGetPlans();
  const { getSteps, hideTour, showTour, ref1, ref2, ref3, ref4 } = useTour();
  const { targetHours, targetHoursShowed } = useGetPlansTarget();
  const {
    planHours,
    planHoursShowed,
    schedule,
    activities,
    changeUpdateFromToday,
    generateSchedule,
    generateNextSchedule,
    setSchedule,
    resetActivities,
    days,
    replaceMonthPlan,
    addMonthPlan,
  } = usePlanner();
  const { token } = useToken();
  const [toggleCollapse, setToggleCollapse] = useState([]);
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [activitiesExistInCurrentMonth, setActivitiesExistInCurrentMonth] =
    useState(false);
  const location = useLocation();
  const [openTour, setOpenTour] = useState(showTour());
  const [removeAllPlans, setRemoveAllPlans] = useState(true);
  const [removeAllPlansWorked, setRemoveAllPlansWorked] = useState(false);
  const [removeAllPlansMonth, setRemoveAllPlansMonth] = useState(false);
  const [showAlertGeneratingPlanning, setShowAlertGeneratingPlanning] =
    useState(false);

  const [tapCount, setTapCount] = useState(0);

  const handleForceRefresh = () => {
    setTapCount((prevCount) => prevCount + 1);
    if (tapCount + 1 === 10) {
      window.alert('Cleaning...');
      localStorage.clear();
      window.location.reload();
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const handleReset = () => {
    if (!removeAllPlans && !removeAllPlansWorked && !removeAllPlansMonth) {
      return;
    }
    resetActivities(
      removeAllPlans,
      removeAllPlansWorked,
      removeAllPlansMonth,
      monthFromCalendar,
    );
    setOpen(false);
    notification('success', t('remove_plan_successfully'));
    navigate('/planner', {
      replace: true,
    });
  };

  const panelStyle = {
    textTransform: 'capitalize',
  };

  const classPlanHours = () => {
    if (planHours < targetHours / 2) {
      return styles.low_plan;
    }
    if (planHours >= targetHours / 2 && planHours < targetHours) {
      return styles.middle_plan;
    }
    if (planHours >= targetHours) {
      return styles.high_plan;
    }
  };

  const handleChangeSchedule = (value) => {
    setSchedule(value);
  };

  const onhandleGenerate = () => {
    if (planHours < targetHours) {
      setShowAlertGeneratingPlanning(true);
      return;
    }
    handleGenerate();
  };

  const handleGenerate = () => {
    if (activities?.length) {
      generateNextSchedule();
    } else {
      generateSchedule();
    }
  };

  const handleClick = () => {
    const allDays = days().map((day) => day.key);
    if (toggleCollapse.length === allDays.length) {
      setToggleCollapse([]);
      setExpand(false);
    } else {
      setExpand(true);
      setToggleCollapse(allDays);
    }
  };

  const onChange = (key) => {
    setToggleCollapse(key);
  };

  const onHandleReplacePlanMonth = () => {
    replaceMonthPlan(monthFromCalendar);
  };

  const onHandleAddPlanMonth = () => {
    addMonthPlan(monthFromCalendar);
  };

  const onHandleCancel = () => {
    navigate('/', {
      state: {
        date: monthFromCalendar,
      },
    });
  };

  useEffect(() => {
    if (expand && activities?.length > 0) {
      const allDays = days().map((day) => day.key);
      setToggleCollapse(allDays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand]);

  useEffect(() => {
    if (activities?.length > 0) {
      setExpand(true);
    }
    window.scrollTo(0, 0);
    setTimeout(() => {
      setOpenTour(showTour());
    }, 750);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.date) {
      setMonthFromCalendar(moment(location?.state?.date));
      if (activities.length > 0) {
        const existActivities = activities?.some((activity) => {
          return (
            moment(activity.start).month() ===
              moment(location?.state?.date).month() &&
            moment(activity.start).year() ===
              moment(location?.state?.date).year()
          );
        });
        setActivitiesExistInCurrentMonth(existActivities);
      }
    } else {
      setMonthFromCalendar(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={styles.planner} data-testid="planner">
      <Modal
        title={`${t('alert_generate_plan1')} ${planHoursShowed} ${t(
          'alert_generate_plan2',
        )} ${targetHoursShowed} ${t('alert_generate_plan3')}`}
        open={showAlertGeneratingPlanning}
        onOk={handleGenerate}
        onCancel={() => setShowAlertGeneratingPlanning(false)}
        okText={t('alert_generate_plan_ok')}
        cancelText={t('alert_generate_plan_cancel')}
      />

      <Row align="middle" justify={'space-between'}>
        <Col>
          <Title
            level={2}
            style={{ color: token.titleTextColor }}
            className={styles.title}
            ref={ref1}
            onClick={handleForceRefresh}
          >
            {t('goal')}: {targetHoursShowed}h/s
          </Title>
        </Col>
        <Col>
          <Title
            type="secondary"
            level={3}
            style={{ color: token.subtitleTextColor }}
            className={styles.subtitle}
            ref={ref2}
          >
            {t('plan')}:{' '}
            <span className={classPlanHours()}>{planHoursShowed}h/s</span>
          </Title>
        </Col>
        <Col>
          <Space size={5}>
            {activities?.length > 0 && (
              <Tooltip title={t('reset_plan')}>
                <Button
                  type="primary"
                  shape="circle"
                  onClick={showModal}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            )}
            <Modal
              title={t('remove_plan')}
              open={open}
              onOk={handleReset}
              onCancel={hideModal}
              okText={t('yes')}
              cancelText={t('no')}
              style={{}}
            >
              <Row align="middle" justify={'end'} gutter={15}>
                <Col>
                  <Title
                    level={5}
                    style={{ color: token.titleTextColor, margin: 0 }}
                  >
                    {t('delete_all_my_planning')}
                  </Title>
                </Col>
                <Col>
                  <Switch
                    checkedChildren={t('yes')}
                    unCheckedChildren={t('no')}
                    defaultChecked={true}
                    checked={removeAllPlans}
                    onChange={(value) => {
                      setRemoveAllPlans(value);
                      setRemoveAllPlansMonth(value ? false : true);
                    }}
                  />
                </Col>
              </Row>{' '}
              <Row align="middle" justify={'end'} gutter={15}>
                <Col>
                  <Title
                    level={5}
                    style={{ color: token.titleTextColor, margin: 0 }}
                  >
                    {t('remove_all_plans')}
                  </Title>
                </Col>
                <Col>
                  <Switch
                    checkedChildren={t('yes')}
                    unCheckedChildren={t('no')}
                    defaultChecked={false}
                    onChange={(value) => {
                      setRemoveAllPlansWorked(value);
                    }}
                  />
                </Col>
              </Row>
              {monthFromCalendar && (
                <Row align="middle" justify={'end'} gutter={15}>
                  <Col>
                    <Title
                      level={5}
                      style={{ color: token.titleTextColor, margin: 0 }}
                    >
                      {`${t('delete_planning_month')} ${moment(
                        monthFromCalendar,
                      ).format('MMMM')}`}
                    </Title>
                  </Col>
                  <Col>
                    <Switch
                      checkedChildren={t('yes')}
                      unCheckedChildren={t('no')}
                      defaultChecked={false}
                      checked={removeAllPlansMonth}
                      onChange={(value) => {
                        setRemoveAllPlansMonth(value);
                        setRemoveAllPlans(value ? false : true);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Modal>
            <Button
              type="primary"
              shape="circle"
              style={{
                background: expand ? token.colorPrimary : token.backgroundColor,
                color: expand ? 'white' : token.colorText,
              }}
              icon={<ExpandAltOutlined />}
              onClick={handleClick}
            />
          </Space>
        </Col>
      </Row>

      <Collapse
        items={days(panelStyle, ref3)}
        activeKey={toggleCollapse}
        onChange={onChange}
        className={
          token.isDarkMode ? styles.collapse_dark : styles.collapse_light
        }
      />

      {xs && (
        <div style={{ marginTop: 16 }}>
          <Row align="middle" justify={'space-between'}>
            <Col>
              <Title
                level={2}
                style={{ color: token.titleTextColor }}
                className={styles.title}
                ref={ref1}
                onClick={handleForceRefresh}
              >
                {t('goal')}: {targetHoursShowed}h/s
              </Title>
            </Col>
            <Col>
              <Title
                type="secondary"
                level={3}
                style={{ color: token.subtitleTextColor }}
                className={styles.subtitle}
                ref={ref2}
              >
                {t('plan')}:{' '}
                <span className={classPlanHours()}>{planHoursShowed}h/s</span>
              </Title>
            </Col>
          </Row>
        </div>
      )}
      {activities?.length > 0 && !monthFromCalendar && (
        <Row
          align="middle"
          style={{
            marginTop: 10,
            gap: '8px',
            flexFlow: 'nowrap',
            justifyContent: 'flex-end',
          }}
        >
          <Col xs={21} md={22}>
            <Title
              level={5}
              style={{
                color: token.titleTextColor,
                margin: 0,
              }}
              className={styles.questionUpdatePlan}
            >
              {t('update_plan')}
            </Title>
          </Col>
          <Col xs={3} md={1} style={{ textAlign: 'end' }}>
            <Switch
              checkedChildren={t('yes')}
              unCheckedChildren={t('no')}
              onChange={changeUpdateFromToday}
            />
          </Col>
        </Row>
      )}

      {!monthFromCalendar && (
        <Row align="middle" justify="end" style={{ marginTop: 16 }}>
          <Col xs={17} md={10} lg={9} xl={6} xxl={6}>
            <Title level={4} style={{ color: token.titleTextColor, margin: 0 }}>
              {t('gen_plan')}{' '}
              <Select
                defaultValue={schedule}
                onChange={handleChangeSchedule}
                style={{ width: 60, textAlign: 'center' }}
                options={[
                  { value: '1', label: '1' },
                  { value: '2', label: '2' },
                  { value: '3', label: '3' },
                  { value: '4', label: '4' },
                  { value: '5', label: '5' },
                  { value: '6', label: '6' },
                  { value: '7', label: '7' },
                  { value: '8', label: '8' },
                  { value: '9', label: '9' },
                  { value: '10', label: '10' },
                  { value: '11', label: '11' },
                  { value: '12', label: '12' },
                ]}
              />{' '}
              {t('months')}
            </Title>
          </Col>
          <Col xs={7} md={4} lg={3} xl={2} xxl={2}>
            <Button
              type="primary"
              htmlType="submit"
              className={[styles.roundedButton]}
              style={{ width: '-webkit-fill-available' }}
              onClick={onhandleGenerate}
              ref={ref4}
            >
              {activities?.length > 0 ? t('update') : t('generate')}
            </Button>
          </Col>
        </Row>
      )}

      {monthFromCalendar && activitiesExistInCurrentMonth && (
        <Row
          align="middle"
          justify="end"
          style={{ marginTop: 16, gap: '20px' }}
        >
          <Col xs={24}>
            <Title
              level={4}
              style={{
                color: token.titleTextColor,
                margin: 0,
                textAlign: 'end',
              }}
            >
              {t('planMonthQuestion')}
            </Title>
          </Col>
          <Col xs={7} md={4} lg={3} xl={2} xxl={2}>
            <Button
              type="secondary"
              htmlType="button"
              className={[styles.roundedButton, styles.button__border]}
              style={{ width: '-webkit-fill-available' }}
              onClick={onHandleCancel}
            >
              {t('cancel')}
            </Button>
          </Col>
          <Col xs={7} md={4} lg={3} xl={2} xxl={2}>
            <Button
              type="primary"
              htmlType="button"
              className={[styles.roundedButton]}
              style={{ width: '-webkit-fill-available' }}
              onClick={onHandleReplacePlanMonth}
            >
              {t('replace')}
            </Button>
          </Col>
          <Col xs={7} md={4} lg={3} xl={2} xxl={2}>
            <Button
              type="primary"
              htmlType="button"
              className={[styles.roundedButton]}
              style={{ width: '-webkit-fill-available' }}
              onClick={onHandleAddPlanMonth}
            >
              {t('add')}
            </Button>
          </Col>
        </Row>
      )}

      {monthFromCalendar && !activitiesExistInCurrentMonth && (
        <Row
          align="middle"
          justify="end"
          style={{ marginTop: 16, gap: '20px' }}
        >
          <Col xs={7} md={4} lg={3} xl={2} xxl={2}>
            <Button
              type="secondary"
              htmlType="button"
              className={[styles.roundedButton, styles.button__border]}
              style={{ width: '-webkit-fill-available' }}
              onClick={onHandleCancel}
            >
              {t('cancel')}
            </Button>
          </Col>
          <Col xs={7} md={4} lg={3} xl={2} xxl={2}>
            <Button
              type="primary"
              htmlType="button"
              className={[styles.roundedButton]}
              style={{ width: '-webkit-fill-available' }}
              onClick={onHandleAddPlanMonth}
            >
              {t('accept')}
            </Button>
          </Col>
        </Row>
      )}

      <Tour
        className="tour"
        type="primary"
        open={openTour}
        onClose={() => {
          setOpenTour(false);
          hideTour();
        }}
        steps={getSteps()}
      />
    </div>
  );
};

Planner.propTypes = {};

export default Planner;
