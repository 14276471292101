import { useEffect, useState } from 'react';
import { Tour, theme } from 'antd';
import moment from 'moment';
import CustomCalendar from '../../components/CustomCalendar';
import useCalendar from './useCalendar';
import { FORMAT_TIME } from '../../config/constants';
import CustomModalTimePicker from '../../components/CustomModalTimePicker/CustomModalTimePicker';
import InfoHours from '../../components/InfoHours';
import useMonth from './useMonth';
import useTour from '../../components/CustomCalendar/tour/useTour';
import useAppState from '../../hooks/useAppState';
import './Home.scss';
import 'react-quill/dist/quill.snow.css';
import CustomRichText from '../../components/CustomRichText';
import { useComments } from './useComments';

const { useToken } = theme;

const Home = () => {
  const { getMonthData, currentDate, month } = useMonth();
  const [success, setSucess] = useState(false);
  const {
    results,
    handleAddResults,
    handleUpdateResults,
    deleteEventById,
    activityLoading,
  } = useCalendar({ handleSucces: () => setSucess(true) });

  const { getSteps, hideTour, showTour, ref1, ref2, ref3, ref4 } = useTour();
  const [openTour, setOpenTour] = useState(false);

  const { getComments, sendComments, comment, loading } = useComments();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      setOpenTour(showTour());
    }, 1000);
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMonthData({ date: moment(currentDate) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  useEffect(() => {
    if (!activityLoading) {
      setIsModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityLoading]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventSelected, setEventSelected] = useState();
  const [eventStart, setEventStart] = useState(null);
  const [eventEnd, setEventEnd] = useState(null);
  const { token } = useToken();
  const { isAssetsEnable } = useAppState();

  const handleOk = (id, event) => {
    if (id) {
      console.log('id', id, event);
      handleUpdateResults(id, event);
    } else {
      handleAddResults(event);
    }
    getMonthData({ date: moment(event.start) });
  };

  const handleCancel = () => {
    setEventStart();
    setEventEnd();
    setIsModalOpen(false);
    setSucess(false);
  };

  const openModal = (item) => {
    setEventSelected(item);
    const start = moment(item?.start).format(FORMAT_TIME);
    const end = moment(item?.end).format(FORMAT_TIME);
    setEventStart(start);
    setEventEnd(end);
    setIsModalOpen(true);
  };

  const saveComments = (a) => {
    sendComments(a);
  };

  return (
    <div className="homeWP" data-testid="home">
      <CustomModalTimePicker
        visible={isModalOpen}
        event={eventSelected}
        start={eventStart}
        end={eventEnd}
        handleCancel={handleCancel}
        handleOk={handleOk}
        token={token}
        deleteEventById={deleteEventById}
        getMonthData={getMonthData}
        currentDate={currentDate}
        assetsEnable={isAssetsEnable}
        loading={activityLoading}
        success={success}
      />
      <CustomCalendar
        events={results.filter((activity) => !activity.disabled)}
        getMonthData={getMonthData}
        openModal={(item) => openModal(item)}
        tourRef={{ ref1, ref3, ref4 }}
        currentDate={currentDate}
        month={month}
        assetsEnable={isAssetsEnable}
      />
      {
        <InfoHours
          assetsEnable={isAssetsEnable}
          month={month}
          tourRef={{ ref2, ref4 }}
          currentDate={currentDate}
        />
      }

      <div className="homeWP__richContainer">
        <CustomRichText
          className="homeWP__richContainer__richText"
          placeholder="Comments"
          saveHandler={saveComments}
          value={comment}
          loading={loading}
        />
      </div>

      <Tour
        type="primary"
        className="tour"
        open={openTour}
        onClose={() => {
          setOpenTour(false);
          hideTour();
        }}
        steps={getSteps()}
      />
    </div>
  );
};

Home.propTypes = {};

export default Home;
