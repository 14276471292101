import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hideTour: false,
  hideTourCalendar: false,
  hideHandTourCalendar: false,
  wMode: undefined,
};

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    resetState: (state) => {
      //   state.wMode = initialState.wMode;
      state.hideTourCalendar = initialState.hideTourCalendar;
      state.hideTour = initialState.hideTour;
      state.hideHandTourCalendar = initialState.hideHandTourCalendar;
    },
    cloudMode: (state, action) => {
      state.wMode = action.payload.wMode;
    },
    tourCalendar: (state, action) => {
      state.hideTourCalendar = action.payload.hideTourCalendar;
    },
    tourPlan: (state, action) => {
      state.hideTour = action.payload.hideTour;
    },
    tourSwipe: (state, action) => {
      state.hideHandTourCalendar = action.payload.hideHandTourCalendar;
    },
  },
});

export const { resetState, cloudMode, tourCalendar, tourPlan, tourSwipe } =
  appConfigSlice.actions;

export const wMode = (state) => state.appConfig.wMode;

export const hideTour = (state) => state.appConfig.hideTour;

export const hideTourCalendar = (state) => state.appConfig.hideTourCalendar;

export const hideHandTourCalendar = (state) =>
  state.appConfig.hideHandTourCalendar;

export default appConfigSlice.reducer;
