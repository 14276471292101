import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import esES from 'antd/lib/locale/es_ES';
import enUS from 'antd/lib/locale/en_US';

const CustomDatePicker = (props) => {
  const { i18n } = useTranslation();

  const getAntdLocale = () => {
    switch (i18n.language) {
      case 'es':
        return esES;
      case 'en':
        return enUS;
      default:
        return enUS;
    }
  };

  return (
    <ConfigProvider locale={getAntdLocale()}>
      <DatePicker {...props} />
    </ConfigProvider>
  );
};

export default CustomDatePicker;
