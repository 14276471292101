import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import useAppState from '../../hooks/useAppState';
import { request as requestRefreshCalendar } from '../../store/reducers/refreshCalendar/refreshCalendarSlice';
import { getVersionFromDB, updateVersionInDB } from '../../utils/idbUtils';
import { useTranslation } from 'react-i18next';

export const useRefresh = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userLogged, update } = useAppState();
  const [isHidden, setIsHidden] = useState(document.hidden);
  const countRef = useRef(0);

  const handleRefresh = () => {
    update('loaderActive', true);
    dispatch(requestRefreshCalendar({ email: userLogged.email }));
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsHidden(document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  async function fetchAndCompareVersion() {
    const versionData = await getVersionFromDB();
    const response = await fetch(
      process.env.NODE_ENV === 'development'
        ? '/version.json'
        : 'https://weekplanner.impactotecnologico.net/version.json',
    );
    const jsonData = await response.json();
    const versionFromJson = jsonData.version;

    if (!versionData || versionFromJson > versionData.value) {
      await updateVersionInDB(versionFromJson);
      if (localStorage.getItem('appInstalled') === 'true') {
        localStorage.removeItem('iosBannerShownCount');
        localStorage.removeItem('bannerShownCount');
      }
      alert(t('version'));
      window.location.reload(true);
    }
  }

  useEffect(() => {
    if (!isHidden) {
      if (countRef.current > 0) {
        handleRefresh();
        fetchAndCompareVersion();
      }
      countRef.current = 0;
    }
    countRef.current++;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden]);

  return {
    handleRefresh,
  };
};

export default useRefresh;
