import './locales/i18n';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AppContainer from './AppContainer';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js').then(
      function (registration) {
        console.log('Service Worker registrado con éxito:', registration.scope);
      },
      function (err) {
        console.log('Registro de Service Worker fallido:', err);
      },
    );
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppContainer />
  </BrowserRouter>,
);
