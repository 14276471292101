import { Button, Dropdown } from 'antd';
import {
  CalendarOutlined,
  UserOutlined,
  FormOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CustomMenu.scss';

const CustomMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items = [
    {
      key: '/planner',
      icon: <FormOutlined />,
      label: t('planner'),
    },
    {
      type: 'divider',
    },
    {
      key: '/notebook',
      icon: <MessageOutlined />,
      label: t('myNotes'),
    },
    {
      type: 'divider',
    },
    {
      key: '/profile',
      icon: <UserOutlined />,
      label: t('profile'),
    },
    {
      type: 'divider',
    },
    {
      key: '/',
      icon: <CalendarOutlined />,
      label: t('home'),
    },
  ];

  const onClick = ({ key }) => {
    navigate(key);
  };

  return (
    <Dropdown menu={{ items, onClick }} placement="top" trigger="click" arrow>
      <Button>{t('menu').toUpperCase()}</Button>
    </Dropdown>
  );
};

export default CustomMenu;
