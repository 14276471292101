import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  request,
  readyForNextState,
} from '../../store/reducers/plansTarget/getAllPlansTargetSlice';
// import { request as requestSendPlansTarget } from '../../store/reducers/plansTarget/sendPlansTargetSlice';
import useDeleteAccount from '../../hooks/useDeleteAccount';
import { STATUS } from '../../config/constants';
import { MessageContext } from '../../context/MessageContext';

const usePlansTarget = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deleteAccount } = useDeleteAccount(true);
  const { notification } = useContext(MessageContext);

  const { status, error } = useSelector((state) => state.getAllPlansTarget);
  const { data } = useSelector((state) => state.plansTarget);

  const [plansTarget, setPlansTarget] = useState([]);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (status === STATUS.SUCCESS) {
      setComplete(true);
      dispatch(readyForNextState());
    }
    if (status === STATUS.FAILURE) {
      const { message } = error;
      dispatch(readyForNextState());
      notification('error', t(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setPlansTarget(
      Object.entries(data).map((entry) => ({
        email: entry[0],
        key: entry[0],
        ...entry[1],
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getPlansTarget = () => {
    setComplete(false);
    dispatch(request());
  };

  const resetAccount = (email) => {
    deleteAccount(email, true);
  };

  return {
    plansTarget,
    complete,
    getPlansTarget,
    resetAccount,
    deleteAccount,
  };
};

export default usePlansTarget;
