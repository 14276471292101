import { useContext } from "react";

import { AppContext } from "../context/AppContext";

export const useAppState = () => {
  const [state, setState] = useContext(AppContext);
  const {
    userLogged,
    isReady,
    isMock,
    loaderActive,
    isAssetsEnable,
    language,
  } = state;

  const update = (key, value) => {
    setState((currState) => ({
      ...currState,
      [key]: value,
    }));
  };

  return {
    userLogged,
    isReady,
    loaderActive,
    isMock,
    language,
    isAssetsEnable,
    update,
  };
};

export default useAppState;
