const BASE = {
  baseUrl: process.env.REACT_APP_BASE_URL || '/api/',
  accountGa: process.env.REACT_APP_ACCOUNT_GA || '',
  enableGa: process.env.REACT_APP_ENABLE_GA || '',
  adminPass: process.env.REACT_APP_ADMIN_PASS || '',
  endpoints: {
    companies: '/companies/',
  },
};

export const getVars = () => BASE;
