import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './CustomCalendar.scss';
import { FORMAT_TIME } from '../../config/constants';
import CustomToolbar from './components/CustomToolBar';
import useResponsive from '../../hooks/useResponsive';
import {
  EventDuration,
  HeaderMonthCell,
  HeaderWeekCell,
  StandartEvent,
} from './components/Events';
import CustomToolbarDesktop from './components/CustomToolbarDesktop';
import useAppState from '../../hooks/useAppState';
import { getMaxDate, getMinDate } from '../../utils/dateUtils';
import CustomModalMonth from '../CustomModalMonth';
import useUpdateActivityMonth from '../../pages/home/useUpdateActivityMonth';
import { useSwipeable } from 'react-swipeable';
import SwipeHint from '../SwipeHint';
import useTour from './tour/useTour';

const { useToken } = theme;

const CustomCalendar = ({
  events,
  assetsEnable,
  getMonthData,
  currentDate,
  openModal,
  tourRef,
  month,
}) => {
  const localizer = momentLocalizer(moment);
  const { t, i18n } = useTranslation();
  const clickRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sendActivityMonth } = useUpdateActivityMonth({
    currentDate,
    setIsModalOpen,
  });

  const { isMobile, isTablet } = useResponsive();
  const context = useAppState();
  const { token } = useToken();
  const [culture] = useState('es');

  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [view, setView] = useState('month');

  const { hideTourCalendar, showHandTour } = useTour();

  const [showSwipeHint, setShowSwipeHint] = useState(showHandTour());

  useEffect(() => {
    setShowSwipeHint(showHandTour());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHandTour()]);

  const statusColorMap = {
    0: token.colorPrimary,
    1: token.mediumAlert1,
    2: token.okAlert,
    3: token.ok,
    4: token.highAlert,
    default: token.okAlert,
  };

  const isDateInPast = (date) => new Date(date) < new Date();

  const getBackgroundColor = (start, status) =>
    isDateInPast(start) && status === 0
      ? statusColorMap[4]
      : statusColorMap[status] || statusColorMap.default;

  const CustomToolbarWithTourRef = (props) => (
    <CustomToolbar
      {...props}
      setIsModalOpen={setIsModalOpen}
      tourRef={tourRef}
      events={events}
      openModal={openModal}
      isBeforeOfPlanned={month.isBeforeOfPlanned}
    />
  );
  const CustomToolbarDesktopWithTourRef = (props) => (
    <CustomToolbarDesktop
      {...props}
      setIsModalOpen={setIsModalOpen}
      tourRef={tourRef}
      events={events}
      openModal={openModal}
      isBeforeOfPlanned={month.isBeforeOfPlanned}
    />
  );

  const handleSwipeLeft = () => {
    if (isMobile || isTablet) {
      const nextMonth = moment(currentDate).add(1, view).toDate();
      getMonthData({ date: nextMonth });
    }
  };

  const handleSwipeRight = () => {
    if (isMobile || isTablet) {
      const prevMonth = moment(currentDate).subtract(1, view).toDate();
      getMonthData({ date: prevMonth });
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });

  useEffect(() => {
    setMinDate(getMinDate());
    setMaxDate(getMaxDate());
  }, [events]);

  const handleNavigate = (newDate, view, action) => {
    if (action === 'NEXT') {
      if (newDate <= maxDate) {
        getMonthData({ date: moment(newDate) });
      }
    } else if (action === 'PREV') {
      if (newDate >= minDate) {
        getMonthData({ date: moment(newDate) });
      }
    } else {
      getMonthData({ date: moment(newDate) });
    }
  };

  const { messages } = useMemo(() => {
    const translations = i18n.getResourceBundle(culture, 'translations');
    moment.defineLocale(culture, { week: { dow: 1 } });
    const i18nMessages = {
      ...translations,
      showMore: (total) => ` ${total}${t('more')}`,
    };
    return {
      messages: i18nMessages,
    };
  }, [culture, i18n, t]);

  const formats = {
    timeGutterFormat: FORMAT_TIME,
    eventTimeRangeFormat: ({ start, end }) => {
      return `${moment(start).format(FORMAT_TIME)} - ${moment(end).format(
        FORMAT_TIME,
      )}`;
    },
  };

  useEffect(() => {
    moment.defineLocale(i18n.language, { week: { dow: 1 } });
    return () => {
      window.clearTimeout(clickRef?.current);
    };
  }, [i18n.language]);

  const showModal = useCallback(
    (event) => {
      window.clearTimeout(clickRef?.current);
      clickRef.current = window.setTimeout(() => {
        openModal(event);
      }, 200);
    },
    [openModal],
  );

  const onSelectEvent = useCallback(
    (event) => {
      showModal(event);
    },
    [showModal],
  );

  const onDoubleClickEvent = useCallback(
    (event) => {
      showModal(event);
    },
    [showModal],
  );

  const handleSelectSlot = useCallback(
    (event) => {
      const firstPlannedDay = events.find((item) => item.startPlanned);
      if (
        moment(event.start).isBefore(
          moment(firstPlannedDay.startAccessor).startOf('month'),
        )
      ) {
        return;
      }
      if (moment(event.start).hour() === 0) {
        event.start = moment(event.start).set('hour', moment().hour()).toDate();
      }
      event.end = moment(event.start)
        .set('hour', moment(event.start).add(1, 'h').hour())
        .toDate();
      showModal(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showModal],
  );

  return (
    <div
      className={
        token.isDarkMode ? 'calendar_home_dark' : 'calendar_home_light'
      }
      ref={tourRef.ref1}
      {...handlers}
    >
      {hideTourCalendar && showSwipeHint && (
        <div className="swipe-hint-backdrop">
          <SwipeHint
            className="calendar__swipe-hint"
            setShowSwipeHint={setShowSwipeHint}
          />
        </div>
      )}

      <Calendar
        culture={context.language}
        localizer={localizer}
        onNavigate={handleNavigate}
        events={events.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }))}
        startAccessor="start"
        endAccessor="end"
        components={{
          month: {
            dateHeader: HeaderMonthCell,
            event: StandartEvent,
          },
          day: {
            event: StandartEvent,
          },
          week: {
            header: HeaderWeekCell,
            event: isMobile ? EventDuration : StandartEvent,
          },
          toolbar:
            isMobile || isTablet
              ? CustomToolbarWithTourRef
              : CustomToolbarDesktopWithTourRef,
        }}
        eventPropGetter={(d) => {
          let newStyle = {
            backgroundColor: getBackgroundColor(d.start, d.status),
            color: 'white',
            borderRadius: '10px',
            textAlign: 'center',
          };

          return {
            style: newStyle,
          };
        }}
        formats={{
          ...formats,
          weekdayFormat: (date, culture, localizer) => {
            return localizer.format(date, 'ddd', culture);
          },
        }}
        messages={messages}
        onDoubleClickEvent={onDoubleClickEvent}
        onSelectEvent={onSelectEvent}
        onSelectSlot={handleSelectSlot}
        onView={(view) => setView(view)}
        selectable
        date={currentDate}
        defaultDate={new Date()}
        views={['month', 'week', 'day']}
      />

      <CustomModalMonth
        visible={isModalOpen}
        month={month}
        assetsEnable={assetsEnable}
        handleCancel={() => setIsModalOpen(false)}
        handleOk={(newMonthData) => sendActivityMonth(newMonthData)}
      />
    </div>
  );
};

export default CustomCalendar;
