import { useContext, useState } from 'react';
import { Button, Col, Input, Row, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import logo from '../../assets/images/logo.png';
import styles from './SignIn.module.scss';
import { emailPattern } from '../../utils/utils';
import useSignIn from './useSignIn';
import ImageComponent from '../../components/ImageComponent';
import CustomPasswordInput from '../../components/CustomPasswordInput/CustomPasswordInput';
import { MessageContext } from '../../context/MessageContext';

const { useToken } = theme;

const SignIn = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const [errorResetPassword, setErrorResetPassword] = useState(false);
  const { notification } = useContext(MessageContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const { singInRequest, resetPassword } = useSignIn();

  const onSubmit = async (data) => {
    console.log('data', data);
    await singInRequest(data);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setValue(name, value.toLowerCase());
    } else {
      setValue(name, value);
    }
  };

  const onResetPassword = async () => {
    const email = getValues('email');
    if (emailPattern.test(email)) {
      setErrorResetPassword(false);
      const requestResetPassword = await resetPassword(email);
      if (requestResetPassword) {
        notification('success', t('request_reset_password_success'));
      } else {
        notification('error', t('request_reset_password_error'));
      }
    } else {
      setErrorResetPassword(true);
    }
  };

  return (
    <Content style={{ height: '100vh' }}>
      <Row justify="center">
        <ImageComponent alt="logo" src={logo} className={styles.logo} />
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={4}>
            <div className={styles.inputContainer}>
              <Input
                {...register('email', {
                  required: {
                    value: true,
                    message: t('required'),
                  },
                  pattern: {
                    value: emailPattern,
                    message: t('valid_email'),
                  },
                })}
                status={errors.email ? 'error' : 'validating'}
                placeholder={t('email')}
                className={styles.roundedInput}
                style={{
                  backgroundColor: token.inputBackgroundColor,
                  color: token.inputTextColor,
                }}
                onChange={handleInputChange}
              />
              {errors.email && (
                <span className={styles.errorMessage}>
                  {errors.email.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={4}>
            <div className={styles.inputContainer}>
              <CustomPasswordInput
                register={register}
                token={token}
                errors={errors}
                handleInputChange={handleInputChange}
                registerName={'password'}
                placeholder={t('password')}
              />
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={24}
            sm={8}
            md={8}
            xl={8}
            xxl={4}
            className={styles.buttonCenter}
          >
            <Button
              type="primary"
              htmlType="submit"
              className={[styles.roundedButton]}
            >
              {t('signin')}
            </Button>
          </Col>
        </Row>
      </form>

      <Row
        justify="center"
        style={{ flexDirection: 'column', alignItems: 'center' }}
        className={styles.simpleCenterText}
      >
        {errorResetPassword && (
          <span style={{ color: token.highAlert1 }}>
            {t('error_reset_password')}
          </span>
        )}
        <span
          className={`${styles.forceHand}`}
          style={{ color: token.textColor }}
          onClick={onResetPassword}
        >
          {t('reset_password')}
        </span>
      </Row>
      <Row justify="center">
        <a
          href="/sign-up"
          className={`${styles.simpleCenterText} ${styles.forceHand}`}
          style={{ color: token.textColor }}
        >
          {t('dont_have_an_account')}
        </a>
      </Row>
    </Content>
  );
};

SignIn.propTypes = {};

export default SignIn;
