import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/activities/getActivitiesSlice';
import { request as requestRefreshCalendar } from '../../reducers/refreshCalendar/refreshCalendarSlice';
import { findDataByEmail } from '../../../firestore';
import { success as succesActivities } from '../../reducers/activities/activitiesSlice';

export function* getActivitiesSaga({ payload: { email } }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const activities = yield select((state) => state.activities.data);

    if (wMode === 'local') {
      if (activities[email]?.length > 0) {
        yield put(succesActivities(activities));
        yield put(success());
        return;
      }
      throw new Error('error_get_calendar_local');
    }

    const activitiesFirestore = yield findDataByEmail('activities', email);

    if (activitiesFirestore.success && activitiesFirestore.data.length > 0) {
      yield put(
        succesActivities({ ...activities, [email]: activitiesFirestore.data }),
      );
      yield put(success());
    } else {
      throw new Error('error_get_calendar');
    }
  } catch (error) {
    yield put(
      failure({
        message: error.message,
        status: error.message === 'error_get_calendar' ? 404 : 500,
      }),
    );
  }
}

export default function* getActivities() {
  yield takeLatest(request, getActivitiesSaga);
  yield takeEvery(requestRefreshCalendar, getActivitiesSaga);
}
