import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/comments/commentsSlice';
import { request as requestRefreshCalendar } from '../../reducers/refreshCalendar/refreshCalendarSlice';

import { findDataByEmail } from '../../../firestore';

export function* getCommentsSaga({ payload: { email } }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);

    if (wMode === 'local') {
      const { data } = yield select((state) => state.comments);
      const commentsUser = data[email];

      if (data[email]) {
        yield put(success({ email, comment: commentsUser }));
      } else {
        yield put(success({ email, comment: '' }));
      }
    } else {
      const commentsFirestore = yield findDataByEmail('userComments', email);

      if (commentsFirestore.success && commentsFirestore.data) {
        yield put(success({ email, comment: commentsFirestore.data }));
      } else if (commentsFirestore.message === 'Document not found') {
        yield put(success({ email, comment: '' }));
      }
    }
  } catch (error) {
    yield put(failure({ message: error.message, status: 500 }));
  }
}

export default function* getComments() {
  yield takeLatest(request, getCommentsSaga);
  yield takeEvery(requestRefreshCalendar, getCommentsSaga);
}
