import { put, select, takeLatest } from "redux-saga/effects";
import { failure, request, success } from "../../reducers/plans/plansSlice";

export function* plansSaga({ payload: input }) {
  const { data } = yield select((state) => state.plans);
  let dataCopy = { ...data };
  const { email, planning } = input;

  if (email && planning) {
    //const { data } = response;
    dataCopy[email] = planning;
    yield put(success(dataCopy));
  } else {
    const error = { message: "error_send_plans", status: 500 };

    yield put(failure(error));
  }
}

export default function* plans() {
  yield takeLatest(request, plansSaga);
}
