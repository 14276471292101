import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../../../config/constants";

const initialState = {
  data: {},
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.data = undefined;
    },
    resetStateByEmail: (state, action) => {
      state.status = STATUS.NONE;
      delete state.data[action.payload.email];
    },
    success: (state, action) => {
      state.data = action.payload;
    },
    updateState: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  resetState,
  resetStateByEmail,
  success,
  updateState,
} = activitiesSlice.actions;

export const data = (state) => state.activities.data;

export default activitiesSlice.reducer;
