import { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Tooltip, theme } from 'antd';
import logo from '../../assets/images/logo.png';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import styles from './SignUp.module.scss';
import { useForm } from 'react-hook-form';
import { emailPattern } from '../../utils/utils';
import useSingUp from './useSignUp';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import ImageComponent from '../../components/ImageComponent';
import CustomPasswordInput from '../../components/CustomPasswordInput/CustomPasswordInput';
import { Link } from 'react-router-dom';
import { BsPatchExclamationFill } from 'react-icons/bs';

const { useToken } = theme;

const SignUp = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [accept, setAccept] = useState(false);

  const { singUpRequest } = useSingUp();

  const onSubmit = (data) => {
    singUpRequest(data);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setValue(name, value.toLowerCase());
    } else {
      setValue(name, value);
    }
  };

  const acceptTerms = (e) => {
    setAccept(e.target.checked);
  };

  return (
    <Content style={{ minHeight: '100vh', marginBottom: 50 }}>
      <Row justify="center">
        <ImageComponent alt="logo" src={logo} className={styles.logo} />
      </Row>
      <Form name="signup-control" onFinish={handleSubmit(onSubmit)}>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Input
                {...register('displayName', {
                  required: { value: true, message: t('required') },
                })}
                status={errors.displayName ? 'error' : 'validating'}
                placeholder={t('name')}
                className={styles.roundedInput}
                style={{
                  backgroundColor: token.inputBackgroundColor,
                  color: token.inputTextColor,
                }}
                onChange={handleInputChange}
              />
              {errors.displayName && (
                <span className={styles.errorMessage}>
                  {errors.displayName.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Input
                placeholder={t('email')}
                {...register('email', {
                  required: {
                    value: true,
                    message: t('required'),
                    pattern: {
                      value: emailPattern,
                      message: t('valid_email'),
                    },
                  },
                })}
                status={errors.email ? 'error' : 'validating'}
                className={styles.roundedInput}
                style={{
                  backgroundColor: token.inputBackgroundColor,
                  color: token.inputTextColor,
                }}
                onChange={handleInputChange}
              />
              {errors.email && (
                <span className={styles.errorMessage}>
                  {errors.email.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <CustomPasswordInput
                register={register}
                token={token}
                errors={errors}
                handleInputChange={handleInputChange}
                registerName={'password'}
                placeholder={t('password')}
              />
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Input
                {...register('hours', {
                  required: { value: true, message: t('required') },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: t('valid_number'),
                  },
                })}
                placeholder={t('hours_goal')}
                className={styles.roundedInput}
                style={{
                  backgroundColor: token.inputBackgroundColor,
                  color: token.inputTextColor,
                }}
                onChange={handleInputChange}
                status={errors.hours ? 'error' : 'validating'}
                type="number"
              />
              {errors.hours && (
                <span className={styles.errorMessage}>
                  {errors.hours.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Form.Item>
                <CustomSelect
                  register={register}
                  setValue={setValue}
                  propertyName="periodicity"
                  placeholder={t('periodicity')}
                  options={[
                    { value: 'weekly', label: t('weekly') },
                    { value: 'monthly', label: t('monthly') },
                    { value: 'yearly', label: t('yearly') },
                  ]}
                  errors={errors}
                  style={{
                    color: token.inputTextColor,
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} sm={7} md={7} xl={7} xxl={7}>
            <div className={styles.inputContainer}>
              <Form.Item>
                <CustomSelect
                  register={register}
                  setValue={setValue}
                  propertyName="wMode"
                  placeholder={t('saveMyData')}
                  options={[
                    { value: 'local', label: t('device') },
                    { value: 'cloud', label: t('cloud') },
                  ]}
                  errors={errors}
                  style={{
                    color: token.inputTextColor,
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={4} sm={1} md={1} xl={1} xxl={1}>
            <Tooltip title={t('saveMyDataInfo')}>
              <BsPatchExclamationFill
                style={{ fontSize: 'large', marginTop: '1.1rem' }}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Checkbox onChange={acceptTerms}>{t('terms1')}</Checkbox>
              <Link
                to="/terms"
                target="_blank"
                style={{
                  textDecoration: 'underline',
                }}
              >
                {t('terms2')}
              </Link>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={24}
            sm={8}
            md={8}
            xl={6}
            xxl={4}
            className={styles.buttonCenter}
          >
            <Button
              type="primary"
              htmlType="submit"
              className={[styles.roundedButton]}
              disabled={!accept}
            >
              {t('signup')}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row justify="center">
        <a
          href="/sign-in"
          className={`${styles.simpleCenterText} ${styles.forceHand}`}
          style={{ color: token.textColor }}
        >
          {t('have_an_account')}
        </a>
      </Row>
    </Content>
  );
};

SignUp.propTypes = {};

export default SignUp;
