import { put, select, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/activities/deleteActivitiesSlice';
import { resetStateByEmail } from '../../reducers/activities/activitiesSlice';
import { deleteDataByEmail } from '../../../firestore';

export function* deleteActivitiesSaga({ payload: input }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);

    const { email } = input;
    if (wMode === 'local') {
      yield put(resetStateByEmail({ email }));
      yield put(success());
      return;
    }

    const activitiesFirestore = yield deleteDataByEmail("activities", email);

    if (activitiesFirestore.success) {
      yield put(resetStateByEmail({ email }));
      yield put(success());
      return;
    }

    throw new Error('error_delete_activities');
  } catch (error) {
    yield put(
      failure({
        message: error.message,
        status: error.message === 'error_delete_activities' ? 404 : 500,
      }),
    );
  }
}

export default function* deleteActivities() {
  yield takeLatest(request, deleteActivitiesSaga);
}
