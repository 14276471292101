import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './reducers';
import rootSaga from './sagas';

export const reduxStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const persistConfig = {
    key: 'root',
    storage,
  };

  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers(reducer),
  );

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [
            'persist/PERSIST',
            'users/success',
            'signin/success',
            'sendActivities/request',
          ],
          ignoredPaths: ['users', 'payload', 'payload.proactiveRefresh'],
        },
      })
        .prepend(sagaMiddleware)
        .concat(logger);
    },
  });

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};
