import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAppState from '../../hooks/useAppState';
import { STATUS } from '../../config/constants';
import { MessageContext } from '../../context/MessageContext';
import {
  request,
  readyForNextState,
} from '../../store/reducers/activities/sendActivitiesSlice';

export const useActivities = ({ handleSendActivities }) => {
  const dispatch = useDispatch();
  const getActivities = useSelector((state) => state.getActivities);
  const { status: statusSendActivities, error: errorSendActivities } =
    useSelector((state) => state.sendActivities);
  const { data } = useSelector((state) => state.activities);
  const { userLogged, update } = useAppState();
  const { notification } = useContext(MessageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDeleteActivity = (id) => {
    setLoading(true);
    const currentResults = [...data[userLogged.email]];
    const index = currentResults.findIndex((result) => result.id === id);

    let currentEvent = { ...currentResults[index] };

    currentEvent.comments = '';

    currentResults[index] = currentEvent;

    dispatch(
      request({
        activities: currentResults,
        email: userLogged.email,
      }),
    );
  };

  useEffect(() => {
    if (statusSendActivities === STATUS.SUCCESS) {
      dispatch(readyForNextState());
      handleSendActivities();
      setLoading(false);
    }

    if (statusSendActivities === STATUS.FAILURE) {
      const { message } = errorSendActivities;
      dispatch(readyForNextState());
      notification('error', t(message));
      handleSendActivities();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSendActivities]);

  useEffect(() => {
    if (getActivities.status === STATUS.SUCCESS) {
      update('loaderActive', false);
    }

    if (getActivities.status === STATUS.FAILURE) {
      update('loaderActive', false);
      const { message } = getActivities.error;
      notification('error', t(message));
      setTimeout(() => {
        navigate('/planner');
      }, 1000);
    }

    update('loaderActive', getActivities.status === STATUS.FETCHING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivities]);

  useEffect(() => {
    if (data[userLogged.email]) {
      setActivities(
        data[userLogged.email].filter((activity) => activity.comments),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    loading,
    activities,
    handleDeleteActivity,
  };
};

export default useActivities;
