import { Navigate } from 'react-router-dom';
import useAppState from '../../hooks/useAppState';

export const ProtectedRoute = ({ children }) => {
  const context = useAppState();
  if (!context.userLogged) {
    return <Navigate to="/sign-in" />;
  }
  return children;
};
