import { put, takeLatest, select } from 'redux-saga/effects';
import {
  getAuth,
  deleteUser,
  // reauthenticateWithCredential,
} from 'firebase/auth';

import {
  failure,
  request,
  success,
} from '../../reducers/deleteAccount/deleteAccountSlice';

export function* deleteAccountSaga({ payload: input }) {
  const users = yield select((state) => state.users.data);
  const { email } = input;
  const alreadyRegistered = Object.keys(users).includes(email);

  if (alreadyRegistered) {
    try {
      if (users[email].wMode === 'cloud') {
        const auth = getAuth();
        const user = auth.currentUser;
        yield deleteUser(user);
      }
      yield put(success());
    } catch (error) {
      const errorMessage = 'user.errorDeleting';
      yield put(failure({ message: errorMessage, status: 500 }));
    }
  } else {
    const error = { message: 'user.noregistered', status: 400 };
    yield put(failure(error));
  }
}

export default function* deleteAccount() {
  yield takeLatest(request, deleteAccountSaga);
}
