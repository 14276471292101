import { Card, Col, Row } from 'antd';
import styles from './InfoHours.module.scss';

const InfoCard = ({ icon: Icon, title, secondTitle, subtitle, style }) => (
  <Card className={styles.cards}>
    <Row align={'top'}>
      <Col xs={6}>
        <Icon className={styles.icon} />
      </Col>
      <Col xs={18}>
        <div className={styles.title} style={style}>
          {title}
        </div>
        {secondTitle && (
          <div className={styles.subtitle} style={style}>
            {secondTitle}
          </div>
        )}
        <div className={styles.subtitle} style={style}>
          {subtitle}
        </div>
      </Col>
    </Row>
  </Card>
);

export default InfoCard;
