import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../../../config/constants";

const initialState = {
  status: STATUS.NONE,
};

export const updateActivitiesSlice = createSlice({
  name: "updateActivities",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
    },
    readyForNextState: (state) => {
      state.status = STATUS.NONE;
      state.error = undefined;
    },
    request: (state) => {
      state.status = STATUS.FETCHING;
    },
    success: (state) => {
      state.status = STATUS.SUCCESS;
    },
    failure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },
  },
});

export const { resetState, readyForNextState, request, success, failure } =
  updateActivitiesSlice.actions;

export const status = (state) => state.updateActivities.status;

export const error = (state) => state.updateActivities.error;

export default updateActivitiesSlice.reducer;
