import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tooltip,
  theme,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useRef, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import styles from './Profile.module.scss';
import { useTranslation } from 'react-i18next';
import useAppState from '../../hooks/useAppState';
import useLogout from '../../hooks/useLogout';
import { useForm } from 'react-hook-form';
import { emailPattern } from '../../utils/utils';
import useProfile from './useProfile';
import CustomPasswordInput from '../../components/CustomPasswordInput/CustomPasswordInput';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { useSelector } from 'react-redux';
import { BsPatchExclamationFill } from 'react-icons/bs';

const { useToken } = theme;

const Profile = () => {
  const { t } = useTranslation();
  const context = useAppState();
  const { token } = useToken();
  const fileInputRef = useRef(null);

  const [userPhoto, setUserPhoto] = useState(null);
  useLogout;
  const { logout } = useLogout();
  const { updateUser, updateProfilePhotoUser, handleDeleteAccount } =
    useProfile();
  const { data } = useSelector((state) => state.users);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      displayName: context.userLogged?.displayName,
      email: context.userLogged?.email,
      hours: context.userLogged?.hours,
      periodicity: context.userLogged?.periodicity,
      // role: context.userLogged?.role,
      wMode: context.userLogged?.wMode,
    },
  });

  const onSubmit = async (data) => {
    if (!data.password) {
      delete data.password;
      delete data.password_confirmation;
    }

    data.previousEmail = context.userLogged?.email;
    updateUser(data);
  };

  useEffect(() => {
    setUserPhoto(data[context.userLogged?.email]?.photo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target.result;
        updateProfilePhotoUser(base64Image);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Content style={{ height: '100%', marginBottom: 40 }}>
      <Row justify="center">
        <Avatar
          size={150}
          className={[styles.avatar, styles.avatarBorder]}
          style={{ borderColor: token.colorPrimary }}
          icon={<UserOutlined />}
          onClick={handleAvatarClick}
          src={userPhoto}
        />

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={(e) => handleAvatarChange(e)}
        />
      </Row>

      <Form name="signup-control" onFinish={handleSubmit(onSubmit)}>
        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Input
                {...register('displayName', {
                  required: { value: true, message: t('required') },
                })}
                status={errors.displayName ? 'error' : 'validating'}
                placeholder={t('name')}
                className={styles.roundedInput}
                defaultValue={context.userLogged?.displayName}
                onChange={handleInputChange}
                style={{
                  backgroundColor: token.inputBackgroundColor,
                  color: token.inputTextColor,
                }}
              />
              {errors.displayName && (
                <span className={styles.errorMessage}>
                  {errors.displayName.message}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Input
                {...register('email', {
                  required: {
                    value: true,
                    message: t('required'),
                    pattern: {
                      value: emailPattern,
                      message: t('valid_email'),
                    },
                  },
                })}
                status={errors.email ? 'error' : 'validating'}
                disabled
                placeholder={t('email')}
                className={styles.roundedInput}
                defaultValue={context.userLogged?.email}
                onChange={handleInputChange}
                style={{
                  backgroundColor: token.inputBackgroundColor,
                  color: token.inputTextColor,
                }}
              />
              {errors.email && (
                <span className={styles.errorMessage}>
                  {errors.email.message}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <CustomPasswordInput
                register={register}
                getValues={getValues}
                token={token}
                errors={errors}
                handleInputChange={handleInputChange}
                placeholder={t('password')}
                registerName="password"
              />
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <CustomPasswordInput
                register={register}
                getValues={getValues}
                token={token}
                errors={errors}
                handleInputChange={handleInputChange}
                placeholder={t('password_confirmation')}
                registerName="password_confirmation"
              />
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Input
                {...register('hours', {
                  required: { value: true, message: t('required') },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: t('valid_number'),
                  },
                })}
                placeholder={t('hours_goal')}
                className={styles.roundedInput}
                style={{
                  backgroundColor: token.inputBackgroundColor,
                  color: token.inputTextColor,
                }}
                defaultValue={context.userLogged?.hours}
                onChange={handleInputChange}
                status={errors.hours ? 'error' : 'validating'}
                type="number"
              />
              {errors.hours && (
                <span className={styles.errorMessage}>
                  {errors.hours.message}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={8} md={8} xl={8} xxl={6}>
            <div className={styles.inputContainer}>
              <Form.Item>
                <CustomSelect
                  register={register}
                  setValue={setValue}
                  propertyName="periodicity"
                  placeholder={t('periodicity')}
                  options={[
                    { value: 'weekly', label: t('weekly') },
                    { value: 'monthly', label: t('monthly') },
                    { value: 'yearly', label: t('yearly') },
                  ]}
                  defaultValue={context.userLogged?.periodicity}
                  errors={errors}
                  style={{
                    color: token.inputTextColor,
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={20} sm={7} md={7} xl={7} xxl={7}>
            <div className={styles.inputContainer}>
              <Form.Item>
                <CustomSelect
                  register={register}
                  setValue={setValue}
                  propertyName="wMode"
                  placeholder={t('saveMyData')}
                  options={[
                    { value: 'local', label: t('device') },
                    { value: 'cloud', label: t('cloud') },
                  ]}
                  defaultValue={context.userLogged?.wMode}
                  errors={errors}
                  style={{
                    color: token.inputTextColor,
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={4} sm={1} md={1} xl={1} xxl={1}>
            <Tooltip title={t('saveMyDataInfo')}>
              <BsPatchExclamationFill
                style={{ fontSize: 'large', marginTop: '1.1rem' }}
              />
            </Tooltip>
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            sm={8}
            md={8}
            xl={6}
            xxl={4}
            className={styles.buttonCenter}
          >
            <Button
              type="primary"
              htmlType="submit"
              className={[styles.roundedButton, styles.editButton]}
            >
              {t('save')}
            </Button>
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            sm={8}
            md={8}
            xl={6}
            xxl={4}
            className={styles.itemCentered}
          >
            <span className={styles.forceHand} onClick={logout}>
              {t('user.logout')}
            </span>
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            sm={8}
            md={8}
            xl={6}
            xxl={4}
            className={[styles.itemCentered, styles.textDeleteAccount]}
          >
            <span
              className={styles.forceHand}
              onClick={() => {
                setShowDeleteConfirmation(!showDeleteConfirmation);
              }}
            >
              {t('user.deleteaccount')}
            </span>
          </Col>
        </Row>

        <Modal
          title={t('delete_account_title')}
          open={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              {t('no')}
            </Button>,
            <Button key="confirm" type="primary" onClick={handleDeleteAccount}>
              {t('yes')}
            </Button>,
          ]}
        >
          {t('delete_account_warning')}
        </Modal>
      </Form>
    </Content>
  );
};

Profile.propTypes = {};

export default Profile;
