import { put, takeLatest, select } from 'redux-saga/effects';
import { failure, request, success } from '../../reducers/signin/signInSlice';
import {
  success as successUser,
  readyForNextState,
} from '../../reducers/users/usersSlice';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase';

const handleSuccess = function* (userData) {
  yield put(success());
  yield put(successUser(userData));
  yield put(readyForNextState());
};

const handleFailure = function* () {
  yield put(failure({ message: 'user.noregistered', status: 400 }));
};

export function* signInSaga({ payload: input }) {
  const { wMode } = yield select((state) => state.appConfig);
  let user;

  if (wMode === 'local') {
    const users = yield select((state) => state.users.data);
    user = users[input.email];

    if (user && user.password === input.password) {
      yield* handleSuccess(user);
    } else {
      yield* handleFailure();
    }
  } else {
    try {
      const { user: firebaseUser } = yield signInWithEmailAndPassword(
        auth,
        input.email,
        input.password,
      );
      const attributes = JSON.parse(firebaseUser.photoURL);
      const combinedUser = { ...firebaseUser, ...attributes };
      yield* handleSuccess(combinedUser);
    } catch (error) {
      yield* handleFailure();
    }
  }
}

export default function* signIn() {
  yield takeLatest(request, signInSaga);
}
