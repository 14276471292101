import { Select, theme } from "antd";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const { useToken } = theme;

const CustomSelect = ({
  setValue,
  propertyName,
  options,
  errors,
  placeholder,
  register,
  style,
  defaultValue,
}) => {
  const handleChange = (option) => {
    setValue(propertyName, option);
  };
  const { token } = useToken();
  const { t } = useTranslation();

  return (
    <>
      <Select
        {...register(propertyName, {
          required: { value: true, message: t("required") },
        })}
        style={{ height: "55px", ...style }}
        className={`custom-select ${
          token.isDarkMode ? "custom-select-d" : "custom-select-l"
        }`}
        placeholder={placeholder}
        onChange={handleChange}
        defaultValue={defaultValue}
        status={errors?.periodicity ? "error" : "validating"}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
      {errors[propertyName] && (
        <span className={"errorMessage"}>{errors[propertyName].message}</span>
      )}
    </>
  );
};

export default CustomSelect;
