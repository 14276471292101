import { theme } from 'antd';
import { FORMAT_TIME } from '../../../config/constants';
import moment from 'moment';
import './Events.scss';
import React from 'react';

const { useToken } = theme;

const getDuration = (event) => {
  const startDate = new Date(event.start);
  const endDate = new Date(event.end);

  const diff = endDate.getTime() - startDate.getTime();
  const hours = Math.floor(diff / (60 * 60 * 1000));
  let minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
  minutes = minutes > 9 ? minutes : `0${minutes}`;

  return `${hours > 0 ? `${hours}h` : '0:'}${minutes > 0 ? `${minutes}m` : ''}`;
};

const StandartEvent = ({ event }) => {
  return `${moment(event.start).format(FORMAT_TIME)} - ${moment(
    event.end,
  ).format(FORMAT_TIME)} (${getDuration(event)})`;
};

const TimeColumn = ({ times }) => {
  return (
    <div className="rbc-time-column">
      {times.map((time, index) => (
        <div key={index} className="rbc-hour">
          {time}
        </div>
      ))}
    </div>
  );
};

const EventDuration = ({ event }) => {
  return <span className="event-duration">{getDuration(event)}</span>;
};

const HeaderMonthCell = (props) => {
  return <span>{props.label}</span>;
};

const HeaderWeekCell = (props) => {
  const { token } = useToken();
  return <span style={{ color: token.textColor }}>{props.label}</span>;
};

export {
  StandartEvent,
  TimeColumn,
  HeaderMonthCell,
  HeaderWeekCell,
  EventDuration,
};
