import React from "react";
import { Select } from "antd";

const CustomHourPicker = ({ onChange, defaultValue }) => {
  const [hour, setHour] = React.useState(defaultValue || null);

  const hoursOptions = Array.from({ length: 201 }, (_, i) => i);

  const handleHourChange = (value) => {
    setHour(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      style={{ width: 100 }}
      onChange={handleHourChange}
      value={hour}
      placeholder="00"
    >
      {hoursOptions.map((option) => (
        <Select.Option key={option} defaultValue={hour}>
          {option.toString().padStart(2, "0")}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomHourPicker;
