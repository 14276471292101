import { useState, useEffect } from 'react';
import IosInstallPrompt from 'react-ios-pwa-prompt';
import './installBanner.scss';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.png';
import ImageComponent from '../components/ImageComponent';

function InstallBanner() {
  const { t } = useTranslation();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const bannerShownCount = parseInt(
      localStorage.getItem('bannerShownCount') || '0',
    );

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();

      if (
        bannerShownCount <= 5 &&
        !window.matchMedia('(display-mode: standalone)').matches
      ) {
        setDeferredPrompt(e);
        setIsVisible(true);

        // Incrementa el conteo aquí
        localStorage.setItem(
          'bannerShownCount',
          (bannerShownCount + 1).toString(),
        );
      }
    });

    window.addEventListener('appinstalled', () => {
      console.log('🎉 WeekPlanner fue instalada!');
      localStorage.setItem('bannerShownCount', '5');
      localStorage.setItem('appInstalled', 'true');
    });
    /*
    return () => {
      window.removeEventListener('beforeinstallprompt', () => {});
      window.removeEventListener('appinstalled', () => {});
    };
    */
  });

  const installApp = async () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    console.log(`Resultado del banner de instalación: ${outcome}`);
    setDeferredPrompt(null);
    setIsVisible(false);
  };

  const closeBanner = () => {
    setIsVisible(false);
  };

  const isIos =
    /iPhone/.test(navigator.userAgent) && !window.navigator.standalone;
  const isAndroid = /Android/.test(navigator.userAgent);

  const closeIosBanner = () => {
    const count =
      parseInt(localStorage.getItem('iosBannerShownCount') || 0) + 1;
    localStorage.setItem('iosBannerShownCount', count.toString());
  };

  return (
    <>
      {/* Banner para Android */}
      {isAndroid && (
        <div className={`install-banner ${!isVisible ? 'hidden' : ''}`}>
          <ImageComponent
            alt="WeekPlanner Logo"
            src={logo}
            className="banner-logo"
          />
          <div className="banner-content">
            <p>Instala WeekPlanner en tu dispositivo.</p>
            <button className="install-btn" onClick={installApp}>
              Instalar
            </button>
          </div>
          <button className="close-btn" onClick={closeBanner}>
            X
          </button>
        </div>
      )}

      {/* Banner para iOS */}
      {isIos &&
        parseInt(localStorage.getItem('bannerShownCount') || '0') <= 5 && (
          <IosInstallPrompt
            debug={true}
            onClose={closeIosBanner}
            copyTitle={t('installTitle')}
            copyBody={t('installBody')}
            copyAddHomeButtonLabel={t('installAddHome')}
            copyShareButtonLabel={t('installShare')}
            copyClosePrompt={t('installClose')}
          />
        )}
    </>
  );
}

export default InstallBanner;
