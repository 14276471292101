import moment from "moment";

const getMinDate = () => {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();

  if (month < 8) {
    year--;
  }

  return new Date(year, 8, 1);
};

const getMaxDate = () => {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();

  if (month >= 8) {
    year++;
  }

  return new Date(year, 7, 31, 23, 59, 59);
};

const checkMonthNotInArray = (arr, monthYearString) => {
  const targetDate = moment(monthYearString, ["MMMM YYYY", "MMMM YYYY"], true);

  return !arr.some((obj) => {
    const startDate = moment(obj.startPlanned);
    return startDate.isSame(targetDate, "month");
  });
};

export { getMinDate, getMaxDate, checkMonthNotInArray };
