import React from 'react';
import { useRoutes } from 'react-router-dom';

import baseRoutes from './routes';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { PublicRoute } from './components/PublicRoute/PublicRoute';

const AppRouter = () => {
  return useRoutes(
    baseRoutes.map((route) => {
      const {
        path,
        component: Component,
        needAuthentication,
        notNeedAuthentication,
      } = route;

      return {
        path,
        element: needAuthentication ? (
          <ProtectedRoute>
            <Component />
          </ProtectedRoute>
        ) : notNeedAuthentication ? (
          <PublicRoute>
            <Component />
          </PublicRoute>
        ) : (
          <Component />
        ),
      };
    }),
  );
};

export default AppRouter;
