import { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Tooltip, theme } from 'antd';
import Title from 'antd/es/typography/Title';
import { CalendarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TbTargetArrow } from 'react-icons/tb';
import { FaPersonRunning } from 'react-icons/fa6';
import { MdManageHistory } from 'react-icons/md';
import styles from './InfoHours.module.scss';
import InfoCard from './InfoCard';
import useResponsive from '../../hooks/useResponsive';
import CustomTimePicker from '../CustomTimePicker';
import { BsSendPlusFill } from 'react-icons/bs';
import moment from 'moment';
import useSplitTimeWorked from '../../hooks/useSplitTimeWorked';
import useAppState from '../../hooks/useAppState';
import useGetPlansTarget from '../../pages/planner/useGetPlansTarget';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../config/constants';
import {
  request as requestSendActivitiesSplited,
  readyForNextState as readyForNextStateSendActivitiesSplited,
} from '../../store/reducers/activities/sendActivitiesSplitedSlice';

const { useToken } = theme;

const generateReportString = (t, month, includeAssets) => {
  let reportString = `*${t('monthReport')} ${month.currentMonth}* \n  ${t(
    'hours_goal',
  )}: ${moment.duration(month.worked, 'minutes').asHours()}`;

  if (includeAssets) {
    reportString += ` \n  ${t('pubs')}: ${month.pubTotal} \n  ${t('visits')}: ${
      month.revTotal
    } \n  ${t('videos')}: ${month.vidTotal}`;
  }

  reportString += ` \n  ${t('studies')}: ${month.stuTotal}`;

  return reportString;
};

const InfoHours = ({ month, assetsEnable, tourRef, currentDate }) => {
  const dispatch = useDispatch();
  const [size] = useState(6);
  const { isMobile } = useResponsive();
  const { userLogged } = useAppState();
  const { t } = useTranslation();
  const { token } = useToken();
  const [tapCount, setTapCount] = useState(0);
  const [hoursToMove, setHoursToMove] = useState('00:00');
  const [modalMoveHoursVisible, setModalMoveHoursVisible] = useState(false);
  const { targetHoursShowed } = useGetPlansTarget();

  const [modalSplitHoursVisible, setModalSplitHoursVisible] = useState(false);
  const [hasSendReport, setHasSendReport] = useState(false);
  const [minutesExtra, setMinutesExtra] = useState('0');
  const { forNextMonth } = useSplitTimeWorked();
  const { status: statusSendActivitiesSplited } = useSelector(
    (state) => state.sendActivitiesSplited,
  );
  const commonStyles = {
    color: token.titleTextColor,
    marginTop: '0px',
    marginBottom: '0px',
  };

  const buttonStyle = {
    cursor: 'pointer',
  };

  const handleTap = () => {
    setTapCount((prevCount) => prevCount + 1);
    if (tapCount + 1 === 10) {
      setTapCount(0);
      setModalMoveHoursVisible(true);
    }
  };

  const hideModal = () => {
    setModalMoveHoursVisible(false);
  };

  const getNextMonth = () => {
    const nextMonth = moment(month.currentMonth, 'MMMM')
      .add(1, 'months')
      .format('MMMM');
    return ` ${nextMonth}`;
  };

  const handleMoveToNextMonth = () => {
    let [hours, minutes] = hoursToMove.split(':').map(Number);
    let [maxHours, maxMinutes] = month.workedShowed.split(':').map(Number);
    const error =
      hours > maxHours || (hours === maxHours && minutes > maxMinutes);
    if (!error) {
      const activitiesNew = forNextMonth({ currentDate, hours, minutes });
      dispatch(
        requestSendActivitiesSplited({
          activities: activitiesNew,
          email: userLogged.email,
        }),
      );
    }
  };

  const handleSendReport = () => {
    const minutes = moment.duration(month.worked, 'minutes').get('minutes');
    setMinutesExtra(minutes < 10 ? `0${minutes}` : `${minutes}`);
    if (minutes === 0) {
      sendReport();
    } else {
      setModalSplitHoursVisible(true);
    }
  };

  const sendReport = () => {
    const waLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      generateReportString(t, month, assetsEnable),
    )}`;
    window.open(waLink);
    setModalSplitHoursVisible(false);
  };

  const handleOk = (discard) => {
    const minutes = moment.duration(month.worked, 'minutes').get('minutes');
    const activitiesNew = forNextMonth({ currentDate, minutes, discard });
    setHasSendReport(true);
    dispatch(
      requestSendActivitiesSplited({
        activities: activitiesNew,
        email: userLogged.email,
      }),
    );
  };

  const handleCancel = () => {
    setModalSplitHoursVisible(false);
  };

  useEffect(() => {
    if (statusSendActivitiesSplited === STATUS.SUCCESS) {
      setModalSplitHoursVisible(false);
      setModalMoveHoursVisible(false);
      dispatch(readyForNextStateSendActivitiesSplited());
    }
    if (statusSendActivitiesSplited === STATUS.FAILURE) {
      dispatch(readyForNextStateSendActivitiesSplited());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSendActivitiesSplited]);

  useEffect(() => {
    if (hasSendReport) {
      setHasSendReport(false);
      sendReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return (
    <Row className={styles.mainRow} ref={tourRef.ref2} gutter={[10, 10]}>
      <Col xs={24} sm={12} xl={size} xxl={size}>
        <Card>
          <Row align={'middle'}>
            <Col xs={6}>
              <CalendarOutlined onClick={handleTap} className={styles.icon} />
            </Col>
            <Col xs={18}>
              <div
                className={styles.subtitle}
                style={{
                  color: token.titleTextColor,
                  textTransform: 'capitalize',
                  marginTop: '20px',
                }}
              >
                {month.currentMonth}
              </div>
              <div
                className={styles.title}
                style={{
                  color: token.titleTextColor,
                }}
              >
                {month.workedShowed}{' '}
                <span ref={tourRef.ref4} style={buttonStyle}>
                  <Tooltip title={t('sendReport')} className={styles.lastItem}>
                    <BsSendPlusFill
                      size={20}
                      onClick={handleSendReport}
                      color={token.colorText}
                    />
                  </Tooltip>
                </span>
              </div>
            </Col>
          </Row>
          <Row align={'middle'}>
            {assetsEnable && (
              <>
                <Col xs={6}>
                  <Title
                    level={5}
                    align="center"
                    style={{ ...commonStyles, color: token.colorText }}
                  >
                    {isMobile ? (
                      <Tooltip title={t('pubs')} className={styles.lastItem}>
                        {t('pub')}
                      </Tooltip>
                    ) : (
                      t('pubs')
                    )}
                  </Title>
                </Col>
                <Col xs={6}>
                  <Title
                    level={5}
                    align="center"
                    style={{ ...commonStyles, color: token.colorText }}
                  >
                    {isMobile ? (
                      <Tooltip title={t('videos')} className={styles.lastItem}>
                        {t('vid')}
                      </Tooltip>
                    ) : (
                      t('videos')
                    )}
                  </Title>
                </Col>
                <Col xs={6}>
                  <Title
                    level={5}
                    align="center"
                    style={{ ...commonStyles, color: token.colorText }}
                  >
                    {isMobile ? (
                      <Tooltip title={t('visits')} className={styles.lastItem}>
                        {t('ret')}
                      </Tooltip>
                    ) : (
                      t('visits')
                    )}
                  </Title>
                </Col>
              </>
            )}
            <Col xs={assetsEnable ? 6 : 24}>
              <Title
                level={5}
                align="center"
                style={{ ...commonStyles, color: token.colorText }}
              >
                {isMobile ? (
                  <Tooltip title={t('studies')} className={styles.lastItem}>
                    {t('studies')}
                  </Tooltip>
                ) : (
                  t('studies')
                )}
              </Title>
            </Col>
          </Row>
          <Row align={'middle'}>
            {assetsEnable && (
              <>
                <Col xs={6}>
                  <Title className={styles.assets} style={commonStyles}>
                    {parseInt(month.pubTotal)}
                  </Title>
                </Col>
                <Col xs={6}>
                  <Title
                    className={styles.assets}
                    align="center"
                    style={commonStyles}
                  >
                    {parseInt(month.vidTotal)}
                  </Title>
                </Col>
                <Col xs={6}>
                  <Title
                    className={styles.assets}
                    align="center"
                    style={commonStyles}
                  >
                    {parseInt(month.revTotal)}
                  </Title>
                </Col>
              </>
            )}
            <Col xs={assetsEnable ? 6 : 24}>
              <Title
                className={styles.assets}
                align="center"
                style={commonStyles}
              >
                {parseInt(month.stuTotal)}
              </Title>
            </Col>
          </Row>
        </Card>
      </Col>
      {!month.isBeforeOfPlanned && (
        <Col xs={24} sm={12} xl={size} xxl={size}>
          <InfoCard
            icon={TbTargetArrow}
            title={`${month.goalShowed} ${t('h/month')}`}
            secondTitle={`${targetHoursShowed} ${t('h/week')}`}
            subtitle={t('goal')}
            style={{ color: token.titleTextColor }}
          />
        </Col>
      )}
      {!month.isBeforeOfPlanned && (
        <Col xs={24} sm={12} xl={size} xxl={size}>
          <InfoCard
            icon={FaPersonRunning}
            title={`${month.wantingShowed} ${t('h/month')}`}
            subtitle={t('outstanding')}
            style={{ color: token.titleTextColor }}
          />
        </Col>
      )}

      <Col xs={24} sm={12} xl={size} xxl={size}>
        {month.workedAllShowed && (
          <InfoCard
            icon={MdManageHistory}
            title={`${month.workedAllShowed}h`}
            subtitle={
              <>
                {t('youLeft')}
                {600 - parseInt(month.workedAllShowed.split(':')[0])}
                <br />
                {t('toGoal')}
              </>
            }
            style={{ color: token.titleTextColor }}
          />
        )}
      </Col>
      <Modal
        open={modalMoveHoursVisible}
        onOk={handleMoveToNextMonth}
        onCancel={hideModal}
        okText={t('moveTo').concat(getNextMonth())}
        cancelText={t('cancel')}
      >
        <Row gutter={4} style={{ marginTop: 0 }}>
          <Col xs={12}>
            <Title
              level={5}
              align="left"
              style={{ ...commonStyles, color: token.colorText }}
            >
              {isMobile ? (
                <Tooltip title={t('hoursToMove')} className={styles.lastItem}>
                  {t('hoursToMove')}
                </Tooltip>
              ) : (
                t('hoursToMove')
              )}
            </Title>
            <CustomTimePicker
              value={hoursToMove}
              onChange={(value) => setHoursToMove(value)}
              max={month.workedShowed}
              errorText={t('customTimePickerMaxError')}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title={`${t('alert_split_minutes1')} ${minutesExtra} ${t(
          'alert_split_minutes2',
        )}`}
        onCancel={handleCancel}
        open={modalSplitHoursVisible}
        footer={[
          <Button key="cancel" onClick={() => handleCancel()}>
            {t('cancel')}
          </Button>,
          <Button
            key="back"
            danger
            type="primary"
            onClick={() => handleOk(true)}
          >
            {t('alert_split_minutes_cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleOk(false)}
            className={styles.splitMinutesButtom}
          >
            {t('alert_split_minutes_ok')}
          </Button>,
        ]}
      />
    </Row>
  );
};

export default InfoHours;
