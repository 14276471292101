import { useState } from 'react';
import { Table, Space, Button, Tooltip, Modal } from 'antd';
import { MdOutlineRestorePage /*, MdDeleteForever*/ } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import styles from './Admin.module.scss';
import TableSkeleton from '../../components/TableSkeleton';
import ModalAdmin from '../../components/ModalAdmin';
import usePlansTarget from './usePlansTarget';
import useResponsive from '../../hooks/useResponsive';

const Admin = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const [visible, setVisible] = useState(true);
  const [open, setOpen] = useState('');
  const [record, setRecord] = useState(null);
  const { plansTarget, getPlansTarget, complete, resetAccount, deleteAccount } =
    usePlansTarget();

  const columns = [
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('weeklyHours'),
      dataIndex: 'hoursWeekly',
      key: 'hoursWeekly',
    },
    {
      title: t('weeklyMinutes'),
      dataIndex: 'minutesWeekly',
      key: 'minutesWeekly',
    },
    {
      title: t('targetHours'),
      dataIndex: 'targetHours',
      key: 'targetHours',
    },
    {
      title: '',
      key: 'action',
      width: 80,
      render: (record) => (
        <Space size="middle">
          <Tooltip title={t('resetUserData')}>
            <Button
              shape="circle"
              onClick={() => showConfirmationModal('reset', record)}
              icon={<MdOutlineRestorePage size={18} />}
            />
          </Tooltip>
          {/* <Tooltip title={t('deleteUser')}>
            <Button
              danger
              shape="circle"
              onClick={() => showConfirmationModal('delete', record)}
              icon={<MdDeleteForever size={18} />}
            />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  const handleAdminOk = () => {
    setVisible(false);
    getPlansTarget();
  };

  function showConfirmationModal(type, record) {
    setOpen(type);
    setRecord(record);
  }

  const hideConfirmationModal = () => {
    setOpen('');
    setRecord(null);
  };

  const handleResetRow = () => {
    resetAccount(record.email);
    hideConfirmationModal();
  };

  const handleDeleteRow = () => {
    deleteAccount(record.email);
    hideConfirmationModal();
  };

  return (
    <div className={styles.admin}>
      <TableSkeleton
        rowNumber={10}
        colNumber={isMobile ? 4 : 6}
        visible={!complete}
      />
      {complete && <Table columns={columns} dataSource={plansTarget} />}
      <ModalAdmin visible={visible} handleOk={handleAdminOk} />
      <Modal
        title={
          open === 'reset'
            ? `${t('resetUserDataConfirmation')} ${record?.email}. ${t(
                'confirmation',
              )}`
            : `${t('deleteUserConfirmation')} ${record?.email}. ${t(
                'confirmation',
              )}`
        }
        open={!!open}
        onOk={open === 'reset' ? handleResetRow : handleDeleteRow}
        onCancel={hideConfirmationModal}
        okText={t('yes')}
        cancelText={t('no')}
      />
    </div>
  );
};

Admin.propTypes = {};

export default Admin;
