import { useState, useEffect } from 'react';
import { Timeline, Typography, Button, Tooltip, Modal, theme } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useActivities from './useActivities';
import CustomDatePicker from '../../components/CustomDatePicker';
import TimelineElement from './TimelineElement';
import './Notebook.scss';

const { useToken } = theme;
const { Title } = Typography;

const Notebook = () => {
  const { i18n, t } = useTranslation();
  const { token } = useToken();
  const { activities, handleDeleteActivity, loading } = useActivities({
    handleSendActivities: hideConfirmationModal,
  });
  const [open, setOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [date, setDate] = useState('');

  const statusColorMap = {
    0: token.highAlert,
    1: token.ok,
    2: token.colorPrimary,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    moment.locale(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const getColor = (start) => {
    const date = moment(start);
    const today = moment();

    if (date.isBefore(today, 'day')) {
      return statusColorMap[0];
    } else if (date.isAfter(today, 'day')) {
      return statusColorMap[2];
    } else {
      return statusColorMap[1];
    }
  };

  const getActivities = () => {
    const filterDate = moment(date, t('dateFormat'));
    if (date) {
      return activities.filter(
        (a) =>
          !a.monthActivity && moment(a.start).isSameOrAfter(filterDate, 'day'),
      );
    } else {
      return activities.filter((a) => !a.monthActivity);
    }
  };

  const onDateChange = (date, dateString) => {
    setDate(dateString);
  };

  const showConfirmationModal = (e, activity) => {
    e.stopPropagation();
    setOpen(true);
    setSelectedActivity(activity);
  };

  function hideConfirmationModal() {
    setOpen(false);
    setSelectedActivity(null);
  }

  const handleOk = () => {
    handleDeleteActivity(selectedActivity.id);
  };

  return (
    <div className="notebook">
      {activities.length === 0 ? (
        <div className="no-notes-container">
          <Title strong style={{ color: token.titleTextColor }}>
            {t('noNotes')}
          </Title>
        </div>
      ) : (
        <>
          <CustomDatePicker
            inputReadOnly
            format={t('dateFormat')}
            onChange={onDateChange}
          />
          <Timeline
            reverse
            mode="left"
            items={getActivities().map((activity) => ({
              color: getColor(activity.start),
              children: <TimelineElement activity={activity} />,
              label: (
                <Tooltip title={t('deleteNote')}>
                  <Button
                    danger
                    shape="circle"
                    type="primary"
                    className="small-button"
                    icon={<CloseOutlined />}
                    onClick={(e) => showConfirmationModal(e, activity)}
                  />
                </Tooltip>
              ),
            }))}
          />
          <Modal
            title={t('deleteNoteConfirmation')}
            open={open}
            onOk={handleOk}
            onCancel={hideConfirmationModal}
            okText={t('yes')}
            cancelText={t('no')}
            confirmLoading={loading}
          />
        </>
      )}
    </div>
  );
};

Notebook.propTypes = {};

export default Notebook;
