import { put, select, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/plansTarget/deletePlansTargetSlice';
import { resetStateByEmail } from '../../reducers/plansTarget/plansTargetSlice';
import { deleteDataByEmail } from '../../../firestore';

export function* deletePlansTargetSaga({ payload: input }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const { email } = input;
    if (wMode === 'local') {
      yield put(resetStateByEmail({ email }));
      yield put(success());
      return;
    }

    const deletePlansTargetFirestore = yield deleteDataByEmail("plansTarget", email);

    if (deletePlansTargetFirestore.success) {
      yield put(resetStateByEmail({ email }));
      yield put(success());
      return;
    }

    throw new Error('error_delete_plans_target');
  } catch (error) {
    yield put(
      failure({
        message: error.message,
        status: error.message === 'error_delete_plans_target' ? 404 : 500,
      }),
    );
  }
}

export default function* deletePlansTarget() {
  yield takeLatest(request, deletePlansTargetSaga);
}
