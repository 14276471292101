export const COOKIE_NAME = 'cookie';

export const HEADER_AUTH = 'auth_token';

export const API_KEY_NAME = 'x-api-key';

export const DEV = 'development';
export const TEST = 'test';

export const EMPTY_OBJECT = {};

export const TODAY = new Date();

export const FORMAT_DATE = 'DD/MM/yyyy';

export const FORMAT_TIME = 'HH:mm';

export const STATUS = Object.freeze({
  NONE: 'NONE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  MANAGED: 'MANAGED',
  NOTIFIED: 'NOTIFIED',
});

export const STATUS_CODES = Object.freeze({
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  TooManyRequests: 429,
  InternalServerError: 500,
});

export const MAIN_COOKIES = [
  'user_name',
  'user_lastName',
  'user_email',
  'user_roles',
  'x-signature-token',
  'x-header-payload-token',
];

export const BASE_ASSETS = {
  p: 0,
  v: 0,
  r: 0,
  e: 0,
};

export const BASE_EVENTS = {
  start: '',
  end: '',
  extra: true,
  disabled: false,
  status: true,
  title: '',
  assets: {
    p: 0,
    r: 0,
    e: 0,
    v: 0,
  },
};
