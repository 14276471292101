import { put, select, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/plans/deletePlansSlice';
import { resetStateByEmail } from '../../reducers/plans/plansSlice';
import { deleteDataByEmail } from '../../../firestore';

export function* deletePlansSaga({ payload: input }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const { email } = input;
    if (wMode === 'local') {
      yield put(resetStateByEmail({ email }));
      yield put(success());
      return;
    }

    const plansFirestore = yield deleteDataByEmail("plans", email);

    if (plansFirestore.success) {
      yield put(resetStateByEmail({ email }));
      yield put(success());
      return;
    }

    throw new Error('error_delete_plans');
  } catch (error) {
    yield put(
      failure({
        message: error.message,
        status: error.message === 'error_delete_plans' ? 404 : 500,
      }),
    );
  }
}

export default function* deletePlans() {
  yield takeLatest(request, deletePlansSaga);
}
