import { createSlice } from '@reduxjs/toolkit';

import { STATUS } from '../../../config/constants';

const initialState = {
  comments: {},
  status: STATUS.NONE,
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.statusSend = STATUS.NONE;
      state.data = false;
    },
    resetStateByEmail: (state, action) => {
      state.status = STATUS.NONE;
      delete state.comments[action.payload.email];
    },
    readyForNextState: (state) => {
      state.status = STATUS.NONE;
      state.statusSend = STATUS.NONE;
      state.error = undefined;
    },

    request: (state) => {
      state.status = STATUS.FETCHING;
    },
    success: (state, action) => {
      state.status = STATUS.SUCCESS;
      const newComments = {
        ...state.data,
        [action.payload.email]: action.payload.comment,
      };
      state.data = newComments;
    },
    failure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },

    requestSend: (state) => {
      state.statusSend = STATUS.FETCHING;
    },
    successSend: (state, action) => {
      state.statusSend = STATUS.SUCCESS;
      state.data = {
        ...state.data,
        [action.payload.email]: action.payload.comment,
      };
    },
    failureSend: (state, action) => {
      const { payload: error } = action;
      state.statusSend = STATUS.FAILURE;
      state.error = error;
    },
  },
});

export const {
  resetState,
  resetStateByEmail,
  readyForNextState,
  request,
  requestSend,
  success,
  successSend,
  failure,
  failureSend,
} = commentsSlice.actions;

export const getStatus = (state) => state.comments.status;

export const getData = (state) => state.comments.data;

export const getError = (state) => state.comments.error;

export default commentsSlice.reducer;
