import { Layout, theme } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PropTypes from 'prop-types';
import styles from './AppLayout.module.scss';
import CustomLoader from './components/CustomLoader/index';
import useAppState from './hooks/useAppState';
import HeaderApp from './components/HeaderApp';
import FooterApp from './components/FooterApp';
import InstallBanner from './installBanner';

const { useToken } = theme;

const AppLayout = ({ children, toggleMode }) => {
  const { token } = useToken();
  const context = useAppState();

  return (
    <Layout>
      <InstallBanner />
      <div
        className={styles.MainContainer}
        style={{ backgroundColor: token.backgroundColor }}
      >
        <div className={styles.MainContainer__wrapper}>
          {context.userLogged && <HeaderApp />}
        </div>

        <CustomLoader active={context.loaderActive}>
          <Content>{children}</Content>
        </CustomLoader>

        <FooterApp toggleMode={toggleMode} />
      </div>
    </Layout>
  );
};

AppLayout.props = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
