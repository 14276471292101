import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { BASE_ASSETS, STATUS } from '../../config/constants';

import { MessageContext } from '../../context/MessageContext';
import useAppState from '../../hooks/useAppState';
import {
  request as requestSendActivities,
  readyForNextState as readyForNextStateSendActivities,
} from '../../store/reducers/activities/sendActivitiesSlice';
import { readyForNextState as readyForNextStateSendActivitiesSplited } from '../../store/reducers/activities/sendActivitiesSplitedSlice';
import { request as requestGetActivities } from '../../store/reducers/activities/getActivitiesSlice';
import useMonth from './useMonth';

export const useCalendar = ({ handleSucces }) => {
  const dispatch = useDispatch();
  const getActivities = useSelector((state) => state.getActivities);
  const { status: statusSendActivities, error: errorSendActivities } =
    useSelector((state) => state.sendActivities);
  const {
    status: statusSendActivitiesSplited,
    error: errorSendActivitiesSplited,
  } = useSelector((state) => state.sendActivitiesSplited);
  const { data } = useSelector((state) => state.activities);
  const { userLogged, update } = useAppState();
  const [results, setResults] = useState([]);
  const [activityLoading, setActivityLoading] = useState('');
  const { notification } = useContext(MessageContext);
  const { getMonthData, currentDate } = useMonth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getCalendarData = (email) => {
    dispatch(requestGetActivities({ email }));
  };

  const sendActivities = ({ activities, email }) => {
    dispatch(
      requestSendActivities({
        activities,
        email,
      }),
    );
  };

  const handleAddResults = (event) => {
    setActivityLoading('add');
    let currentResults = JSON.parse(JSON.stringify(results));
    let currentEvent = {
      id: currentResults.length + 1,
      start: moment(event.start).toDate().toISOString(),
      startPlanned: moment(event.start).toDate().toISOString(),
      end: moment(event.end).toDate().toISOString(),
      endPlanned: moment(event.end).toDate().toISOString(),
      status: event.status ? 3 : 0,
      extra: event.status,
      disabled: false,
    };
    if (event.assets) {
      currentEvent.assets = event.assets;
    }
    if (event?.register) {
      const [from, to] = event.register;
      if (from === '' || to === '') {
        return;
      }
      const initialDate = moment(event.start);
      const start = new Date(
        initialDate.year(),
        initialDate.month(),
        initialDate.date(),
        from.split(':')[0],
        from.split(':')[1],
      ).toISOString();
      const end = new Date(
        initialDate.year(),
        initialDate.month(),
        initialDate.date(),
        to.split(':')[0],
        to.split(':')[1],
      ).toISOString();
      currentEvent.start = start;
      currentEvent.startPlanned = start;
      currentEvent.end = end;
      currentEvent.endPlanned = end;
      currentEvent.title = event.register.join(' - ');
    }

    currentResults.push(currentEvent);
    currentResults = currentResults.sort((a, b) =>
      a.start.localeCompare(b.start),
    );
    sendActivities({
      activities: currentResults,
      email: userLogged.email,
    });
  };

  const handleUpdateResults = (id, activity) => {
    setActivityLoading('update');
    const currentResults = [...results];
    const index = currentResults.findIndex((result) => result.id === id);

    let currentEvent = { ...currentResults[index] };
    const isGreatherToday = moment().isBefore(moment(currentEvent.start));

    if (activity?.register) {
      const [start, end] = activity.register ? activity.register : ['', ''];
      if (start === '' || end === '') {
        return;
      }
      const [hourStart, minuteStart] = start.split(':');
      const [hourEnd, minuteEnd] = end.split(':');

      const newStart = moment(currentEvent.start)
        .hour(hourStart)
        .minute(minuteStart)
        .toDate();
      const newEnd = moment(currentEvent.end)
        .hour(hourEnd)
        .minute(minuteEnd)
        .toDate();
      currentEvent.start = newStart;
      currentEvent.end = newEnd;
      if (isGreatherToday) {
        currentEvent.startPlanned = newStart.toISOString();
        currentEvent.endPlanned = newEnd.toISOString();
      }
      currentEvent.title = activity.register.join(' - ');
    }

    if (isGreatherToday) {
      currentEvent.status = 0;
    } else {
      const diffCurrent = moment(currentEvent.end).diff(
        moment(currentEvent.start),
        'm',
      );
      const diffPlanned = moment(currentEvent.endPlanned).diff(
        moment(currentEvent.startPlanned),
        'm',
      );
      if (diffCurrent < diffPlanned) {
        currentEvent.status = currentEvent.extra ? 3 : 1;
      }
      if (diffCurrent === diffPlanned) {
        currentEvent.status = currentEvent.extra ? 3 : 2;
      }
      if (diffCurrent > diffPlanned) {
        currentEvent.status = 3;
      }
    }
    currentEvent.assets = activity.assets ?? BASE_ASSETS;

    if (activity.comments) {
      currentEvent.comments = activity.comments;
    }

    currentResults[index] = currentEvent;

    sendActivities({
      activities: currentResults,
      email: userLogged.email,
    });
  };

  const deleteEventById = (id) => {
    setActivityLoading('delete');
    const eventsDataCopy = JSON.parse(JSON.stringify(results));
    const index = eventsDataCopy.findIndex((event) => event.id === id);
    eventsDataCopy[index] = {
      ...eventsDataCopy[index],
      disabled: true,
    };
    sendActivities({
      activities: eventsDataCopy,
      email: userLogged.email,
    });
  };

  useEffect(() => {
    getCalendarData(userLogged.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getActivities.status === STATUS.SUCCESS) {
      update('loaderActive', false);
      setResults(data[userLogged.email]);
    }

    if (getActivities.status === STATUS.FAILURE) {
      update('loaderActive', false);
      const { message } = getActivities.error;
      notification('error', t(message));
      setTimeout(() => {
        navigate('/planner');
      }, 1000);
    }

    update('loaderActive', getActivities.status === STATUS.FETCHING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivities]);

  useEffect(() => {
    if (statusSendActivities === STATUS.SUCCESS) {
      setResults(data[userLogged.email]);
      dispatch(readyForNextStateSendActivities());
      getMonthData({ date: currentDate });
      setActivityLoading('');
      handleSucces();
    }

    if (statusSendActivities === STATUS.FAILURE) {
      const { message } = errorSendActivities;
      dispatch(readyForNextStateSendActivities());
      notification('error', t(message));
      setActivityLoading('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSendActivities]);

  useEffect(() => {
    if (statusSendActivitiesSplited === STATUS.SUCCESS) {
      setResults(data[userLogged.email]);
      dispatch(readyForNextStateSendActivitiesSplited());
    }

    if (statusSendActivitiesSplited === STATUS.FAILURE) {
      const { message } = errorSendActivitiesSplited;
      dispatch(readyForNextStateSendActivities());
      notification('error', t(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSendActivitiesSplited]);

  return {
    results,
    getCalendarData,
    handleAddResults,
    handleUpdateResults,
    deleteEventById,
    activityLoading,
  };
};

export default useCalendar;
