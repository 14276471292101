import { put, takeLatest, select } from "redux-saga/effects";
import {
  updatePhoto,
  updatePhotoFailure,
  updatePhotoSuccess,
} from "../../reducers/users/usersSlice";
// import { getAuth, updateProfile } from "firebase/auth";

export function* updateUserPhotoSaga({ payload: input }) {
  const users = yield select((state) => state.users.data);
  const user = users[input.email];

  if (!user) {
    yield put(updatePhotoFailure({ message: "user.noupdated", status: 400 }));
    return;
  }

  try {
    const updatedUser = { ...user, photo: input.photo };
    /*
    //TODO: pending because photoURL doesn't accept base64, it must be an url
    const auth = getAuth();
    const attributes = JSON.parse(auth.currentUser.photoURL || "{}");
    const newUser = {
      photoURL: JSON.stringify({
        ...attributes,
        photoURL: input.photo,
      }),
    };
    yield updateProfile(auth.currentUser, newUser);
    */

    yield put(updatePhotoSuccess(updatedUser));
  } catch (error) {
    console.error(error);
    yield put(updatePhotoFailure({ message: "user.noupdated", status: 400 }));
  }
}

export default function* updateUser() {
  yield takeLatest(updatePhoto, updateUserPhotoSaga);
}
