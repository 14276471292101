import Error403 from './pages/error/error403';
import Error404 from './pages/error/error404';
import Error500 from './pages/error/error500';
import Home from './pages/home';
import Planner from './pages/planner';
import Profile from './pages/profile';
import Admin from './pages/admin';
import Notebook from './pages/notebook';
import SignIn from './pages/signin';
import SignUp from './pages/signup';
import Terms from './pages/signup/Terms';

/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: 'home',
    path: '/',
    exact: true,
    component: Home,
    icon: '',
    needAuthentication: true,
  },
  {
    id: 'signin',
    path: '/sign-in',
    exact: true,
    component: SignIn,
    icon: '',
  },
  {
    id: 'signup',
    path: '/sign-up',
    exact: true,
    component: SignUp,
    icon: '',
  },
  {
    id: 'terms',
    path: '/terms',
    exact: true,
    component: Terms,
    icon: '',
  },
  {
    id: 'profile',
    path: '/profile',
    exact: true,
    component: Profile,
    icon: '',
    needAuthentication: true,
  },
  {
    id: 'planner',
    path: '/planner',
    exact: true,
    component: Planner,
    icon: '',
    needAuthentication: true,
  },
  {
    id: 'admin',
    path: '/admin',
    exact: true,
    component: Admin,
    icon: '',
    needAuthentication: true,
  },
  {
    id: 'notebook',
    path: '/notebook',
    exact: true,
    component: Notebook,
    icon: '',
    needAuthentication: true,
  },
  {
    id: 'error403',
    path: '/403',
    exact: true,
    component: Error403,
    icon: '',
  },
  {
    id: 'error404',
    path: '/404',
    exact: true,
    component: Error404,
    icon: '',
  },
  {
    id: 'error500',
    path: '/500',
    exact: true,
    component: Error500,
    icon: '',
  },
];
