import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useResponsive from '../../../hooks/useResponsive';
import { tourCalendar, tourSwipe } from '../../../store/reducers/appConfig';

const useTour = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hideTourCalendar, hideHandTourCalendar } = useSelector(
    (state) => state.appConfig,
  );
  const { isMobile, isTablet } = useResponsive();

  const getSteps = () => [
    {
      title: t('calendar'),
      description: t('calendarToolbar'),
      target: () => ref3.current,
      nextButtonProps: { children: <span>{t('next')}</span> },
      prevButtonProps: { children: <span>{t('previous')}</span> },
      placement: 'bottom',
    },
    {
      title: t('calendar'),
      description: isMobile || isTablet ? t('calendarTap') : t('calendarClick'),
      target: () => ref1.current,
      nextButtonProps: { children: <span>{t('next')}</span> },

      placement: 'center',
    },
    {
      title: t('calendar'),
      description: t('calendarSummary'),
      target: () => ref2.current,
      nextButtonProps: { children: <span>{t('next')}</span> },
      prevButtonProps: { children: <span>{t('previous')}</span> },
      placement: 'center',
    },
    {
      title: t('calendar'),
      description: t('calendarSendReport'),
      target: () => ref4.current,
      nextButtonProps: { children: <span>{t('finish')}</span> },
      placement: 'bottom',
    },
  ];

  const hideTour = () => {
    window.scrollTo(0, 0);
    dispatch(tourCalendar({ hideTourCalendar: true }));
  };

  const showTour = () => {
    return hideTourCalendar === false;
  };

  const showHandTour = () => {
    return (isMobile || isTablet) && hideHandTourCalendar === false;
  };

  const hideHandTourHandle = () => {
    dispatch(tourSwipe({ hideHandTourCalendar: true }));
  };

  return {
    getSteps,
    showTour,
    hideTour,
    showHandTour,
    hideHandTour: hideHandTourHandle,
    hideTourCalendar,
    ref1,
    ref2,
    ref3,
    ref4,
  };
};

export default useTour;
