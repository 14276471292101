import { Typography, Collapse, theme } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';

const { useToken } = theme;
const { Text } = Typography;

const TimelineElement = ({ activity }) => {
  const { token } = useToken();

  const start = moment(activity.start).format('ddd DD/MM/YYYY');

  if (activity.comments.includes('\n')) {
    return (
      <div className="timeline-container">
        <Collapse
          ghost
          size="small"
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          items={[
            {
              key: '1',
              label: (
                <Text strong style={{ color: token.titleTextColor }}>
                  {start}:
                </Text>
              ),
              children: (
                <Text
                  className="comments-paragraph"
                  style={{ color: token.titleTextColor }}
                >
                  {activity.comments}
                </Text>
              ),
            },
          ]}
        />
      </div>
    );
  } else {
    return (
      <div className="timeline-container">
        <Text style={{ color: token.titleTextColor }}>
          <strong>{start}:</strong> {activity.comments}
        </Text>
      </div>
    );
  }
};

export default TimelineElement;
