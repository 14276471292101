/* eslint-disable import/no-anonymous-default-export */
import { all, fork } from 'redux-saga/effects';

import signUp from './signUp';
import sigIn from './signIn';
import logout from './logout';
import deleteAccount from './deleteAccount';
import plans from './plans';
import sendPlans from './sendPlans';
import getPlans from './getPlans';
import deletePlans from './deletePlans';
import sendPlansTarget from './sendPlansTarget';
import getPlansTarget from './getPlansTarget';
import getAllPlansTarget from './getAllPlansTarget';
import deletePlansTarget from './deletePlansTarget';
import sendActivities from './sendActivities';
import sendActivitiesSplited from './sendActivitiesSplited';
import getActivities from './getActivities';
import updateActivities from './updateActivities';
import deleteActivities from './deleteActivities';
import updateUser from './updateUser';
import updatePhoto from './updatePhoto';
import darkMode from './darkMode';
import getComments from './comments/get';
import sendComments from './comments/send';
import deleteComments from './deleteComments';

export default function* () {
  yield all([
    fork(signUp),
    fork(sigIn),
    fork(logout),
    fork(deleteAccount),
    fork(plans),
    fork(sendPlans),
    fork(getPlans),
    fork(deletePlans),
    fork(sendPlansTarget),
    fork(getPlansTarget),
    fork(getAllPlansTarget),
    fork(deletePlansTarget),
    fork(sendActivities),
    fork(sendActivitiesSplited),
    fork(getActivities),
    fork(updateActivities),
    fork(deleteActivities),
    fork(updateUser),
    fork(updatePhoto),
    fork(darkMode),
    fork(getComments),
    fork(sendComments),
    fork(deleteComments),
  ]);
}
