import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { STATUS } from '../../config/constants';
import {
  request,
  readyForNextState,
} from '../../store/reducers/signup/signUpSlice';
import useAppState from '../../hooks/useAppState';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../context/MessageContext';

import { cloudMode } from '../../store/reducers/appConfig';

export const useSingUp = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.users);
  const { status, error } = useSelector((state) => state.signup);

  const [loading, setLoading] = useState(status);
  const [results, setResults] = useState(data);
  const [currentUser, setCurrentUser] = useState();
  const { update } = useAppState();
  const { notification } = useContext(MessageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const singUpRequest = (data) => {
    setCurrentUser(data.email);
    update('loaderActive', true);
    dispatch(request(data));
  };

  useEffect(() => {
    setLoading(status === STATUS.FETCHING);
    if (status === STATUS.SUCCESS) {
      dispatch(cloudMode({ wMode: data[currentUser].wMode }));
      notification('success', t('welcome'));
      setResults(data[currentUser]);
      update('userLogged', data[currentUser]);
      setTimeout(() => {
        update('loaderActive', false);
        navigate('/planner');
      }, 1000);

      dispatch(readyForNextState());
    }

    if (status === STATUS.FAILURE) {
      const { response } = error;
      update('loaderActive', false);
      notification('error', t(response.message));
      dispatch(readyForNextState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  return { singUpRequest, loading, results };
};

export default useSingUp;
