import { createSlice } from '@reduxjs/toolkit';

import { STATUS } from '../../../config/constants';

const initialState = {
  status: STATUS.NONE,
};

export const refreshCalendarSlice = createSlice({
  name: 'refreshCalendar',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.data = undefined;
    },
    request: (state) => {
      state.status = STATUS.FETCHING;
    },
    success: (state) => {
      state.status = STATUS.SUCCESS;
    },
    failure: (state) => {
      state.status = STATUS.FAILURE;
    },
    readyForNextState: (state) => {
      state.status = STATUS.NONE;
      state.error = undefined;
    },
  },
});

export const { resetState, request, success, failure, readyForNextState } =
  refreshCalendarSlice.actions;

export const status = (state) => state.refreshCalendar.status;

export const error = (state) => state.refreshCalendar.error;

export default refreshCalendarSlice.reducer;
