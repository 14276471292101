import { openDB } from 'idb';

export const openDatabase = async () => {
  return openDB('service-worker-db', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('version')) {
        db.createObjectStore('version', { keyPath: 'id' });
      }
    },
  });
};

export const getVersionFromDB = async () => {
  const db = await openDatabase();
  return db.transaction('version').objectStore('version').get(1);
};

export const updateVersionInDB = async (newVersion) => {
  const db = await openDatabase();
  const tx = db.transaction('version', 'readwrite');
  await tx.objectStore('version').put({ id: 1, value: newVersion });
  return tx.done;
};
