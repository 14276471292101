import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../../config/constants";

const initialState = {
  status: STATUS.NONE,
};

export const bootstrapSlice = createSlice({
  name: "bootstrap",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.data = undefined;
    },
    request: (state) => {
      state.status = STATUS.FETCHING;
      state.data = undefined;
    },
    success: (state, action) => {
      state.status = STATUS.SUCCESS;
      state.data = action.payload;
    },
    failure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
      state.data = undefined;
    },
  },
});

export const { resetState, request, success, failure } = bootstrapSlice.actions;

export const status = (state) => state.bootstrap.status;

export const data = (state) => state.bootstrap.data;

export default bootstrapSlice.reducer;
