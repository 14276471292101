import { Navigate } from 'react-router-dom';
import useAppState from '../../hooks/useAppState';

export const PublicRoute = ({ children }) => {
  const context = useAppState();
  console.log(context);
  if (context.userLogged) {
    return <Navigate to="/" />;
  }
  return children;
};
