import { put, select, takeLatest } from 'redux-saga/effects';
import { failure, request, success } from '../../reducers/plans/getPlansSlice';
import { success as successPlans } from '../../reducers/plans/plansSlice';

import { findDataByEmail } from '../../../firestore';
import { request as requestRefreshCalendar } from '../../reducers/refreshCalendar/refreshCalendarSlice';

export function* getPlansSaga({ payload: input }) {
  const { wMode } = yield select((state) => state.appConfig);
  const { email } = input;
  const { data } = yield select((state) => state.plans);
  let dataCopy = { ...data };

  if (wMode === 'local') {
    if (
      dataCopy &&
      dataCopy[email] &&
      Object.keys(dataCopy[email])?.length > 0
    ) {
      yield put(successPlans({ ...dataCopy, [email]: { ...dataCopy[email] } }));
    }
    yield put(success());
  } else {
    const plansFirestore = yield findDataByEmail("plans", email);

    if (plansFirestore.success && Object.keys(plansFirestore.data).length > 0) {
      yield put(
        successPlans({ ...dataCopy, [email]: { ...plansFirestore.data } }),
      );
      yield put(success());
    } else if (
      !plansFirestore.success &&
      dataCopy &&
      dataCopy[email] &&
      Object.keys(dataCopy[email])?.length > 0
    ) {
      yield put(successPlans({ ...dataCopy, [email]: { ...dataCopy[email] } }));
      yield put(success());
    } else {
      const error = { message: 'error_get_plans', status: 500 };
      yield put(failure(error));
    }
  }
}

export default function* getPlans() {
  yield takeLatest(request, getPlansSaga);
  yield takeLatest(requestRefreshCalendar, getPlansSaga);
}
