import { Input } from "antd";

const NumericInput = ({ value, onChange, placeholder }) => {
  const handleChange = (e) => {
    const { value } = e.target;

    if (!value || /^\d+$/.test(value)) {
      onChange(parseInt(value));
    }
  };

  return (
    <Input
      value={value ? parseInt(value) : 0}
      onChange={handleChange}
      placeholder={placeholder}
      pattern="\d*"
      inputMode="numeric"
    />
  );
};

export default NumericInput;
