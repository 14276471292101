import { put, select, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/plansTarget/getPlansTargetSlice';
import { success as successPlanTarget } from '../../reducers/plansTarget/plansTargetSlice';
import { findDataByEmail } from '../../../firestore';
import { request as requestRefreshCalendar } from '../../reducers/refreshCalendar/refreshCalendarSlice';

export function* getPlansTargetSaga({ payload: { email } }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const { data } = yield select((state) => state.plansTarget);

    if (wMode === 'local') {
      const plansTarget = data[email];

      if (Object.keys(plansTarget).length > 0) {
        yield put(success());
        yield put(
          successPlanTarget({
            ...data,
            [email]: { ...plansTarget },
          }),
        );
        return;
      }
    } else {
      const plansTargetFirestore = yield findDataByEmail("plansTarget", email);

      if (plansTargetFirestore.success && plansTargetFirestore.data) {
        yield put(success());
        yield put(
          successPlanTarget({
            ...data,
            [email]: { ...plansTargetFirestore.data },
          }),
        );
        return;
      }
    }

    throw new Error('error_get_plans_target');
  } catch (error) {
    yield put(failure({ message: error.message, status: 500 }));
  }
}

export default function* getPlansTarget() {
  yield takeLatest(request, getPlansTargetSaga);
  yield takeLatest(requestRefreshCalendar, getPlansTargetSaga);
}
