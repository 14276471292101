import { Menu, Drawer, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { BsCalendar2WeekFill, BsCalendar3 } from 'react-icons/bs';
import styles from './CustomToolBar.module.scss';
import {
  IoTodaySharp,
  IoCaretBack,
  IoCaretForward,
  IoAddCircleSharp,
  IoRefreshOutline,
} from 'react-icons/io5';
import { FaRectangleList } from 'react-icons/fa6';
import { MdAddTask, MdInstallMobile } from 'react-icons/md';
import { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import useRefresh from '../../../pages/home/useRefresh';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const { useToken } = theme;

const CustomToolbar = ({
  tourRef,
  openModal,
  setIsModalOpen,
  isBeforeOfPlanned,
  ...toolbar
}) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleInstall = () => {
    localStorage.removeItem('bannerShownCount');
    localStorage.removeItem('iosBannerShownCount');
    window.location.reload();
  };
  const { t } = useTranslation();
  const { handleRefresh } = useRefresh();

  const goToBack = () => {
    toolbar.onNavigate('PREV');
    setVisible(false);
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
    setVisible(false);
  };

  const goToToday = () => {
    toolbar.onNavigate('TODAY');
    setVisible(false);
  };

  const changeView = (view) => {
    toolbar.onView(view);
    setVisible(false);
  };

  const planMonth = () => {
    navigate('/planner', {
      state: {
        date: toolbar.date.toString(),
      },
    });
  };

  const DropdownMenu = () => (
    <Menu>
      <Menu.Item onClick={goToBack} className={styles.menuItem}>
        <IoCaretBack />
        &nbsp;
        {t('previous')}
      </Menu.Item>
      <Menu.Item onClick={goToNext} className={styles.menuItem}>
        <IoCaretForward />
        &nbsp;
        {t('next')}
      </Menu.Item>
      <Menu.Item onClick={goToToday} className={styles.menuItem}>
        <IoTodaySharp />
        &nbsp;&nbsp;
        {t('toToday')}
      </Menu.Item>
      <Menu.Item
        onClick={() => changeView('month')}
        className={styles.menuItem}
      >
        <BsCalendar3 />
        &nbsp;&nbsp;
        {t('month')}
      </Menu.Item>
      <Menu.Item onClick={() => changeView('week')} className={styles.menuItem}>
        <BsCalendar2WeekFill />
        &nbsp;&nbsp;
        {t('week')}
      </Menu.Item>
      <Menu.Item onClick={() => changeView('day')} className={styles.menuItem}>
        <FaRectangleList />
        &nbsp;&nbsp;
        {t('dayView')}
      </Menu.Item>
      <Menu.Item onClick={openModal} className={styles.menuItem}>
        <IoAddCircleSharp />
        &nbsp;&nbsp;
        {t('addNew')}
      </Menu.Item>
      {isBeforeOfPlanned && (
        <Menu.Item onClick={showModal} className={styles.menuItem}>
          <MdAddTask />
          &nbsp;&nbsp;
          {t('addMonthInfo')}
        </Menu.Item>
      )}
      {!isBeforeOfPlanned && (
        <Menu.Item onClick={planMonth} className={styles.menuItem}>
          <MdAddTask />
          &nbsp;&nbsp;
          {t('planMonth')}
        </Menu.Item>
      )}
      <Menu.Item onClick={handleInstall} className={styles.menuItem}>
        <MdInstallMobile />
        &nbsp;&nbsp;
        {t('install')}
      </Menu.Item>
    </Menu>
  );

  return (
    <span className={styles.toolbarContainer}>
      <div className={styles.toolbar}>
        <MenuOutlined
          ref={tourRef.ref3}
          onClick={() => setVisible(true)}
          style={{ fontSize: '1.5rem' }}
        />
        <IoRefreshOutline
          style={{ fontSize: '1.5rem', marginLeft: 10, alignSelf: 'center' }}
          onClick={handleRefresh}
        />
        <Drawer
          title="Menu"
          placement="left"
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          width={250}
          zIndex={999}
          style={{ backgroundColor: token.backgroundColor }}
          rootClassName={styles.rootDrawer}
        >
          <DropdownMenu />
        </Drawer>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Title level={5} className={styles.monthLabel}>
          {toolbar.label}
        </Title>
      </div>
    </span>
  );
};

export default CustomToolbar;
