import { Navigate } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';

import styles from './CustomToolBar.module.scss';
import useRefresh from '../../../pages/home/useRefresh';
import { useNavigate } from 'react-router-dom';

const CustomToolbarDesktop = ({
  tourRef,
  setIsModalOpen,
  openModal,
  isBeforeOfPlanned,
  ...toolbar
}) => {
  const { label, onNavigate, view, views, onView } = toolbar;
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { handleRefresh } = useRefresh();

  const handleNew = () => {
    openModal(undefined);
  };

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const planMonth = () => {
    navigate('/planner', {
      state: {
        date: toolbar.date.toString(),
      },
    });
  };

  const viewNamesGroup = () => {
    let messages = { month: t('month'), week: t('week'), day: t('day') };
    let viewNames = views.map((name) => (
      <button
        type="button"
        key={name}
        className={view === name ? 'rbc-active' : ''}
        onClick={() => onView(name)}
      >
        {messages[name]}
      </button>
    ));
    return <span className="rbc-btn-group">{viewNames}</span>;
  };

  return (
    <div className="rbc-toolbar" ref={tourRef.ref3}>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate(Navigate.PREVIOUS)}>
          {t('previous')}
        </button>
        <button type="button" onClick={() => onNavigate(Navigate.TODAY)}>
          {t('today')}
        </button>
        <button type="button" onClick={() => onNavigate(Navigate.NEXT)}>
          {t('next')}
        </button>
        <button type="button" onClick={handleRefresh}>
          {t('refresh')}
        </button>
      </span>
      <span className={`rbc-toolbar-label ${styles.monthLabel}`}>{label}</span>
      {viewNamesGroup()}
      <span className="rbc-btn-group" style={{ marginLeft: 'auto' }}>
        <button type="button" onClick={handleNew}>
          {t('addNew')}
        </button>
        {isBeforeOfPlanned && (
          <button type="button" onClick={showModal}>
            {t('addMonthInfo')}
          </button>
        )}
        {!isBeforeOfPlanned && (
          <button type="button" onClick={planMonth}>
            {t('planMonth')}
          </button>
        )}
      </span>
    </div>
  );
};

export default CustomToolbarDesktop;
