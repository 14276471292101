import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { request } from '../../store/reducers/plans/getPlansSlice';
import useAppState from '../../hooks/useAppState';

const useGetPlans = () => {
  const dispatch = useDispatch();
  const { userLogged, update } = useAppState();

  useEffect(() => {
    update('loaderActive', true);
    dispatch(request({ email: userLogged.email }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useGetPlans;
