import { useDispatch, useSelector } from 'react-redux';
import useAppState from '../../hooks/useAppState';
import {
  request,
  requestSend,
  readyForNextState,
} from '../../store/reducers/comments/commentsSlice';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { MessageContext } from '../../context/MessageContext';
import { STATUS } from '../../config/constants';
import { useTranslation } from 'react-i18next';

export const useComments = () => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const { userLogged } = useAppState();
  const comments = useSelector((state) => state.comments);
  const [loading, setLoading] = useState('');
  const { notification } = useContext(MessageContext);
  const { t } = useTranslation();

  const getComments = () => {
    const { email } = userLogged;
    setLoading(true);
    dispatch(request({ email }));
  };

  useEffect(() => {
    const { email } = userLogged;
    setLoading(
      comments.status === STATUS.FETCHING ||
        comments.statusSend === STATUS.FETCHING,
    );

    switch (comments.status) {
      case STATUS.SUCCESS:
        dispatch(readyForNextState());
        setComment(comments.data[email]);
        break;
      case STATUS.FAILURE:
        dispatch(readyForNextState());
        notification('error', t('error_get_comments'));
        break;

      default:
        break;
    }
    switch (comments.statusSend) {
      case STATUS.SUCCESS:
        dispatch(readyForNextState());
        notification('success', t('success'), {
          style: { marginBottom: '20px', marginTop: 'auto' },
        });
        break;
      case STATUS.FAILURE:
        dispatch(readyForNextState());
        notification('error', t('error_send_comments'));
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const sendComments = (myComment) => {
    const { email } = userLogged;
    dispatch(requestSend({ email, comment: myComment }));
  };

  return { getComments, sendComments, loading, comment };
};
