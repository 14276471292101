import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { STATUS } from '../../config/constants';
import useAppState from '../../hooks/useAppState';
import useDeleteAccount from '../../hooks/useDeleteAccount';
import {
  update as updateProfile,
  updatePhoto,
  readyForNextState as readyUserForNextState,
} from '../../store/reducers/users/usersSlice';
import { request as requestSendPlansTarget } from '../../store/reducers/plansTarget/sendPlansTargetSlice';
import { MessageContext } from '../../context/MessageContext';
import { useTranslation } from 'react-i18next';
import { cloudMode } from '../../store/reducers/appConfig';

export const useProfile = () => {
  const dispatch = useDispatch();
  const context = useAppState();
  const { t } = useTranslation();
  const { update } = useAppState();
  const { deleteAccount } = useDeleteAccount();

  const { notification } = useContext(MessageContext);

  const {
    status: statusUsers,
    error,
    data,
  } = useSelector((state) => state.users);

  const handleDeleteAccount = () => {
    deleteAccount(context.userLogged.email);
  };

  const updateUser = (data) => {
    update('loaderActive', true);
    dispatch(updateProfile({ email: context.userLogged.email, newData: data }));
  };

  const updateProfilePhotoUser = (data) => {
    update('loaderActive', true);
    dispatch(updatePhoto({ email: context.userLogged.email, photo: data }));
    update('userLogged', { ...context.userLogged, photo: data });
  };

  useEffect(() => {
    console.log('statusUsers', statusUsers);
    if (statusUsers === STATUS.SUCCESS) {
      dispatch(readyUserForNextState());
      update('loaderActive', false);
      const updatedUser = data[context.userLogged.email];
      const attributes = JSON.parse(updatedUser.photoURL);
      dispatch(cloudMode({ wMode: updatedUser.wMode }));

      dispatch(
        requestSendPlansTarget({
          email: context.userLogged.email,
          hours: attributes.hours,
          periodicity: attributes.periodicity,
        }),
      );

      update('userLogged', updatedUser);
      notification('success', t('user.updated'));
    }
    if (statusUsers === STATUS.FAILURE) {
      const { message } = error;
      notification('error', t(message));
      dispatch(readyUserForNextState());
      update('loaderActive', false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUsers]);

  return { updateUser, updateProfilePhotoUser, handleDeleteAccount };
};

export default useProfile;
