import { put, select, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/activities/sendActivitiesSlice';
import { success as succesActivities } from '../../reducers/activities/activitiesSlice';
import { upsertDataByEmail } from '../../../firestore';

export function* sendActivitiesSaga({ payload: input }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const data = yield select((state) => state.activities.data);
    const response = { ...data };
    const { email, activities } = input;

    response[email] = activities.map((event) => {
      const newEvent = { ...event };
      newEvent.start =
        typeof event.start === 'object'
          ? event.start.toISOString()
          : event.start;
      if (event.end) {
        newEvent.end =
          typeof event.end === 'object' ? event.end.toISOString() : event.end;
      }
      return { ...newEvent };
    });

    if (wMode === 'local') {
      yield put(succesActivities(response));
      yield put(success());
      return;
    }

    const sendActivitiesFirestore = yield upsertDataByEmail("activities",
      email,
      response[email],
    );

    if (sendActivitiesFirestore.success) {
      yield put(succesActivities(response));
      yield put(success());
      return;
    }

    throw new Error('error_send_events');
  } catch (error) {
    console.error(error);
    yield put(
      failure({
        message: error.message,
        status: error.message === 'error_send_events' ? 404 : 500,
      }),
    );
  }
}

export default function* sendActivities() {
  yield takeLatest(request, sendActivitiesSaga);
}
