import { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { resetState } from '../store/reducers/appConfig';
import { deleteMyAccount } from '../store/reducers/users/usersSlice';
import {
  request as requestDeleteAccount,
  readyForNextState as readyForNextStateDeleteAccount,
} from '../store/reducers/deleteAccount/deleteAccountSlice';
import {
  request as requestDeleteActivitiesByEmail,
  readyForNextState as readyForNextStateDeleteActivities,
} from '../store/reducers/activities/deleteActivitiesSlice';
import {
  request as requestPlansByEmail,
  readyForNextState as readyForNextStateDeletePlans,
} from '../store/reducers/plans/deletePlansSlice';
import {
  request as requestPlansTargetByEmail,
  readyForNextState as readyForNextStateDeletePlansTarget,
} from '../store/reducers/plansTarget/deletePlansTargetSlice';
import {
  request as requestCommentsByEmail,
  readyForNextState as readyForNextStateDeleteComments,
} from '../store/reducers/comments/deleteCommentsSlice';

import useAppState from './useAppState';
import { MessageContext } from '../context/MessageContext';
import { STATUS } from '../config/constants';

export const useDeleteAccount = (isAdmin) => {
  const dispatch = useDispatch();
  const { update } = useAppState();
  const { t } = useTranslation();
  const { notification } = useContext(MessageContext);
  const [email, setEmail] = useState('');
  const [isReset, setIsReset] = useState(false);

  const { status: statusDeleteAccount, error: errorDeleteAccount } =
    useSelector((state) => state.deleteAccount);
  const { status: statusDeleteActivities, error: errorDeleteActivities } =
    useSelector((state) => state.deleteActivities);
  const { status: statusDeletePlans, error: errorDeletePlans } = useSelector(
    (state) => state.deletePlans,
  );
  const { status: statusDeletePlansTarget, error: errorDeletePlansTarget } =
    useSelector((state) => state.deletePlansTarget);
  const { status: statusDeleteComments, error: errorDeleteComments } =
    useSelector((state) => state.deleteComments);

  useEffect(() => {
    if (
      statusDeleteActivities === STATUS.SUCCESS &&
      statusDeletePlans === STATUS.SUCCESS &&
      statusDeleteComments === STATUS.SUCCESS &&
      (isReset || statusDeletePlansTarget === STATUS.SUCCESS)
    ) {
      if (!isReset && !isAdmin && email) {
        dispatch(requestDeleteAccount({ email: email }));
      }
      readyForNext();
    } else if (
      statusDeleteActivities === STATUS.FAILURE ||
      statusDeletePlans === STATUS.FAILURE ||
      statusDeletePlansTarget === STATUS.FAILURE ||
      statusDeleteComments === STATUS.FAILURE
    ) {
      if (statusDeleteActivities === STATUS.FAILURE) {
        const { message } = errorDeleteActivities;
        notification('error', t(message));
      }
      if (statusDeletePlans === STATUS.FAILURE) {
        const { message } = errorDeletePlans;
        notification('error', t(message));
      }
      if (statusDeletePlansTarget === STATUS.FAILURE) {
        const { message } = errorDeletePlansTarget;
        notification('error', t(message));
      }
      if (statusDeleteComments === STATUS.FAILURE) {
        const { message } = errorDeleteComments;
        notification('error', t(message));
      }
      readyForNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusDeleteActivities,
    statusDeletePlans,
    statusDeletePlansTarget,
    statusDeleteComments,
  ]);

  function readyForNext() {
    dispatch(readyForNextStateDeleteActivities());
    dispatch(readyForNextStateDeletePlans());
    dispatch(readyForNextStateDeleteComments());
    if (!isReset) {
      dispatch(readyForNextStateDeletePlansTarget());
    }
  }

  const deleteAccount = (email, isReset) => {
    setEmail(email);
    dispatch(requestDeleteActivitiesByEmail({ email: email }));
    dispatch(requestPlansByEmail({ email: email }));
    dispatch(requestCommentsByEmail({ email: email }));
    if (!isReset) {
      setIsReset(isReset);
      dispatch(requestPlansTargetByEmail({ email: email }));
    }
  };

  useEffect(() => {
    if (statusDeleteAccount === STATUS.SUCCESS) {
      update('userLogged', undefined);
      dispatch(readyForNextStateDeleteAccount());
      dispatch(resetState());
      dispatch(deleteMyAccount({ email: email }));
    }

    if (statusDeleteAccount === STATUS.FAILURE) {
      console.log('errorDeleteAccount', errorDeleteAccount);
      dispatch(readyForNextStateDeleteAccount());
      const { message } = errorDeleteAccount;
      notification('error', t(message));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDeleteAccount]);

  return {
    deleteAccount,
  };
};

export default useDeleteAccount;
