import { put, select, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/plansTarget/sendPlansTargetSlice';
import { updateState } from '../../reducers/plansTarget/plansTargetSlice';
import { upsertDataByEmail } from '../../../firestore';
import { plannerHours } from '../../../utils/plannerHours';

export function* sendPlansTargetSaga({ payload: input }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const { email, hours, periodicity } = input;
    const { targetHours, hoursWeekly, minutesWeekly } = plannerHours(
      periodicity,
      hours,
    );

    if (wMode === 'local') {
      yield put(
        updateState({
          email,
          plansTarget: {
            targetHours,
            hoursWeekly,
            minutesWeekly,
          },
        }),
      );
      yield put(success());
      return;
    }

    const sendPlansTargetFirestore = yield upsertDataByEmail("plansTarget", email, {
      targetHours,
      hoursWeekly,
      minutesWeekly,
    });

    if (sendPlansTargetFirestore.success) {
      yield put(
        updateState({
          email,
          plansTarget: {
            targetHours,
            hoursWeekly,
            minutesWeekly,
          },
        }),
      );
      yield put(success());
      return;
    }

    throw new Error('error_send_plansTarget');
  } catch (error) {
    yield put(
      failure({
        message: error.message,
        status: error.message === 'error_send_plansTarget' ? 404 : 500,
      }),
    );
  }
}

export default function* sendPlansTarget() {
  yield takeLatest(request, sendPlansTargetSaga);
}
