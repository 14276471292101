import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS } from '../../config/constants';
import {
  readyForNextState,
  request,
} from '../../store/reducers/signin/signInSlice';
import useAppState from '../../hooks/useAppState';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../context/MessageContext';

import { useNavigate } from 'react-router-dom';
import {
  request as getActivitiesRequest,
  readyForNextState as readyForNextStateActivities,
} from '../../store/reducers/activities/getActivitiesSlice';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import {
  cloudMode,
  tourCalendar,
  tourPlan,
} from '../../store/reducers/appConfig';

export const useSingIn = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.users);
  const { status, error } = useSelector((state) => state.signin);
  const { data: activities } = useSelector((state) => state.activities);
  const { status: getActivitiesStatus } = useSelector(
    (state) => state.getActivities,
  );

  const [loading, setLoading] = useState(status);
  const [results, setResults] = useState(data);
  const [currentUser, setCurrentUser] = useState();
  const { update } = useAppState();
  const { notification } = useContext(MessageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const singInRequest = (data) => {
    setCurrentUser(data.email);
    update('loaderActive', true);
    dispatch(request(data));
  };

  useEffect(() => {
    setLoading(status === STATUS.FETCHING);
    if (status === STATUS.SUCCESS) {
      dispatch(cloudMode({ wMode: data[currentUser].wMode }));
      setResults(data[currentUser]);
      update('userLogged', data[currentUser]);
      notification('success', t('welcome'));
      update('loaderActive', false);
      dispatch(readyForNextState());
      const userEmail = data[currentUser]['email'];

      dispatch(getActivitiesRequest({ email: userEmail }));
      dispatch(tourCalendar({ hideTourCalendar: true }));
      dispatch(tourPlan({ hideTour: true }));
    }

    if (status === STATUS.FAILURE) {
      const { message } = error;
      update('loaderActive', false);
      notification('error', t(message));
      dispatch(readyForNextState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  useEffect(() => {
    const redirect = async () => {
      if (activities[currentUser].length > 0) {
        return navigate('/');
      } else {
        navigate('/planner');
      }
    };
    if (getActivitiesStatus === STATUS.SUCCESS) {
      console.log('***readyForNextStateActivities', getActivitiesStatus);
      dispatch(readyForNextStateActivities());
      redirect();
    }
    if (getActivitiesStatus === STATUS.FAILURE) {
      dispatch(readyForNextStateActivities());
      navigate('/planner');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivitiesStatus]);

  const resetPassword = async (email) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.error('error', error);
      return false;
    }
  };

  return { singInRequest, resetPassword, loading, results };
};

export default useSingIn;
