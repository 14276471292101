import React, { useEffect } from 'react';
import { theme, Switch } from 'antd';
import styles from './FooterApp.module.scss';
import { ReactComponent as Sun } from '../../assets/images/sun_icon.svg';
import { ReactComponent as Moon } from '../../assets/images/moon_icon.svg';
import CustomMenu from '../CustomMenu/CustomMenu';
import useAppState from '../../hooks/useAppState';
import { request } from '../../store/reducers/darkMode/darkModeSlice';
import { useDispatch, useSelector } from 'react-redux';

const { useToken } = theme;

const FooterApp = ({ toggleMode }) => {
  const dispatch = useDispatch();
  const context = useAppState();
  const { token } = useToken();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  useEffect(() => {
    toggleMode(isDarkMode);
  }, [isDarkMode, toggleMode]);

  return (
    <div
      className={styles.Footer}
      style={{ backgroundColor: token.colorBgElevated }}
    >
      <Switch
        checked={isDarkMode}
        onChange={(item) => {
          dispatch(request(!item));
        }}
        checkedChildren={<Sun style={{ fill: 'white' }} />}
        unCheckedChildren={<Moon style={{ fill: 'white' }} />}
      />

      {context.userLogged && <CustomMenu />}
    </div>
  );
};

export default FooterApp;
