import { useSpring, animated, config } from 'react-spring';
import './SwipeHint.scss';
import arrowIcon from '../../assets/images/swipe-hand-arrow.png';
import { useEffect, useState } from 'react';
import useTour from '../CustomCalendar/tour/useTour';

const SwipeHint = ({ className = '', style = {}, setShowSwipeHint }) => {
  const [animationCount, setAnimationCount] = useState(0);
  const { hideHandTour } = useTour();
  const animation = useSpring({
    from: { transform: 'translateX(-100px)' },
    to: { transform: 'translateX(100px)' },
    config: config.slow,
    loop: { reverse: true },
    onRest: () => setAnimationCount(animationCount + 1),
  });

  useEffect(() => {
    if (animationCount >= 4) {
      hideHandTour();
      setShowSwipeHint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationCount]);

  return (
    <div className={`swipe-hint ${className}`} style={style}>
      <animated.img
        style={animation}
        src={arrowIcon}
        alt="Arrow icon"
        className="swipe-hint__arrow"
      />
    </div>
  );
};

export default SwipeHint;
