import { put, select, takeLatest } from 'redux-saga/effects';
import { failure, request, success } from '../../reducers/plans/sendPlansSlice';
import { upsertDataByEmail } from '../../../firestore';

export function* sendPlansSaga({ payload: input }) {
  try {
    const { wMode } = yield select((state) => state.appConfig);
    const { email, plans } = input;

    if (wMode === 'local') {
      yield put(success());
      return;
    }
    const sendPlansFirestore = yield upsertDataByEmail('plans', email, plans);

    if (sendPlansFirestore.success) {
      yield put(success());
      return;
    }

    throw new Error('error_send_plans');
  } catch (error) {
    yield put(
      failure({
        message: error.message,
        status: error.message === 'error_send_plans' ? 404 : 500,
      }),
    );
  }
}

export default function* sendPlans() {
  yield takeLatest(request, sendPlansSaga);
}
