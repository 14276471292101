import { createSlice } from '@reduxjs/toolkit';

import { STATUS } from '../../../config/constants';

const initialState = {
  data: {},
  status: STATUS.NONE,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.data = undefined;
    },
    logout: (state, action) => {
      const { email } = action.payload;
      const currentUser = state.data[email];
      delete currentUser.logged;
    },
    deleteMyAccount: (state, action) => {
      const { email } = action.payload;
      delete state.data[email];
    },
    success: (state, action) => {
      state.status = STATUS.SUCCESS;
      const { email } = action.payload;
      let newData = {
        [email]: {
          ...state.data[email],
          ...action.payload,
          logged: true,
        },
      };
      if (state.data) {
        newData = { ...state.data, ...newData };
      }
      state.data = {
        ...newData,
      };
    },
    failure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },
    update: () => {},
    updateSuccess: (state, action) => {
      const { previousEmail, ...updatedFields } = action.payload;

      const updatedUser = {
        ...updatedFields,
      };
      delete state.data[previousEmail];

      state.status = STATUS.SUCCESS;
      state.data[updatedFields.email] = updatedUser;
    },
    updateFailure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },
    updatePhoto: (state) => {
      state.status = STATUS.FETCHING;
    },
    updatePhotoSuccess: (state, action) => {
      const { email, photo } = action.payload;
      state.data = {
        ...state.data,
        [email]: { ...action.payload, photo: photo },
      };
      state.status = STATUS.SUCCESS;
    },
    updatePhotoFailure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },
    readyForNextState: (state) => {
      state.status = STATUS.NONE;
      state.error = undefined;
    },
  },
});

export const {
  resetState,
  request,
  logout,
  success,
  failure,
  update,
  updateFailure,
  updateSuccess,
  updatePhoto,
  updatePhotoFailure,
  updatePhotoSuccess,
  readyForNextState,
  deleteMyAccount,
} = usersSlice.actions;

export const status = (state) => state.users.status;

export const data = (state) => state.users.data;

export const error = (state) => state.users.error;

export default usersSlice.reducer;
