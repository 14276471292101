import { Col, Modal, Row, Tooltip, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styles from './CustomModalMonth.module.scss';

import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import useResponsive from '../../hooks/useResponsive';
import CustomHourPicker from '../CustomHourPicker';
import NumericInput from '../CustomNumericInput';

dayjs.extend(customParseFormat);

const { useToken } = theme;

const CustomModalMonth = ({
  visible,
  month,
  handleOk,
  handleCancel,
  assetsEnable,
}) => {
  const { t } = useTranslation();
  const { token } = useToken();
  const { isMobile } = useResponsive();
  const [newMonthData, setNewMonthData] = useState({
    extra: true,
    disabled: false,
    monthActivity: true,
    assets: {
      p: month.pubTotal,
      r: month.revTotal,
      e: month.stuTotal,
      v: month.vidTotal,
    },
  });
  const localHandleOk = () => {
    if (!newMonthData.hours) {
      return;
    }
    handleOk(newMonthData);
  };

  const commonStyles = {
    color: token.titleTextColor,
    marginTop: '0px',
    marginBottom: '0px',
  };

  const handleHours = (time) => {
    setNewMonthData((prev) => ({
      ...prev,
      hours: `${time <= 9 ? `0${time}` : time}:00`,
    }));
  };

  const handleInputChange = (name, value) => {
    setNewMonthData((prev) => ({
      ...prev,
      assets: {
        ...prev.assets,
        [name]: parseInt(value),
      },
    }));
  };

  useEffect(() => {
    setNewMonthData((prev) => ({
      ...prev,
      assets: {
        p: month.pubTotal,
        r: month.revTotal,
        e: month.stuTotal,
        v: month.vidTotal,
      },
    }));
  }, [month]);

  if (visible) {
    return (
      <Modal
        title={`${t('registerFor')} ${month.currentMonth}`}
        okText={t('submit')}
        cancelText={t('cancel')}
        open={visible}
        onOk={localHandleOk}
        onCancel={handleCancel}
        className={styles.modal}
      >
        <Row>
          <Col sm={12} md={8} xl={10} xs={8}>
            <Title
              level={5}
              align="left"
              style={{ ...commonStyles, color: token.colorText }}
            >
              <Tooltip title={t('hours_register')} className={styles.lastItem}>
                {t('hours_register')}
              </Tooltip>
            </Title>
          </Col>
          <Col sm={12} md={12} xl={14} xs={8}>
            <CustomHourPicker
              onChange={handleHours}
              defaultValue={month.workedShowed}
            />
          </Col>
        </Row>
        <Row gutter={4} align={'middle'} style={{ marginTop: 10 }}>
          {assetsEnable && (
            <>
              <Col xs={6}>
                <Title
                  level={5}
                  align="center"
                  style={{ ...commonStyles, color: token.colorText }}
                >
                  {isMobile ? (
                    <Tooltip title={t('pubs')} className={styles.lastItem}>
                      {t('pub')}
                    </Tooltip>
                  ) : (
                    t('pubs')
                  )}
                </Title>
              </Col>
              <Col xs={6}>
                <Title
                  level={5}
                  align="center"
                  style={{ ...commonStyles, color: token.colorText }}
                >
                  {isMobile ? (
                    <Tooltip title={t('videos')} className={styles.lastItem}>
                      {t('vid')}
                    </Tooltip>
                  ) : (
                    t('videos')
                  )}
                </Title>
              </Col>
              <Col xs={6}>
                <Title
                  level={5}
                  align="center"
                  style={{ ...commonStyles, color: token.colorText }}
                >
                  {isMobile ? (
                    <Tooltip title={t('visits')} className={styles.lastItem}>
                      {t('ret')}
                    </Tooltip>
                  ) : (
                    t('visits')
                  )}
                </Title>
              </Col>
            </>
          )}
          <Col xs={assetsEnable ? 6 : 8} md={assetsEnable ? 6 : 8}>
            <Title
              level={5}
              align={assetsEnable ? 'center' : 'left'}
              style={{ ...commonStyles, color: token.colorText }}
            >
              {isMobile ? (
                <Tooltip title={t('studies')} className={styles.lastItem}>
                  {t('studies')}
                </Tooltip>
              ) : (
                t('studies')
              )}
            </Title>
          </Col>
          {!assetsEnable && (
            <Col xs={6} sm={6} md={assetsEnable ? 6 : 8}>
              <NumericInput
                placeholder={t('studies')}
                value={newMonthData?.assets?.e}
                onChange={(value) => handleInputChange('e', value)}
              />
            </Col>
          )}
        </Row>
        {assetsEnable && (
          <Row gutter={4} style={{ marginTop: 0 }}>
            <Col xs={6} sm={6}>
              <NumericInput
                placeholder={t('pubs')}
                value={newMonthData?.assets?.p}
                onChange={(value) => handleInputChange('p', value)}
              />
            </Col>
            <Col xs={6} sm={6}>
              <NumericInput
                placeholder={t('videos')}
                value={newMonthData?.assets?.v}
                onChange={(value) => handleInputChange('v', value)}
              />
            </Col>
            <Col xs={6} sm={6}>
              <NumericInput
                placeholder={t('visits')}
                value={newMonthData?.assets?.r}
                onChange={(value) => handleInputChange('r', value)}
              />
            </Col>

            <Col xs={6} sm={6}>
              <NumericInput
                placeholder={t('studies')}
                value={newMonthData?.assets?.e}
                onChange={(value) => handleInputChange('e', value)}
              />
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
};

export default CustomModalMonth;
