import { createSlice } from '@reduxjs/toolkit';

import { STATUS } from '../../../config/constants';

const initialState = {
  data: {},
  status: STATUS.NONE,
};

export const signUpSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.data = undefined;
    },
    readyForNextState: (state) => {
      state.status = STATUS.NONE;
      state.error = undefined;
    },
    request: (state) => {
      state.status = STATUS.FETCHING;
    },
    success: (state) => {
      state.status = STATUS.SUCCESS;
    },
    failure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },
  },
});

export const { resetState, readyForNextState, request, success, failure } =
  signUpSlice.actions;

export const status = (state) => state.signup.status;

export const data = (state) => state.signup.data;

export const error = (state) => state.signup.error;

export default signUpSlice.reducer;
