import moment from "moment";

export const plannerHours = (periodicityUser, hoursUser) => {
  let targetHours = null;
  let hoursWeekly = null;
  let minutesWeekly = null;
  switch (periodicityUser) {
    case "weekly":
      targetHours = Number(hoursUser);
      hoursWeekly = Math.floor(
        moment.duration(targetHours * 60, "minutes").asHours()
      );
      minutesWeekly =
        moment.duration(targetHours * 60, "minutes").get("minutes") < 10
          ? `0${moment.duration(targetHours * 60, "minutes").get("minutes")}`
          : moment.duration(targetHours * 60, "minutes").get("minutes");
      break;
    case "monthly":
      targetHours = Number(hoursUser) / 4;
      hoursWeekly = Math.floor(
        moment.duration(targetHours * 60, "minutes").asHours()
      );
      minutesWeekly =
        moment.duration(targetHours * 60, "minutes").get("minutes") < 10
          ? `0${moment.duration(targetHours * 60, "minutes").get("minutes")}`
          : moment.duration(targetHours * 60, "minutes").get("minutes");
      break;
    case "yearly":
      targetHours = Number(hoursUser) / (4 * 12);
      hoursWeekly = Math.floor(
        moment.duration(targetHours * 60, "minutes").asHours()
      );
      minutesWeekly =
        moment.duration(targetHours * 60, "minutes").get("minutes") < 10
          ? `0${moment.duration(targetHours * 60, "minutes").get("minutes")}`
          : moment.duration(targetHours * 60, "minutes").get("minutes");
      break;
    default:
      break;
  }

  return {
    targetHours,
    hoursWeekly,
    minutesWeekly,
  };
};
