import { put, takeLatest, select } from 'redux-saga/effects';
import {
  readyForNextState,
  request,
  success,
} from '../../reducers/darkMode/darkModeSlice';

export function* darkModeSaga() {
  const darkMode = yield select((state) => state.darkMode);

  yield put(success({ isDarkMode: !darkMode.isDarkMode }));
  yield put(readyForNextState());
}

export default function* darkMode() {
  yield takeLatest(request, darkModeSaga);
}
