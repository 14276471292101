import { put, takeLatest } from 'redux-saga/effects';
import {
  failure,
  request,
  success,
} from '../../reducers/plansTarget/getAllPlansTargetSlice';
import { success as successPlanTarget } from '../../reducers/plansTarget/plansTargetSlice';
import { findData } from '../../../firestore';

export function* getAllPlansTargetSaga() {
  try {
    const plansTargetFirestore = yield findData('plansTarget');

    if (plansTargetFirestore.success && plansTargetFirestore.data) {
      yield put(success());
      yield put(
        successPlanTarget({
          ...plansTargetFirestore.data,
        }),
      );
      return;
    }

    throw new Error('error_get_all_plans_target');
  } catch (error) {
    yield put(failure({ message: error.message, status: 500 }));
  }
}

export default function* getAllPlansTarget() {
  yield takeLatest(request, getAllPlansTargetSaga);
}
