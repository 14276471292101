import { useState, useEffect } from 'react';
import { Input, List, Popover, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import styles from './CustomTimePicker.module.scss';

const { Text } = Typography;

const TimePicker = ({ value, onChange, max, errorText }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let [hours, minutes] = value.split(':').map(Number);
    let [maxHours, maxMinutes] = max.split(':').map(Number);
    setError(hours > maxHours || (hours === maxHours && minutes > maxMinutes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleChange = (type, newvalue) => {
    let [hours, minutes] = value.split(':');
    if (type === 'hour') {
      hours = newvalue;
    } else {
      minutes = newvalue;
    }
    onChange([hours, minutes].join(':'));
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  const getNumberArray = (max) => {
    return Array.from({ length: max }, (_, i) => (i < 10 ? `0${i}` : `${i}`));
  };

  const content = (
    <div className={styles.Popover}>
      <div className={styles.Popover__content}>
        <List
          split={false}
          itemLayout="vertical"
          dataSource={getNumberArray(51)}
          className={styles.Popover__content__list}
          renderItem={(item) => (
            <List.Item className={styles.Popover__content__list__item}>
              <Button
                type={value.split(':')[0] === item ? 'primary' : 'text'}
                onClick={() => handleChange('hour', item)}
                disabled={value.split(':')[0] === item}
              >
                {item}
              </Button>
            </List.Item>
          )}
        />
        <List
          split={false}
          itemLayout="vertical"
          dataSource={getNumberArray(60)}
          className={styles.Popover__content__list}
          renderItem={(item) => (
            <List.Item className={styles.Popover__content__list__item}>
              <Button
                type={value.split(':')[1] === item ? 'primary' : 'text'}
                onClick={() => handleChange('minute', item)}
                disabled={value.split(':')[1] === item}
              >
                {item}
              </Button>
            </List.Item>
          )}
        />
      </div>
      <Button type="primary" size="small" onClick={handleSubmit}>
        OK
      </Button>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Input
        readOnly
        suffix={<ClockCircleOutlined />}
        value={value}
        status={error ? 'error' : undefined}
      />
      {error && <Text type="danger">{errorText || t('invalidData')}</Text>}
    </Popover>
  );
};

export default TimePicker;
