import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import 'moment/locale/es';

import ChildrenCollapse from '../../components/ChildrenCollapse/ChildrenCollapse';
import useAppState from '../../hooks/useAppState';
import {
  request as requestSendPlans,
  readyForNextState as readyForNextStateSendPlans,
} from '../../store/reducers/plans/sendPlansSlice';
import {
  request as requestPlans,
  readyForNextState as readyForNextStatePlans,
} from '../../store/reducers/plans/plansSlice';
import {
  request as requestSendActivities,
  readyForNextState,
} from '../../store/reducers/activities/sendActivitiesSlice';
import {
  request as requestUpdateActivities,
  readyForNextState as readyForNextStateUpdateActivities,
} from '../../store/reducers/activities/updateActivitiesSlice';
import { FORMAT_TIME, STATUS } from '../../config/constants';
import { MessageContext } from '../../context/MessageContext';
import { modifyActivitiesFromTo } from '../../utils/modifyActivitiesFromTo';

moment.defineLocale('custom', {
  week: {
    dow: 1, // Lunes es el primer día de la semana.
  },
});

const initialStatePlanning = {
  0: [{ key: uuidv4(), from: '', to: '' }],
  1: [{ key: uuidv4(), from: '', to: '' }],
  2: [{ key: uuidv4(), from: '', to: '' }],
  3: [{ key: uuidv4(), from: '', to: '' }],
  4: [{ key: uuidv4(), from: '', to: '' }],
  5: [{ key: uuidv4(), from: '', to: '' }],
  6: [{ key: uuidv4(), from: '', to: '' }],
};

const usePlanner = () => {
  const { notification } = useContext(MessageContext);
  const { i18n, t } = useTranslation();
  const { status: statusSendActivities, error: errorsSendActivities } =
    useSelector((state) => state.sendActivities);
  const { status: statusSendPlans, error: errorSendPlans } = useSelector(
    (state) => state.sendPlans,
  );
  const { data: activitiesData } = useSelector((state) => state.activities);
  const { status: updateActivitiesStatus, error: updateActivitiesErrors } =
    useSelector((state) => state.updateActivities);
  const { userLogged, update } = useAppState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status: statusPlans, error: errorPlans } = useSelector(
    (state) => state.plans,
  );
  const { status: statusGetPlans, error: errorGetPlans } = useSelector(
    (state) => state.getPlans,
  );
  const { data: dataPlans } = useSelector((state) => state.plans);
  const [updateFromToday, setUpdateFromToday] = useState(false);

  const [planHours, setPlanHours] = useState(0);
  const [planHoursShowed, setPlanHoursShowed] = useState('0:00');
  const [schedule, setSchedule] = useState(12);
  const [planning, setPlanning] = useState(initialStatePlanning);
  const [daysNamesLabel, setDaysNamesLabel] = useState([]);
  const [daysNames, setDaysNames] = useState([]);

  useEffect(() => {
    if (statusPlans === STATUS.SUCCESS) {
      dispatch(readyForNextStatePlans());
    }
    if (statusPlans === STATUS.FAILURE) {
      dispatch(readyForNextStatePlans());
      const { message } = errorPlans;
      notification('error', t(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPlans]);

  useEffect(() => {
    if (statusSendPlans === STATUS.SUCCESS) {
      dispatch(readyForNextStateSendPlans());
      dispatch(
        requestPlans({
          planning: { ...initialStatePlanning },
          email: userLogged.email,
        }),
      );
    }
    if (statusSendPlans === STATUS.FAILURE) {
      dispatch(readyForNextStateSendPlans());
      update('loaderActive', false);
      const { message } = errorSendPlans;
      notification('error', t(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSendPlans]);

  useEffect(() => {
    if (statusSendActivities === STATUS.SUCCESS) {
      notification('success', t('success_send_plan'));
      dispatch(readyForNextState());
      setTimeout(() => {
        update('loaderActive', false);
        navigate('/');
      }, 1500);
    }
    if (statusSendActivities === STATUS.FAILURE) {
      dispatch(readyForNextState());
      const { message } = errorsSendActivities;
      notification('error', t(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSendActivities]);

  useEffect(() => {
    if (updateActivitiesStatus === STATUS.SUCCESS) {
      dispatch(readyForNextStateUpdateActivities());
    }
    if (updateActivitiesStatus === STATUS.FAILURE) {
      const { message } = updateActivitiesErrors;
      notification('error', t(message));
      dispatch(readyForNextStateUpdateActivities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateActivitiesStatus]);

  useEffect(() => {
    setDaysNames(moment.weekdays());
    moment.locale(i18n.language);
    const days = moment.weekdays();
    const sunday = days.shift();
    days.push(sunday);
    setDaysNamesLabel(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (statusGetPlans === STATUS.SUCCESS) {
      update('loaderActive', false);
      if (dataPlans[userLogged.email]) {
        setPlanning(dataPlans[userLogged.email]);
      }
    }
    if (statusGetPlans === STATUS.FAILURE) {
      update('loaderActive', false);
      console.log('errorGetPlans', errorGetPlans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusGetPlans]);

  useEffect(() => {
    let minutesCount = 0;
    for (const key in planning) {
      const day = planning[key];
      const dailyMinutes = day.reduce((accumulator, currentValue) => {
        if (currentValue.from === '' && currentValue.to === '') {
          return accumulator;
        }
        const initialHour = moment(currentValue.from, FORMAT_TIME);
        const endHour = moment(currentValue.to, FORMAT_TIME);
        const result = Math.abs(initialHour.diff(endHour, 'm'));
        // return accumulator + moment.duration(result, "minutes").asHours();
        return accumulator + result;
      }, 0);
      minutesCount += dailyMinutes;
    }
    setPlanHours(minutesCount / 60);
    const hours = Math.floor(
      moment.duration(minutesCount, 'minutes').asHours(),
    );
    const minutes =
      moment.duration(minutesCount, 'minutes').get('minutes') < 10
        ? `0${moment.duration(minutesCount, 'minutes').get('minutes')}`
        : moment.duration(minutesCount, 'minutes').get('minutes');
    setPlanHoursShowed(`${hours}:${minutes}`);
  }, [planning]);

  const addActivitiesTo = (day) => {
    const newActivities = JSON.parse(JSON.stringify(planning));
    newActivities[day] = [
      ...planning[day],
      { key: uuidv4(), from: '', to: '' },
    ];
    setPlanning({ ...newActivities });
  };

  const removeActivitiesTo = (day, uuid) => {
    const newPlanning = JSON.parse(JSON.stringify(planning));
    newPlanning[day] = newPlanning[day].filter((act) => act.key !== uuid);
    if (newPlanning[day].length === 0) {
      newPlanning[day][0] = { key: uuidv4(), from: '', to: '' };
    }
    setPlanning({ ...newPlanning });
    dispatch(requestPlans({ planning: newPlanning, email: userLogged.email }));
  };

  const updateActivities = (day, activity, dateString) => {
    const newPlanning = JSON.parse(JSON.stringify(planning));
    const [from, to] = dateString;
    const newActivity = newPlanning[day].find(
      (act) => act.key === activity.key,
    );
    newActivity.from = from;
    newActivity.to = to;
    setPlanning({ ...newPlanning });
    dispatch(
      requestPlans({
        planning: { ...newPlanning },
        email: userLogged.email,
      }),
    );
  };

  const resetActivities = (
    removeAllPlans,
    removeAllPlansWorked,
    removeAllPlansMonth,
    monthFromCalendar,
  ) => {
    let initialDate = moment().utc().add(1, 'day').startOf('day');
    let endDate = undefined;
    const activitiesDataCopy = [...activitiesData[userLogged.email]];
    if (removeAllPlansMonth) {
      initialDate = moment(monthFromCalendar).utc().startOf('month');
      endDate = moment(monthFromCalendar).utc().endOf('month');
    }
    const activities = activitiesDataCopy.filter(
      (event) => {
        if (removeAllPlans && removeAllPlansWorked) {
          return false;
        }
        if (removeAllPlans && !removeAllPlansWorked) {
          return event.status === 2 ? true : false;
        }
        if (removeAllPlansMonth && removeAllPlansWorked) {
          return moment(event.start).utc().isBefore(initialDate) ||
            moment(event.start).utc().isAfter(endDate)
            ? true
            : false;
        }
        if (removeAllPlansMonth && !removeAllPlansWorked) {
          return moment(event.start).utc().isBefore(initialDate) ||
            moment(event.start).utc().isAfter(endDate) ||
            event.status === 2
            ? true
            : false;
        }
      },
      // removeAllPlans ? false : initialDate.isAfter(event.start),
    );
    setPlanning(initialStatePlanning);
    dispatch(
      requestSendPlans({
        plans: { ...initialStatePlanning },
        email: userLogged.email,
      }),
    );
    dispatch(
      requestUpdateActivities({
        activities,
        email: userLogged.email,
      }),
    );
  };

  const days = (panelStyle, ref) =>
    daysNames.map((d, i) => ({
      key: d,
      label: daysNamesLabel[i],
      style: panelStyle,
      children: (
        <ChildrenCollapse
          addActivitiesTo={addActivitiesTo}
          removeActivitiesTo={removeActivitiesTo}
          updateActivities={updateActivities}
          day={i}
          activities={planning}
          ref={i === 0 ? ref : {}}
        />
      ),
    }));

  const generateSchedule = () => {
    let initialDate = moment().utc().startOf('month');
    const lastDateOfMonth = moment()
      .utc()
      .add(schedule, 'months')
      .endOf('month');
    let activities = [];
    modifyActivitiesFromTo(initialDate, lastDateOfMonth, activities, planning);
    update('loaderActive', true);
    dispatch(
      requestSendPlans({
        email: userLogged.email,
        plans: planning,
      }),
    );
    dispatch(
      requestSendActivities({
        activities,
        email: userLogged.email,
      }),
    );
  };

  const generateNextSchedule = () => {
    let initialDate = moment().utc().add(1, 'day').startOf('day');
    if (updateFromToday) {
      initialDate = moment().utc().startOf('day');
    }
    const lastDateOfMonth = moment()
      .utc()
      .add(schedule, 'months')
      .endOf('month');
    let activities = [];
    for (const key in activitiesData[userLogged.email]) {
      const element = activitiesData[userLogged.email][key];
      if (moment(element.start).utc().isBefore(initialDate)) {
        activities.push(element);
      }
    }
    modifyActivitiesFromTo(initialDate, lastDateOfMonth, activities, planning);
    update('loaderActive', true);
    dispatch(
      requestSendPlans({
        email: userLogged.email,
        plans: planning,
      }),
    );

    dispatch(
      requestSendActivities({
        activities,
        email: userLogged.email,
      }),
    );
  };

  const changeUpdateFromToday = () => {
    setUpdateFromToday((odlValue) => !odlValue);
  };

  const replaceMonthPlan = (date) => {
    const initialDate = moment(date).utc().startOf('month');
    const lastDateOfMonth = moment(date).utc().endOf('month');
    let activities = [];
    for (const key in activitiesData[userLogged.email]) {
      const element = activitiesData[userLogged.email][key];
      if (
        moment(element.start).utc().isBefore(initialDate) ||
        moment(element.start).utc().isAfter(lastDateOfMonth) ||
        element.status === 2
      ) {
        activities.push(element);
      }
    }
    modifyActivitiesFromTo(initialDate, lastDateOfMonth, activities, planning);
    update('loaderActive', true);
    dispatch(
      requestSendPlans({
        email: userLogged.email,
        plans: planning,
      }),
    );

    dispatch(
      requestSendActivities({
        activities,
        email: userLogged.email,
      }),
    );
  };

  const addMonthPlan = (date) => {
    const initialDate = moment(date).utc().startOf('month');
    const lastDateOfMonth = moment(date).utc().endOf('month');
    let activities = [...activitiesData[userLogged.email]];
    modifyActivitiesFromTo(initialDate, lastDateOfMonth, activities, planning);
    update('loaderActive', true);
    dispatch(
      requestSendPlans({
        email: userLogged.email,
        plans: planning,
      }),
    );

    dispatch(
      requestSendActivities({
        activities,
        email: userLogged.email,
      }),
    );
  };

  return {
    planHours,
    planHoursShowed,
    schedule,
    updateFromToday,
    changeUpdateFromToday,
    generateSchedule,
    generateNextSchedule,
    setSchedule,
    resetActivities,
    days,
    replaceMonthPlan,
    addMonthPlan,
    activities: activitiesData?.[userLogged.email],
  };
};

export default usePlanner;
