import {
  Button,
  Col,
  Modal,
  Row,
  Switch,
  TimePicker,
  Tooltip,
  Input,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { v4 as uuidv4 } from 'uuid';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import Title from 'antd/es/typography/Title';

import styles from './CustomModalTimePicker.module.scss';
import { BASE_EVENTS, FORMAT_TIME } from '../../config/constants';
import { useEffect, useState } from 'react';
import useResponsive from '../../hooks/useResponsive';
import NumericInput from '../CustomNumericInput';
import CustomDatePicker from '../CustomDatePicker';

const { TextArea } = Input;

dayjs.extend(customParseFormat);

const CustomModalTimePicker = ({
  visible,
  event,
  start,
  end,
  handleOk,
  handleCancel,
  token,
  deleteEventById,
  assetsEnable,
  loading,
  success,
}) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useResponsive();

  const [newDate, setNewDate] = useState();
  const [labelButton, setLabelButton] = useState('');
  const [currentEventModal, setCurrentEventModal] = useState();
  const [isWorkingDisabled, setIsWorkingDisabled] = useState(false);

  useEffect(() => {
    if (success) {
      setNewDate();
      setLabelButton('');
      setCurrentEventModal({ ...BASE_EVENTS });
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dayjs.locale(i18n.language);
    moment.locale(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setIsWorkingDisabled(false);
    if (!event) {
      setCurrentEventModal(BASE_EVENTS);
    } else {
      setCurrentEventModal({ ...BASE_EVENTS, ...event });
      setLabelButton(getTimeDifference(undefined, event));
      if (!event.id && event.start) {
        checkDate(event.start);
      }
    }
  }, [event]);

  const handleInputChange = (name, value) => {
    setCurrentEventModal({
      ...currentEventModal,
      assets: {
        ...currentEventModal.assets,
        [name]: parseInt(value),
      },
    });
  };

  const commonStyles = {
    color: token.titleTextColor,
    marginTop: '0px',
    marginBottom: '0px',
  };

  const getTimeDifference = (momentArray, event) => {
    const eventDiff = moment(event.end).diff(moment(event.start));

    const formatDuration = (duration) => {
      const hours = duration.hours();
      const minutes = String(duration.minutes()).padStart(2, '0');
      return `${hours ? hours : '0'}:${minutes}h`;
    };

    // Si momentArray es undefined, retorna el intervalo de event.start y event.end
    if (momentArray === undefined) {
      return formatDuration(moment.duration(eventDiff));
    }

    if (momentArray === null) {
      return '';
    }

    const momentDiff = momentArray[1].diff(momentArray[0]);

    return formatDuration(moment.duration(momentDiff));
  };

  const handleChange = (date, dateString) => {
    setLabelButton(getTimeDifference(date, currentEventModal));
    setCurrentEventModal((prev) => ({ ...prev, register: dateString }));
  };

  const getTimePickerStatus = () => {
    if (currentEventModal.register) {
      [start, end] = currentEventModal.register;
      if (dayjs(start, FORMAT_TIME).isAfter(dayjs(end, FORMAT_TIME))) {
        return 'error';
      }
    }
    return undefined;
  };

  const getOkText = () => {
    let text = '';
    if (event?.id) {
      const isGreatherToday = moment().isBefore(
        moment(currentEventModal.start),
      );
      if (isGreatherToday) {
        text = t('update');
      } else {
        text = t('submit');
      }
    } else {
      text = t('save');
    }
    return text.concat(` ${labelButton}`);
  };

  const localHandleOk = () => {
    if (!event) {
      setCurrentEventModal((prev) => ({
        ...prev,
        id: uuidv4(),
      }));
      if (!newDate || !currentEventModal.register) {
        return;
      }
    }
    const data = { ...currentEventModal };
    let [start, end] = [data.start, data.end];

    if (data.register) {
      [start, end] = data.register;
    }

    if (newDate) {
      const adjustDateTime = (date) => {
        const adjustedDate = newDate
          .set('hour', date.split(':')[0])
          .set('minute', date.split(':')[1])
          .set('second', '0');
        return adjustedDate.toDate();
      };

      data.start = adjustDateTime(start);
      data.end = adjustDateTime(end);
    }

    handleOk(data.id, data);
  };

  const handleDelete = () => {
    deleteEventById(event.id);
  };

  const onChange = (checked) => {
    setCurrentEventModal((prev) => ({ ...prev, status: checked }));
  };

  const onChangeComments = (comments) => {
    setCurrentEventModal((prev) => ({ ...prev, comments: comments }));
  };

  function formatDate(obj) {
    if (obj && obj.start) {
      return dayjs(obj.start);
    }

    return dayjs();
  }

  function checkDate(date) {
    const isGreatherToday = dayjs(new Date()).isBefore(date);
    if (isGreatherToday) {
      setCurrentEventModal((prev) => ({ ...prev, status: false }));
      setIsWorkingDisabled(true);
    } else {
      setIsWorkingDisabled(false);
    }
  }

  const selectNewDate = (date) => {
    checkDate(date);
    setNewDate(date);
  };

  if (visible) {
    return (
      <Modal
        title={
          !event?.id ? (
            <span className={styles.spanContainer}>
              <CustomDatePicker
                className={styles.item}
                defaultValue={formatDate(event)}
                format={t('dateFormatLong')}
                onChange={selectNewDate}
              />
            </span>
          ) : (
            `${t('registerFor')} ${moment(event?.start).format(
              'dddd, DD MMMM YYYY',
            )}`
          )
        }
        okText={getOkText()}
        cancelText={t('cancel')}
        open={visible}
        onOk={localHandleOk}
        onCancel={() => {
          handleCancel();
          setLabelButton('');
        }}
        className={styles.modal}
        confirmLoading={loading === 'add' || loading === 'update'}
        okButtonProps={{
          disabled:
            getTimePickerStatus() === 'error' ||
            (loading && loading !== 'add' && loading !== 'update'),
        }}
      >
        <Row gutter={10}>
          <Col xs={21}>
            <TimePicker.RangePicker
              inputReadOnly={true}
              changeOnBlur={true}
              onChange={handleChange}
              defaultValue={[
                event?.start ? dayjs(start, FORMAT_TIME) : null,
                event?.end ? dayjs(end, FORMAT_TIME) : null,
              ]}
              style={{
                width: '100%',
                color: token.inputTextColor,
              }}
              className="timepickerClass"
              placeholder={[t('from'), t('to')]}
              format={FORMAT_TIME}
              order={false}
              status={getTimePickerStatus()}
            />
          </Col>
          {event?.id && (
            <Col xs={3}>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={handleDelete}
                loading={loading === 'delete'}
                disabled={loading && loading !== 'delete'}
              />
            </Col>
          )}
        </Row>
        <Row gutter={4} align={'middle'} style={{ marginTop: 10 }}>
          {assetsEnable && (
            <>
              <Col xs={6}>
                <Title
                  level={5}
                  align="center"
                  style={{ ...commonStyles, color: token.colorText }}
                >
                  {isMobile ? (
                    <Tooltip title={t('pubs')} className={styles.lastItem}>
                      {t('pub')}
                    </Tooltip>
                  ) : (
                    t('pubs')
                  )}
                </Title>
              </Col>
              <Col xs={6}>
                <Title
                  level={5}
                  align="center"
                  style={{ ...commonStyles, color: token.colorText }}
                >
                  {isMobile ? (
                    <Tooltip title={t('videos')} className={styles.lastItem}>
                      {t('vid')}
                    </Tooltip>
                  ) : (
                    t('videos')
                  )}
                </Title>
              </Col>
              <Col xs={6}>
                <Title
                  level={5}
                  align="center"
                  style={{ ...commonStyles, color: token.colorText }}
                >
                  {isMobile ? (
                    <Tooltip title={t('visits')} className={styles.lastItem}>
                      {t('ret')}
                    </Tooltip>
                  ) : (
                    t('visits')
                  )}
                </Title>
              </Col>
            </>
          )}
          <Col xs={assetsEnable ? 6 : 8}>
            <Title
              level={5}
              align="left"
              style={{ ...commonStyles, color: token.colorText }}
            >
              {isMobile ? (
                <Tooltip title={t('studies')} className={styles.lastItem}>
                  {t('studies')}
                </Tooltip>
              ) : (
                t('studies')
              )}
            </Title>
          </Col>
        </Row>
        <Row gutter={4} style={{ marginTop: 0 }}>
          {assetsEnable && (
            <>
              <Col xs={6} sm={6}>
                <NumericInput
                  value={currentEventModal?.assets?.p}
                  onChange={(value) => handleInputChange('p', value)}
                  placeholder={t('pubs')}
                />
              </Col>
              <Col xs={6} sm={6}>
                <NumericInput
                  value={currentEventModal?.assets?.v}
                  onChange={(value) => handleInputChange('v', value)}
                  placeholder={t('videos')}
                />
              </Col>
              <Col xs={6} sm={6}>
                <NumericInput
                  value={currentEventModal?.assets?.r}
                  onChange={(value) => handleInputChange('r', value)}
                  placeholder={t('visits')}
                />
              </Col>
            </>
          )}
          <Col xs={6} sm={assetsEnable ? 6 : 8}>
            <NumericInput
              value={currentEventModal?.assets?.e}
              onChange={(value) => handleInputChange('e', value)}
              placeholder={t('studies')}
            />
          </Col>
          {!assetsEnable && !event?.id && (
            <Col offset={2} xs={10} sm={11}>
              <Switch
                className={styles.switch}
                checkedChildren={t('worked')}
                unCheckedChildren={t('for_working')}
                onChange={onChange}
                checked={currentEventModal.status}
                disabled={isWorkingDisabled}
              />
            </Col>
          )}
        </Row>
        {assetsEnable && !event?.id && (
          <Row gutter={8} className={styles.switch}>
            <Col xs={13} sm={13}>
              <Switch
                checkedChildren={t('worked')}
                unCheckedChildren={t('for_working')}
                onChange={onChange}
                checked={currentEventModal.status}
                disabled={isWorkingDisabled}
              />
            </Col>
          </Row>
        )}
        <Row gutter={4} align={'middle'} style={{ marginTop: 10 }}>
          <Col xs={24}>
            <Title
              level={5}
              align="left"
              style={{ ...commonStyles, color: token.colorText }}
            >
              {isMobile ? (
                <Tooltip title={t('notes')} className={styles.lastItem}>
                  {t('notes')}
                </Tooltip>
              ) : (
                t('notes')
              )}
            </Title>
          </Col>
          <Col xs={24}>
            <TextArea
              value={currentEventModal.comments}
              onChange={(e) => onChangeComments(e.target.value)}
              placeholder={t('notes')}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
};

export default CustomModalTimePicker;
