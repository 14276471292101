import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  request,
  readyForNextState,
} from '../../store/reducers/plansTarget/getPlansTargetSlice';
import useAppState from '../../hooks/useAppState';
import { STATUS } from '../../config/constants';

const useGetPlansTarget = () => {
  const dispatch = useDispatch();
  const { status: statusGetPlansTarget, error: errorGetPlansTarget } =
    useSelector((state) => state.getPlansTarget);
  const { data: dataPlansTarget } = useSelector((state) => state.plansTarget);
  const { userLogged, update } = useAppState();
  const [targetHours, setTargetHours] = useState();
  const [targetHoursShowed, setTargetHoursShowed] = useState('0:00');

  useEffect(() => {
    update('loaderActive', true);
    dispatch(request({ email: userLogged.email }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusGetPlansTarget === STATUS.SUCCESS) {
      setTargetHours(dataPlansTarget[userLogged.email].targetHours);
      setTargetHoursShowed(
        `${dataPlansTarget[userLogged.email].hoursWeekly}:${
          dataPlansTarget[userLogged.email].minutesWeekly
        }`,
      );
      update('loaderActive', false);
      dispatch(readyForNextState());
    }
    if (statusGetPlansTarget === STATUS.FAILURE) {
      console.log('errorGetPlans', errorGetPlansTarget);
      update('loaderActive', false);
      dispatch(readyForNextState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusGetPlansTarget]);

  return {
    targetHours,
    targetHoursShowed,
  };
};

export default useGetPlansTarget;
