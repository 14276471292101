import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../../../config/constants";

const initialState = {
  data: {},
  status: STATUS.NONE,
};

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.data = undefined;
    },
    resetStateByEmail: (state, action) => {
      state.status = STATUS.NONE;
      delete state.data[action.payload.email];
    },
    readyForNextState: (state) => {
      state.status = STATUS.NONE;
      state.error = undefined;
    },
    request: (state) => {
      state.status = STATUS.FETCHING;
    },
    success: (state, action) => {
      state.status = STATUS.SUCCESS;
      state.data = action.payload;
    },
    failure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },
    updateState: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { resetState, readyForNextState, request, success, failure, updateState, resetStateByEmail } = plansSlice.actions;

export const status = (state) => state.plans.status;

export const data = (state) => state.plans.data;

export const error = (state) => state.plans.error;

export default plansSlice.reducer;
