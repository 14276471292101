import {
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  collection,
} from 'firebase/firestore';
import { firestore } from '../firebase';

const upsertDataByEmail = async (collection, email, data) => {
  try {
    const docRef = doc(firestore, collection, email);
    await setDoc(docRef, { data });

    return { success: true, message: 'Upsert realizado con éxito.' };
  } catch (error) {
    console.error(`Error en upsertDataByEmail (${collection}):`, error);
    return { success: false, message: error.message };
  }
};

const findDataByEmail = async (collection, email) => {
  try {
    const docRef = doc(firestore, collection, email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        success: true,
        message: 'Find realizado con éxito.',
        data: docSnap.data().data,
      };
    } else {
      throw new Error('Document not found');
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const deleteDataByEmail = async (collection, email) => {
  try {
    const docRef = doc(firestore, collection, email);
    await deleteDoc(docRef);
    return {
      success: true,
      message: 'Delete realizado con éxito.',
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

const findData = async (collectionName) => {
  try {
    const queryRef = collection(firestore, collectionName);
    const querySnapshot = await getDocs(queryRef);
    const data = {};
    querySnapshot.forEach((doc) => {
      data[doc.id] = doc.data().data;
    });

    return {
      success: true,
      message: 'Find realizado con éxito.',
      data: data,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export { upsertDataByEmail, findDataByEmail, deleteDataByEmail, findData };
