import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import useAppState from './useAppState';

const useSplitTimeWorked = () => {
  const { userLogged } = useAppState();
  const { data: dataActivities } = useSelector((state) => state.activities);
  const { t } = useTranslation();

  const reduceTimeInMonth = ({ minutes, hours, currentDate, activities }) => {
    let minutesToSplit = minutes + hours * 60;
    activities.forEach((activity) => {
      const start = moment(activity.start);
      const end = moment(activity.end);
      const isSameMonth = currentDate.isSame(activity.start, 'month');

      if (!isSameMonth || activity.status === 0) {
        return;
      }
      if (minutesToSplit === 0) {
        return;
      }

      let movedTime = false;
      if (activity.monthActivity) {
        let [hours, minutes] = activity.hours.split(':').map(Number);
        minutes += hours * 60;

        if (minutesToSplit > minutes) {
          minutesToSplit -= minutes;
          minutes = 0;
        } else {
          minutes -= minutesToSplit;
          minutesToSplit = 0;
        }

        hours = +(minutes / 60).toFixed();
        minutes = +(minutes % 60).toFixed();
        activity.hours = `${hours > 9 ? hours : `0${hours}`}:${
          minutes > 9 ? minutes : `0${minutes}`
        }`;
        movedTime = true;
      } else {
        const minutesDiff = end.diff(start, 'minutes');
        if (minutesDiff > 0) {
          let subtract;
          if (minutesToSplit > minutesDiff) {
            subtract = minutesDiff;
            minutesToSplit -= minutesDiff;
          } else {
            subtract = minutesToSplit;
            minutesToSplit = 0;
          }
          activity.end = end
            .subtract(subtract, 'minutes')
            .toDate()
            .toISOString();
          movedTime = true;
        }
      }

      if (movedTime) {
        activity.comments = activity.comments
          ? `${activity.comments}\n${t('movedToNextMonth')}`
          : t('movedToNextMonth');
      }
    });
  };

  /**
   *
   * @param {month, currentDate, minutes}
   * currentDate: object of useMonth
   * minutes: split time for next month in minutes
   * hours: split time for next month in hours
   * discard: add new activity in next month
   */
  const forNextMonth = ({
    currentDate,
    minutes = 0,
    hours = 0,
    discard = false,
  }) => {
    console.log('minutes', minutes);
    console.log('discard', discard);
    const currentDateCopy = moment(currentDate.toDate().toISOString());

    const dataActivitiesCopy = JSON.parse(JSON.stringify(dataActivities));
    const start = currentDateCopy.add(1, 'month').startOf('month');

    if (!discard) {
      let minutesCopy = minutes + hours * 60;
      let startCopy = moment(start.toDate().toISOString());
      const dayMinutes = 1439;
      const events = [];

      const surplusActivities = dataActivitiesCopy[userLogged.email].filter(
        (activity) =>
          activity.surplusActivity && start.isSame(activity.start, 'month'),
      );
      if (surplusActivities.length > 0) {
        const surplusStart = moment(
          surplusActivities[surplusActivities.length - 1].start,
        );
        const surplusEnd = moment(
          surplusActivities[surplusActivities.length - 1].end,
        );
        startCopy = moment(surplusStart.toDate().toISOString()).add(1, 'days');

        const minutesDiff = surplusEnd.diff(surplusStart, 'minutes');
        const minutes = dayMinutes - minutesDiff;

        let add;
        if (minutesCopy > minutes) {
          minutesCopy -= minutes;
          add = minutes;
        } else {
          add = minutesCopy;
          minutesCopy = 0;
        }
        surplusEnd.add(add, 'minutes');

        surplusActivities[surplusActivities.length - 1].end = surplusEnd
          .toDate()
          .toISOString();
      }

      while (minutesCopy > 0) {
        let endDate;
        let newStart = moment(startCopy.toDate().toISOString()).add(
          events.length,
          'days',
        );

        if (minutesCopy > dayMinutes) {
          endDate = newStart.clone().add(dayMinutes, 'minutes');
          minutesCopy -= dayMinutes;
        } else {
          endDate = newStart.clone().add(minutesCopy, 'minutes');
          minutesCopy = 0;
        }

        const event = {
          id: dataActivitiesCopy[userLogged.email].length + events.length + 1,
          start: newStart.toDate().toISOString(),
          end: endDate.toDate().toISOString(),
          status: 3,
          extra: true,
          disabled: false,
          comments: t('surplus'),
          surplusActivity: true,
        };
        events.push(event);
      }

      dataActivitiesCopy[userLogged.email] =
        dataActivitiesCopy[userLogged.email].concat(events);
    }

    reduceTimeInMonth({
      minutes,
      hours,
      currentDate,
      activities: dataActivitiesCopy[userLogged.email],
    });

    dataActivitiesCopy[userLogged.email] = dataActivitiesCopy[
      userLogged.email
    ].sort((a, b) => a.start.localeCompare(b.start));
    return dataActivitiesCopy[userLogged.email];
  };

  return { forNextMonth };
};

export default useSplitTimeWorked;
