import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "../../../config/constants";

const initialState = {
  status: STATUS.NONE,
};

export const sendPlansSlice = createSlice({
  name: "sendPlansTarget",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = STATUS.NONE;
      state.data = undefined;
    },
    request: (state) => {
      state.status = STATUS.FETCHING;
    },
    success: (state) => {
      state.status = STATUS.SUCCESS;
    },
    failure: (state, action) => {
      const { payload: error } = action;
      state.status = STATUS.FAILURE;
      state.error = error;
    },
    readyForNextState: (state) => {
      state.status = STATUS.NONE;
      state.error = undefined;
    },
  },
});

export const { resetState, request, success, failure, readyForNextState } =
  sendPlansSlice.actions;

export const status = (state) => state.sendPlansTarget.status;

export const error = (state) => state.sendPlansTarget.error;

export default sendPlansSlice.reducer;
