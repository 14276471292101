import PropTypes from "prop-types";
import React, { useState } from "react";

import { EMPTY_OBJECT } from "../config/constants";
import { useSelector } from "react-redux";

const AppContext = React.createContext([EMPTY_OBJECT, () => {}]);

const AppContextProvider = ({ children }) => {
  const { data } = useSelector((state) => state.users);

  const getCurrentUser = () => {
    if (data) {
      const emails = Object.keys(data);
      for (const e of emails) {
        if (data[e].logged) {
          return data[e];
        }
      }
    }
    return null;
  };

  const [state, setState] = useState({
    userLogged: getCurrentUser(),
    firstTime: true,
    loaderActive: false,
    isReady: false,
    isMock: false,
    language: "",
    isAssetsEnable: process.env.REACT_APP_ASSETS_ENABLE === "true",
  });

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};
AppContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { AppContext, AppContextProvider };
