import { theme } from "antd";
import GridLoader from "react-spinners/GridLoader";

const { useToken } = theme;
const CustomLoader = ({ active = false, children }) => {
  const { token } = useToken();

  return (
    <div id="forScrolling" style={{position: "relative", height: "120%" }}>
      {children}
      {active && (
        <div style={overlayStyle}>
          <GridLoader size={30} color={token.colorPrimary} />
        </div>
      )}
    </div>
  );
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  height: "100vh",
  zIndex: 1000
};

export default CustomLoader;
