import { put, takeLatest, select } from "redux-saga/effects";
import {
  update,
  updateFailure,
  updateSuccess,
} from "../../reducers/users/usersSlice";
import { getAuth, updateProfile, updatePassword } from "firebase/auth";

export function* updateUserSaga({ payload: input }) {
  const users = yield select((state) => state.users.data);
  const user = users[input.email];

  if (!user) {
    return yield put(updateFailure({ message: "user.noupdated", status: 400 }));
  }

  try {
    const { newData } = input;
    const attributes = JSON.stringify({
      hours: newData.hours,
      periodicity: newData.periodicity,
      // role: newData.role,
      wMode: newData.wMode,
    });

    const updatedUser = {
      ...user,
      ...newData,
      photoURL: attributes,
    };

    if (newData.wMode === "cloud") {
      const auth = getAuth();
      yield updateProfile(auth.currentUser, JSON.stringify({
        displayName: newData.displayName,
        photoURL: attributes,
      }));
      if (newData.password) {
        yield updatePassword(auth.currentUser, newData.password).catch((error => {
          throw new Error(error);
        }));
      }
    }
    yield put(updateSuccess(updatedUser));
  } catch (error) {
    console.error(error);
    yield put(updateFailure({ message: "user.noupdated", status: 400 }));
  }
}

export default function* updateUser() {
  yield takeLatest(update, updateUserSaga);
}
