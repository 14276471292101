import { theme } from "antd";
import React from "react";

const { useToken } = theme;

function Terms() {
  const { token } = useToken();
  const WeekPlannerName = () => {
    return <strong>WeekPlanner</strong>;
  };
  return (
    <div
      style={{
        margin: "20px",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        color: token.titleTextColor,
      }}
    >
      <h1>
        CONSENTIMIENTO DE TRATAMIENTO DE TUS DATOS PERSONALES A TRAVÉS DE
        FORMULARIOS WEB
      </h1>

      <section>
        <h2>1.- Presentación y derecho de información</h2>
        <p>
          <strong>WeekPlanner</strong> se toma muy en serio la protección de la
          privacidad y de tus datos personales. Por lo tanto, tu información
          personal se conserva de forma segura y se trata con el máximo cuidado.
        </p>
        <p>
          La presente política de protección de datos regula el acceso y el uso
          del Sitio Web{" "}
          <a
            href="https://weekplanner.impactotecnologico.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://weekplanner.impactotecnologico.net
          </a>{" "}
          (en adelante, {WeekPlannerName}), que los responsables del sitio web
          {WeekPlannerName} (en adelante, {WeekPlannerName}), sin domicilio al
          ser un servicio online, pone a disposición de los Usuarios (en
          adelante, los Usuarios o el Usuario) de Internet interesados en los
          servicios prestados por esta entidad y en los contenidos alojados en
          el Sitio Web.
        </p>
        <p>
          De conformidad con lo establecido por la Ley Orgánica de Protección de
          Datos y Garantía de Derechos Digitales 3/2018 (LOPDGDD), y a su
          normativa de desarrollo, {WeekPlannerName} te informa que existe un
          fichero automatizado de datos de carácter personal, almacenados en la
          nube en una base de datos debidamente protegida, creada por{" "}
          {WeekPlannerName} y bajo su responsabilidad y único destino, y cuya
          actividad es la prestación de la plataforma web de planificación de
          horas de trabajo.
        </p>
      </section>

      <section>
        <h2>
          2.- Procesamiento de datos personales, finalidad del tratamiento y
          consentimiento
        </h2>
        <p>
          Tus datos y los de los demás son recabados por {WeekPlannerName} con
          la finalidad de:
        </p>
        <ol>
          <li>
            poder prestarte los servicios ofrecidos a través del Sitio Web,
          </li>
          <li>
            enviar notificaciones vía email, SMS, teléfono, u otras aplicaciones
            de mensajería que estén enlazadas con tu correo electrónico o número
            de teléfono móvil provisto por ti mismo/a,
          </li>
          <li>
            la realización de encuestas puntuales que tienen por finalidad
            conocer tu nivel de satisfacción, y la calidad y la mejora del
            servicio,
          </li>
          <li>
            remitir comunicaciones de frecuencia variable para informar de
            novedades e instrucciones relacionados con los servicios que
            proporciona {WeekPlannerName}, formación, avisos y otras
            notificaciones expresas del servicio online prestado sin costo y sin
            carácter comercial, mediante teléfono, SMS, correo electrónico u
            otros medios de comunicación electrónica equivalentes, siempre
            usando los datos proporcionados por ti como usuario de{" "}
            {WeekPlannerName},
          </li>
          <li>
            elaborar estadísticas anónimas que se generan a partir de las
            interacciones de los usuarios para mejorar el funcionamiento de
            {WeekPlannerName}.
          </li>
        </ol>
        <p>
          y ello al amparo de lo establecido en la Ley de Servicios de la
          Sociedad de la Información y del Comercio Electrónico 34/2002 de 11 de
          Julio (LSSI), la Ley Orgánica de Protección de Datos y Garantía de
          Derechos Digitales 3/2018 (LOPDGDD), el RPGD o la Ley de
          Telecomunicaciones 9/2014 del 9 de mayo.
        </p>
        <p>
          Te informamos de que, en el supuesto de que hayas consentido la
          recepción de dicha información, podrás oponerte en todo momento a que
          tus datos sean tratados con esta finalidad, así como solicitar más
          información sobre el tratamiento de tus datos personales, mediante el
          envío de un correo electrónico a la siguiente dirección{" "}
          <a href="mailto:info@impactotecnologico.net">
            info@impactotecnologico.net
          </a>
          , usando los medios específicos que se reconozcan en las propias
          comunicaciones.
        </p>
      </section>

      <section>
        <h3>2.1- Procesamiento de datos en caso de menores</h3>
        <p>
          Según la Agencia Española de Protección de Datos (AEPD - basada en el
          boletín oficial del Estado BOE-A-2018-16673 Art. 7 y Art. 12.6), el
          Instituto Nacional de Ciberseguridad (INCIBE) y en conformidad con el
          Reglamento General de Protección de Datos (RGDP - artículo 8
          Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
          de abril de 2016), los menores de 14 años del deben ser autorizados
          por su padre, madre o tutor legal para poder registrar sus datos y
          hacer uso de {WeekPlannerName}. En su caso, tanto el menor de edad
          como cualquiera de los padres del menor, tiene derecho a ejercer
          cualquier decisión según el punto 5 de este mismo contrato de
          consentimiento.
        </p>
        <p>
          Si un menor de 14 años desea registrarse y usar los servicios
          proporcionados por {WeekPlannerName} debe ser autorizado por uno de
          sus padres, o bien un tutor legal, que también debe estar registrado
          en la aplicación, al menos con permisos de lectura de actividad de su
          cuenta, y con la potestad de poder autorizar mediante un sistema
          automático o petición escrita a{" "}
          <a href="mailto:info@impactotecnologico.net">
            info@impactotecnologico.net
          </a>{" "}
          la aceptación de registro del menor.
        </p>
        <p>
          Asimismo, y en cumplimiento del citado BOE-A-2018-16673 Art. 12.6, los
          titulares de la patria potestad podrán ejercitar en nombre y
          representación de los menores de catorce años los derechos de acceso,
          rectificación, cancelación, oposición o cualesquiera otros que
          pudieran corresponderles en el contexto de la presente ley orgánica.
          Con lo cual, {WeekPlannerName} ofrece la posibilidad a dichos
          titulares la capacidad de acceder a dichos datos y ejercer sus
          derechos sobre los mismo cuando el usuario es menor de edad,
          independientemente de que haya cedido sus datos con 14 años o más
          siendo menor de edad.
        </p>
      </section>

      <section>
        <h3>
          2.2- ¿Cómo {WeekPlannerName} obtiene y procesa tus datos en la nube?
        </h3>
        <p>Principalmente, proceden de:</p>
        <ul>
          <li>
            Formulario de contacto externos a la aplicación (Google Forms, por
            escrito, o por correo electrónico personal).
          </li>
          <li>Registro que haces por ti mismo/a en {WeekPlannerName}</li>
        </ul>
      </section>

      <section>
        <h3>2.3- ¿Cuánto tiempo almacena {WeekPlannerName} tus datos?</h3>
        <p>
          Los datos personales proporcionados se conservarán previsiblemente
          hasta que tú nos solicites su supresión, o hasta que {WeekPlannerName}
          determine que ya no son necesarios.
        </p>
      </section>

      <section>
        <h2>
          3.- Carácter obligatorio o facultativo de la información facilitada
          por el usuario y veracidad de los datos
        </h2>
        <p>
          Como Usuario, garantizas que los datos personales facilitados son
          veraces y te haces responsable de comunicar a {WeekPlannerName}{" "}
          cualquier modificación de estos, ya sea mediante contacto por correo
          electrónico a{" "}
          <a href="mailto:info@impactotecnologico.net">
            info@impactotecnologico.net
          </a>{" "}
          o introduciendo los cambios directamente en la aplicación.
          Responderás, en cualquier caso, de la veracidad de los datos
          facilitados y del uso de los mismos tanto si son tuyos como si estás
          ejerciendo tu titularidad de patria potestad, reservándose{" "}
          {WeekPlannerName}
          el derecho a excluirte de los servicios registrados si los datos que
          has facilitado son falsos, sin perjuicio de las demás acciones que
          procedan en derecho.
        </p>
        <p>
          Te recomendamos tener la máxima diligencia en materia de Protección de
          Datos mediante la utilización de herramientas de seguridad personales,
          tanto informatizadas como de formación y conocimiento general para
          proteger tus datos, no pudiéndose responsabilizar a {WeekPlannerName}{" "}
          de sustracciones, modificaciones o pérdidas de datos ilícitas. Para
          ello te recomendamos que visites páginas que te ayudarán a estar más
          consciente de esta auto-protección en páginas como INCIBE, o en su
          canal oficial de YouTube, y mantenerte al día de las últimas novedades
          en cuanto a protección digital se refiere para mantener tus datos
          seguros y confidenciales.
        </p>
      </section>

      <section>
        <h2>4.- Cesión de datos</h2>
        <p>
          {WeekPlannerName} nunca cederá tus datos ni de ningún otro usuario
          registrado en el servicio a terceros de forma deliberada, siendo
          solamente {WeekPlannerName} quien almacene expresamente los datos
          facilitados por ti mismo/a, salvo obligación legal.
        </p>
        <p>
          {WeekPlannerName} no colabora con ninguna otra empresa, entidad,
          agrupación ni terceros de ningún tipo fuera de {WeekPlannerName}, con
          lo cual los datos del Usuario nunca serán cedidos a terceros, salvo
          obligación legal.
        </p>
      </section>

      <section>
        <h2>5.- Derechos de acceso, rectificación, cancelación y oposición</h2>
        <p>
          Te informamos de que puedes ejercer tus derechos de Acceso,
          Rectificación, Portabilidad, Limitación, Cancelación y Oposición en
          cualquier momento, mediante el envío de un correo electrónico a la
          dirección{" "}
          <a href="mailto:info@impactotecnologico.net">
            info@impactotecnologico.net
          </a>
          , sin necesidad de dar una razón personal. La solicitud deberá
          contener los siguientes datos según estés registrado/a en la
          aplicación: nombre, apellidos, y correo electrónico (debe ser el mismo
          correo desde el que escribes), y tu deseo de acceder a los datos,
          modificar o limitar el acceso, o si decides darte de baja de forma
          temporal, permanente, o definitiva. Estas solicitudes se tramitarán
          manualmente en un máximo de 7 días desde la petición.
        </p>
        <p>
          También, te facilitamos un medio de acceso a la baja temporal,
          permanente o definitiva a través de {WeekPlannerName}. Este proceso es
          automático e implicará la eliminación por completo de tus datos
          personales registrados y toda la actividad procesada con tu
          identificador único de usuario en la aplicación, excepto si la baja es
          temporal, en cuyo caso permanecerán archivados y no serán accesibles
          más que por ti mismo/a y por los responsables de {WeekPlannerName}.
        </p>
        <p>
          Cualquier baja, ya sea permanente o definitiva, que hayas llevado a
          cabo no impedirá que en el futuro puedas volver a registrarte, previa
          autorización de {WeekPlannerName}.
        </p>
        <p>
          {WeekPlannerName} no conservará ningún dato personal si deseas darte
          de baja permanente o definitiva.
        </p>
      </section>

      <section>
        <h2>6.- Medidas de seguridad</h2>
        <p>
          {WeekPlannerName} mantiene los niveles de seguridad de protección de
          datos personales conforme a la LOPD y al Real Decreto 1720/2007, de 21
          de diciembre, por el que se aprueba el Reglamento de desarrollo de la
          LOPD que contengan datos de carácter personal y ha establecido todos
          los medios técnicos a su alcance para evitar la pérdida, mal uso,
          alteración, acceso no autorizado y robo de los datos que el Usuario
          facilite a través de {WeekPlannerName}, sin perjuicio de informarle de
          que las medidas de seguridad en Internet no son inexpugnables.
        </p>
        <p>
          {WeekPlannerName} se compromete a cumplir con el deber de secreto y
          confidencialidad respecto de los datos personales contenidos en el
          fichero automatizado de acuerdo con la legislación aplicable, así como
          a conferirles un tratamiento seguro en la navegación de contenidos,
          guardarlos y adaptar todas las medidas para evitar la alteración,
          pérdida, tratamiento o acceso no autorizado, de conformidad con lo
          establecido en la normativa vigente de protección de datos.
        </p>
        <p>
          {WeekPlannerName} incluye un certificado SSL. Es un protocolo de
          seguridad que hace que tus datos viajen de manera íntegra y segura
          desde tu dispositivo hasta la nube, de forma que la información se
          transmite totalmente cifrada o encriptada.
        </p>
        <p>
          {WeekPlannerName} no puede garantizar la absoluta inexpugnabilidad de
          la red de Internet y, por tanto, la violación de los datos mediante
          accesos fraudulentos a ellos por parte de terceros.
        </p>
        <p>
          Respecto a la confidencialidad del procesamiento de tus datos,
          {WeekPlannerName} y los responsables en los que se deleguen ciertas
          funcionalidades especiales de acceso a datos personales de otros
          usuarios se asegurarán de que cualquier persona que esté autorizada
          para procesar tus datos estará bajo la obligación apropiada de
          confidencialidad (ya sea un deber contractual o legal).
        </p>
        <p>
          Cuando se presente algún incidente de seguridad, al darse cuenta
          {WeekPlannerName}, te notificaremos sin demoras indebidas y te
          proporcionaremos información oportuna relacionada con el Incidente de
          Seguridad tal como se conozca o cuando tú mismo/a lo solicites
          razonablemente.
        </p>
      </section>

      <section>
        <h2>7.- Cambios contractuales</h2>
        <p>
          {WeekPlannerName} se reserva el derecho a modificar este documento
          para adaptarla a novedades legislativas o jurisprudenciales, así como
          a prácticas de la industria. Nos comprometemos a avisarte por
          cualquiera de los medios de que dispongamos para ponernos en contacto
          contigo y así hacerte saber los cambios que han sucedido después de la
          aceptación de un contrato previo al vigente con razonable antelación a
          su puesta en práctica. Estas novedades no solo se te enviarán por vías
          electrónicas, también te obligarán a autorizar los nuevos cambios para
          poder usar los servicios proporcionados por {WeekPlannerName}. De esa
          forma, siempre estarás al tanto de cómo {WeekPlannerName} se ajusta a
          lo que determina la ley.
        </p>
        <p>
          En caso de que desees no aceptar las nuevas condiciones, eres libre de
          rechazarla, y se te dará de baja permanente o definitiva del servicio,
          lo dejamos a tu elección.
        </p>
      </section>

      <section>
        <h2>8.- No aceptación de estos términos</h2>
        <p>
          Cualquier no aceptación por tu parte de cualquiera de estos términos
          actualmente o en el futuro conlleva tu baja permanente o definitiva
          (en función de lo que nos indique la modificación según los términos
          de la ley) de {WeekPlannerName}, y la eliminación de todos tus datos
          en el servicio.
        </p>
        <p>
          Si al rechazar la aceptación de estos términos o futuros te has
          decantado por darte de baja permanente, se te informará por medio de
          correo electrónico de los cambios en tu perfil en la aplicación y se
          te proporcionará un enlace por si deseas reactivar la cuenta (conlleva
          la introducción de todos tus datos desde cero). Si, por otro lado,
          deseas darte de baja definitiva, no se te proporcionará una vía para
          reactivar una cuenta.
        </p>
      </section>

      <section>
        <h2>9.- Bases legales</h2>
        <p>
          Las bases legales comprendidas en este texto en las que se guía el
          funcionamiento de {WeekPlannerName} son, específicamente:
        </p>
        <ul>
          <li>Agencia Española de Protección de Datos (AEPD).</li>
          <li>BOE-A-2018-16673 Art. 7 y Art. 12.6.</li>
          <li>INCIBE en relación con la protección de datos a menores.</li>
          <li>
            Reglamento General de Protección de Datos (Artículo RGDP - artículo
            8 Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de
            27 de abril de 2016).
          </li>
          <li>
            Carta de los derechos fundamentales de la Unión Europea (Enlace),
            especialmente Artículo II.
          </li>
          <li>
            Recopilación de legislación AEPD, a nivel general y especialmente
            para menores.
          </li>
          <li>
            Protección de datos personales, según las Fichas temáticas sobre la
            Unión Europea publicadas por el Parlamento Europeo.
          </li>
          <li>
            Real Decreto 1720/2007, de 21 de diciembre, para la protección de
            datos de carácter personal.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Terms;
