import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { STATUS } from '../../config/constants';
import useAppState from '../../hooks/useAppState';
import { readyForNextState } from '../../store/reducers/activities/sendActivitiesSlice';
import { getMaxDate, getMinDate } from '../../utils/dateUtils';
import 'moment/locale/es';
import { useLocation } from 'react-router-dom';

export const useMonth = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { userLogged } = useAppState();
  const { data } = useSelector((state) => state.activities);
  const { status, error } = useSelector((state) => state.sendActivities);
  const [loading, setLoading] = useState(status);
  const [month, setMonth] = useState([]);
  const location = useLocation();
  const [currentDate, setCurrentDate] = useState(
    location?.state?.date ? new Date(location.state.date) : undefined,
  );
  const minDate = getMinDate();
  const maxDate = getMaxDate();

  useEffect(() => {
    moment.locale(i18n.language);
    getMonthData({ date: moment(currentDate) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const getMonthData = ({ date }) => {
    setCurrentDate(date);
    if (
      !data[userLogged.email] ||
      Object.keys(data[userLogged.email]).length === 0
    ) {
      return;
    }
    const dataMonth = data[userLogged.email].filter((event) =>
      moment(event.start).isSame(date, 'month'),
    );
    const dataAllMonths = data[userLogged.email].filter(
      (event) =>
        moment(event.start).isAfter(minDate) &&
        moment(event.start).isBefore(maxDate),
    );

    const firstPlannedDay = data[userLogged.email].find(
      (item) => item.startPlanned,
    );
    const isBeforeOfPlanned = moment(date).isBefore(
      moment(firstPlannedDay.startAccessor).startOf('month'),
    );

    const currentNumberMonth = moment(date).utc().get('M');
    const currentMonth = moment().month(currentNumberMonth).format('MMMM');
    const goal = dataMonth.reduce(
      (accumulator, currentValue) =>
        currentValue.extra || currentValue.disabled === true
          ? accumulator
          : accumulator +
            moment(currentValue.endPlanned).diff(
              moment(currentValue.startPlanned),
              'm',
            ),
      0,
    );
    const hoursGoal = Math.floor(moment.duration(goal, 'minutes').asHours());
    const minutesGoal =
      moment.duration(goal, 'minutes').get('minutes') < 10
        ? `0${moment.duration(goal, 'minutes').get('minutes')}`
        : moment.duration(goal, 'minutes').get('minutes');
    const goalShowed = `${hoursGoal}:${minutesGoal}`;

    const worked = dataMonth.reduce((accumulator, currentValue) => {
      return Number(currentValue.status) === 0 ||
        currentValue.disabled === true ||
        moment(currentValue.end).diff(moment(currentValue.start), 'm') === 0
        ? accumulator
        : accumulator +
            moment(currentValue.end).diff(moment(currentValue.start), 'm');
    }, 0);
    const hoursWorked = Math.floor(
      moment.duration(worked, 'minutes').asHours(),
    );
    const minutesWorked =
      moment.duration(worked, 'minutes').get('minutes') < 10
        ? `0${moment.duration(worked, 'minutes').get('minutes')}`
        : moment.duration(worked, 'minutes').get('minutes');
    let workedShowed = `${hoursWorked}:${minutesWorked}`;

    const workedAll = dataAllMonths.reduce((accumulator, currentValue) => {
      if (currentValue.status === 0 || currentValue.disabled === true) {
        return accumulator;
      } else if (currentValue.monthActivity) {
        const [hours, minutes] = currentValue.hours.split(':').map(Number);
        const minutesCurrent = moment.duration({ hours, minutes }).asMinutes();
        return accumulator + minutesCurrent;
      } else {
        return (
          accumulator +
          moment(currentValue.end).diff(moment(currentValue.start), 'm')
        );
      }
    }, 0);
    const hoursAllWorked = Math.floor(
      moment.duration(workedAll, 'minutes').asHours(),
    );
    const minutesAllWorked =
      moment.duration(workedAll, 'minutes').get('minutes') < 10
        ? `0${moment.duration(workedAll, 'minutes').get('minutes')}`
        : moment.duration(workedAll, 'minutes').get('minutes');
    let workedAllShowed = `${hoursAllWorked}:${minutesAllWorked}`;

    const outstanding = goal - worked;
    let wantingShowed;
    if (outstanding > 0) {
      const hoursWanting = Math.floor(
        moment.duration(outstanding, 'minutes').asHours(),
      );
      const minutesWanting =
        moment.duration(outstanding, 'minutes').get('minutes') < 10
          ? `0${moment.duration(outstanding, 'minutes').get('minutes')}`
          : moment.duration(outstanding, 'minutes').get('minutes');

      wantingShowed = `${hoursWanting}:${minutesWanting}`;
    } else {
      wantingShowed = '00:00';
    }
    const pubTotal = dataMonth.reduce((accumulator, currentValue) => {
      if (
        currentValue.status !== 0 &&
        currentValue.disabled === false &&
        currentValue.assets?.p
      ) {
        return accumulator + currentValue.assets.p;
      }
      return accumulator;
    }, 0);
    const vidTotal = dataMonth.reduce((accumulator, currentValue) => {
      if (
        currentValue.status !== 0 &&
        currentValue.disabled === false &&
        currentValue.assets?.v
      ) {
        return accumulator + currentValue.assets.v;
      }
      return accumulator;
    }, 0);
    const revTotal = dataMonth.reduce((accumulator, currentValue) => {
      if (
        currentValue.status !== 0 &&
        currentValue.disabled === false &&
        currentValue.assets?.r
      ) {
        return accumulator + currentValue.assets.r;
      }
      return accumulator;
    }, 0);
    const stuTotal = dataMonth.reduce((accumulator, currentValue) => {
      if (
        currentValue.status !== 0 &&
        currentValue.disabled === false &&
        currentValue.assets?.e
      ) {
        return accumulator + currentValue.assets.e;
      }
      return accumulator;
    }, 0);

    if (dataMonth.length === 1 && dataMonth[0].monthActivity) {
      workedShowed = dataMonth[0].hours;
      wantingShowed = '00:00';
    }

    const newDataMonth = {
      currentMonth,
      isBeforeOfPlanned,
      goal,
      goalShowed,
      worked,
      workedShowed,
      workedAllShowed,
      wantingShowed,
      pubTotal,
      vidTotal,
      revTotal,
      stuTotal,
    };
    setMonth(newDataMonth);
  };

  useEffect(() => {
    setLoading(status === STATUS.FETCHING);
    if (status === STATUS.SUCCESS) {
      getMonthData({ date: moment(currentDate) });
      dispatch(readyForNextState());
    }

    if (status === STATUS.FAILURE) {
      console.log('error', error);
      dispatch(readyForNextState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return { loading, month, currentDate, getMonthData };
};

export default useMonth;
