import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  request as requestLogout,
  readyForNextState,
} from '../store/reducers/logout/logoutSlice';
import { STATUS } from '../config/constants';
import { resetState } from '../store/reducers/appConfig';
import useAppState from './useAppState';

export const useLogout = () => {
  const dispatch = useDispatch();
  const context = useAppState();
  const { status: statusLogout } = useSelector((state) => state.logout);

  const logout = () => {
    dispatch(requestLogout({ email: context.userLogged.email }));
  };

  useEffect(() => {
    if (statusLogout === STATUS.SUCCESS) {
      context.update('userLogged', undefined);
      dispatch(readyForNextState());
      dispatch(resetState());

      // setTimeout(() => {
      //   window.location.reload(true);
      // }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusLogout]);

  return { logout };
};

export default useLogout;
