import { v4 as uuidv4 } from 'uuid';

export const modifyActivitiesFromTo = (
  initialDate,
  lastDateOfMonth,
  activities,
  planning,
) => {
  while (initialDate.isBefore(lastDateOfMonth)) {
    Object.keys(planning).forEach((act) => {
      if (Number(act) === initialDate.isoWeekday() - 1) {
        for (const key in planning[act]) {
          const element = planning[act][key];
          if (!element.from || !element.to) {
            continue;
          }
          const start = new Date(
            initialDate.year(),
            initialDate.month(),
            initialDate.date(),
            element.from.split(':')[0],
            element.from.split(':')[1],
          ).toISOString();
          const end = new Date(
            initialDate.year(),
            initialDate.month(),
            initialDate.date(),
            element.to.split(':')[0],
            element.to.split(':')[1],
          ).toISOString();

          if (
            activities?.some(
              (activity) => activity.start === start && activity.end === end,
            )
          ) {
            continue;
          }

          activities.push({
            id: uuidv4(),
            title: `${element.from} - ${element.to}`,
            start,
            startPlanned: start,
            end,
            endPlanned: end,
            status: 0,
            extra: false,
            disabled: false,
          });
        }
      }
    });

    initialDate.add(1, 'd');
  }
};
