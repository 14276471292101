import React from 'react';
import { Provider } from 'react-redux';
import styles from './AppLayout.module.scss';

import AppLayout from './AppLayout';
import AppRouter from './AppRouter';
import { AppContextProvider } from './context/AppContext';
import { reduxStore } from './store/setupStore';
import { ConfigProvider, theme } from 'antd';
import { MessageProvider } from './context/MessageContext';
import { PersistGate } from 'redux-persist/integration/react';
import { useTranslation } from 'react-i18next';

const AppContainer = () => {
  const { store, persistor } = reduxStore();
  const { i18n } = useTranslation();

  const [isDarkMode, setIsDarkMode] = React.useState(false);

  const toggleMode = (darkMode) => {
    setIsDarkMode(darkMode);
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppContextProvider>
          <MessageProvider>
            <ConfigProvider
              locale={i18n.language}
              theme={{
                token: {
                  isDarkMode,
                  ok: styles['ok'],
                  okAlert: styles['ok-alert'],
                  highAlert: styles['high-alert'],
                  highAlert1: styles['high-alert1'],
                  mediumAlert1: styles['medium-alert1'],
                  colorBgContainer: isDarkMode
                    ? styles['d-bg-color']
                    : styles['l-bg-color'],
                  colorText: styles['l-primary-color'],
                  colorIcon: 'white',
                  colorError: isDarkMode
                    ? styles['d-error-color']
                    : styles['l-error-color'],
                  colorBorder: isDarkMode
                    ? styles['l-t-text-color']
                    : styles['d-t-text-color'],
                  colorBgElevated: isDarkMode
                    ? styles['d-i-bg-color']
                    : styles['l-i-bg-color'],
                  colorPrimary: isDarkMode
                    ? styles['d-primary-color']
                    : styles['l-primary-color'],
                  backgroundColor: isDarkMode
                    ? styles['d-bg-color']
                    : styles['l-bg-color'],
                  inputBackgroundColor: isDarkMode
                    ? styles['d-i-bg-color']
                    : styles['l-i-bg-color'],
                  inputTextColor: isDarkMode
                    ? styles['d-i-text-color']
                    : styles['l-i-text-color'],
                  textCalendarColor: isDarkMode ? 'red' : 'blue',
                  titleTextColor: isDarkMode
                    ? styles['d-t-text-color']
                    : styles['l-t-text-color'],
                  subtitleTextColor: isDarkMode
                    ? styles['d-st-text-color']
                    : styles['l-st-text-color'],
                  calendarBackgroundColor: isDarkMode
                    ? styles['d-calendar-bg-color']
                    : styles['l-calendar-bg-color'],
                  textColor: isDarkMode
                    ? styles['l-text-color']
                    : styles['d-text-color'],
                },
                components: {
                  Select: {
                    controlHeight: 25,
                  },
                  Message: {
                    contentBg: isDarkMode
                      ? styles['d-bg-color']
                      : styles['l-bg-color'],
                  },
                },
                algorithm: isDarkMode
                  ? theme.darkAlgorithm
                  : theme.compactAlgorithm,
              }}
            >
              <AppLayout toggleMode={toggleMode}>
                <AppRouter />
              </AppLayout>
            </ConfigProvider>
          </MessageProvider>
        </AppContextProvider>
      </PersistGate>
    </Provider>
  );
};

AppContainer.propTypes = {};

export default AppContainer;
