import { createContext } from 'react';
import { message, Grid } from 'antd';

const { useBreakpoint } = Grid;

const MessageContext = createContext({
  notification: (type, content, options) => ({ type, content, options }),
});

const MessageProvider = ({ children }) => {
  const { xs } = useBreakpoint();
  const messageConfig = xs ? { top: 50 } : undefined;
  const [messageApi, contextHolder] = message.useMessage(messageConfig);

  const notification = (type, content, options) => {
    messageApi.open({
      type: type,
      content: content,
      ...options,
    });
  };

  return (
    <MessageContext.Provider value={{ notification }}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

export { MessageContext, MessageProvider };
