import { Input } from 'antd';
import styles from './CustomPasswordInput.module.scss';
import { useState, forwardRef } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const CustomPasswordInput = forwardRef(
  (
    {
      token,
      register,
      getValues,
      handleInputChange,
      errors,
      placeholder,
      registerName,
      containerClass,
      ...others
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    let validationRules;

    if (registerName === 'password') {
      validationRules = {
        ...validationRules,
        minLength: { value: 6, message: t('password_min_length_error') },
      };
    }

    if (registerName === 'password_confirmation') {
      validationRules = {
        ...validationRules,
        minLength: { value: 6, message: t('password_min_length_error') },
        validate: (value) => {
          if (value === getValues('password')) {
            return true;
          } else {
            return t('password_confirmation_error');
          }
        },
      };
    }

    return (
      <div className={containerClass}>
        <Input
          {...others}
          className={styles.passwordInput}
          style={{
            backgroundColor: token.inputBackgroundColor,
            color: token.inputTextColor,
          }}
          {...register(registerName, validationRules)}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={handleInputChange}
          status={errors.password ? 'error' : 'validating'}
          ref={ref}
        />
        {showPassword ? (
          <EyeInvisibleOutlined
            className={styles.passwordIcon}
            onClick={togglePasswordVisibility}
          />
        ) : (
          <EyeOutlined
            className={styles.passwordIcon}
            onClick={togglePasswordVisibility}
          />
        )}
        {errors[registerName] && (
          <span className={styles.errorMessage}>
            {errors[registerName].message}
          </span>
        )}
      </div>
    );
  },
);
CustomPasswordInput.displayName = 'CustomPasswordInput';

export default CustomPasswordInput;
