import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  request as requestSendActivities,
  readyForNextState,
} from '../../store/reducers/activities/sendActivitiesSlice';
import useAppState from '../../hooks/useAppState';
import { STATUS } from '../../config/constants';
import { MessageContext } from '../../context/MessageContext';

export const useUpdateActivityMonth = ({ currentDate, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: dataActivities } = useSelector((state) => state.activities);
  const { status, error } = useSelector((state) => state.sendActivities);
  const { userLogged } = useAppState();
  const { notification } = useContext(MessageContext);

  const sendActivityMonth = (newMonthData) => {
    const activityMonth = dataActivities[userLogged.email].find(
      (activity) =>
        activity.monthActivity &&
        moment(activity.start).format('MMMM YYYY') ===
          moment(currentDate).format('MMMM YYYY'),
    );
    if (!activityMonth) {
      addActivityMonth(newMonthData);
    } else {
      handleUpdateResults(activityMonth.id, newMonthData);
    }
  };

  const addActivityMonth = (event) => {
    let newActivities = [...dataActivities[userLogged.email]];
    let currentEvent = {
      id: uuidv4(),
      ...event,
      start: moment(currentDate).toDate().toISOString(),
      label: moment(currentDate).format('MMMM YYYY'),
      status: 2,
    };

    newActivities.push(currentEvent);
    newActivities = newActivities.sort((a, b) =>
      a.start.localeCompare(b.start),
    );

    dispatch(
      requestSendActivities({
        activities: newActivities,
        email: userLogged.email,
      }),
    );
  };

  const handleUpdateResults = (id, activity) => {
    let newActivities = [...dataActivities[userLogged.email]];
    const currentEventIndex = newActivities.findIndex(
      (result) => result.id === id,
    );
    newActivities[currentEventIndex] = {
      ...newActivities[currentEventIndex],
      ...activity,
    };
    dispatch(
      requestSendActivities({
        activities: newActivities,
        email: userLogged.email,
      }),
    );
  };

  useEffect(() => {
    if (status === STATUS.SUCCESS) {
      setIsModalOpen(false);
      dispatch(readyForNextState());
    }

    if (status === STATUS.FAILURE) {
      const { message } = error;
      notification('error', t(message));
      dispatch(readyForNextState());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return {
    sendActivityMonth,
    addActivityMonth,
    handleUpdateResults,
  };
};

export default useUpdateActivityMonth;
