import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CustomRichText.scss';
import { useEffect, useState } from 'react';

const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['link', 'image'],
      ['clean'],
      ['save'],
    ],
    handlers: {
      save: function () {},
    },
  },
};

function CustomRichText({
  className,
  placeholder,
  value,
  saveHandler,
  loading,
}) {
  const [editorHtml, setEditorHtml] = useState(value);
  const quillRef = useRef(null);

  useEffect(() => {
    if (loading) {
      const saveIcon = document.querySelector('.ql-save');

      if (saveIcon) {
        saveIcon.classList.toggle('ql-save', !loading);
        saveIcon.classList.toggle('ql-loading', loading);
      }
    } else {
      const loadingIcon = document.querySelector('.ql-loading');

      if (loadingIcon) {
        loadingIcon.classList.toggle('ql-save', !loading);
        loadingIcon.classList.toggle('ql-loading', loading);
      }
    }
  }, [loading]);

  function handleSaveButton() {
    if (quillRef.current) {
      const quillInstance = quillRef.current.getEditor();
      const content = quillInstance.root.innerHTML;
      saveHandler(content);
    }
  }

  modules.toolbar.handlers.save = handleSaveButton;

  useEffect(() => {
    setEditorHtml(value);
  }, [value]);

  return (
    <ReactQuill
      value={editorHtml}
      theme="snow"
      onChange={setEditorHtml}
      ref={quillRef}
      modules={modules}
      className={className}
      placeholder={placeholder}
    />
  );
}

export default CustomRichText;
