import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tourPlan } from '../../store/reducers/appConfig';

const useTour = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hideTour: hideTourPlan } = useSelector((state) => state.appConfig);

  const getSteps = () => [
    {
      title: t('goal'),
      description: t('tour1'),
      target: () => ref1.current,
      nextButtonProps: { children: <span>{t('next')}</span> },
    },
    {
      title: t('yourPlan'),
      description: t('tour2'),
      target: () => ref2.current,
      nextButtonProps: { children: <span>{t('next')}</span> },
      prevButtonProps: { children: <span>{t('previous')}</span> },
    },
    {
      title: t('daysAndHours'),
      description: t('tour3'),
      target: () => ref3.current,
      nextButtonProps: { children: <span>{t('next')}</span> },
      prevButtonProps: { children: <span>{t('previous')}</span> },
    },
    {
      title: t('create'),
      description: t('tour4'),
      target: () => ref4.current,
      nextButtonProps: { children: <span>{t('finish')}</span> },
      prevButtonProps: { children: <span>{t('previous')}</span> },
    },
  ];

  const hideTour = () => {
    window.scrollTo(0, 0);
    dispatch(tourPlan({ hideTour: true }));
  };

  const showTour = () => {
    return hideTourPlan === false;
  };

  return {
    getSteps,
    showTour,
    hideTour,
    ref1,
    ref2,
    ref3,
    ref4,
  };
};

export default useTour;
