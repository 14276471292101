import bootstrapSlice from './bootstrapSlice';
import signInSlice from './signin/signInSlice';
import signUpSlice from './signup/signUpSlice';
import usersSlice from './users/usersSlice';
import plansSlice from './plans/plansSlice';
import sendPlansSlice from './plans/sendPlansSlice';
import getPlansSlice from './plans/getPlansSlice';
import deletePlansSlice from './plans/deletePlansSlice';
import plansTargetSlice from './plansTarget/plansTargetSlice';
import sendPlansTargetSlice from './plansTarget/sendPlansTargetSlice';
import getPlansTargetSlice from './plansTarget/getPlansTargetSlice';
import getAllPlansTargetSlice from './plansTarget/getAllPlansTargetSlice';
import deletePlansTargetSlice from './plansTarget/deletePlansTargetSlice';
import activitiesSlice from './activities/activitiesSlice';
import sendActivitiesSlice from './activities/sendActivitiesSlice';
import sendActivitiesSplitedSlice from './activities/sendActivitiesSplitedSlice';
import getActivitiesSlice from './activities/getActivitiesSlice';
import updateActivitiesSlice from './activities/updateActivitiesSlice';
import deleteActivitiesSlice from './activities/deleteActivitiesSlice';
import logoutSlice from './logout/logoutSlice';
import deleteAccountSlice from './deleteAccount/deleteAccountSlice';
import darkModeSlice from './darkMode/darkModeSlice';
import appConfigSlice from './appConfig';
import refreshCalendarSlice from './refreshCalendar/refreshCalendarSlice';
import commentsSlice from './comments/commentsSlice';
import deleteCommentsSlice from './comments/deleteCommentsSlice';

const reducers = {
  appConfig: appConfigSlice,
  bootstrap: bootstrapSlice,
  signup: signUpSlice,
  signin: signInSlice,
  users: usersSlice,
  getPlans: getPlansSlice,
  sendPlans: sendPlansSlice,
  deletePlans: deletePlansSlice,
  plansTarget: plansTargetSlice,
  sendPlansTarget: sendPlansTargetSlice,
  getPlansTarget: getPlansTargetSlice,
  getAllPlansTarget: getAllPlansTargetSlice,
  deletePlansTarget: deletePlansTargetSlice,
  plans: plansSlice,
  activities: activitiesSlice,
  sendActivities: sendActivitiesSlice,
  sendActivitiesSplited: sendActivitiesSplitedSlice,
  getActivities: getActivitiesSlice,
  updateActivities: updateActivitiesSlice,
  deleteActivities: deleteActivitiesSlice,
  logout: logoutSlice,
  deleteAccount: deleteAccountSlice,
  darkMode: darkModeSlice,
  refreshCalendar: refreshCalendarSlice,
  comments: commentsSlice,
  deleteComments: deleteCommentsSlice,
};

export default reducers;
