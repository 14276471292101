import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const plansTargetSlice = createSlice({
  name: "plansTarget",
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = undefined;
    },
    resetStateByEmail: (state, action) => {
      delete state.data[action.payload.email];
    },
    success: (state, action) => {
      state.data = action.payload;
    },
    updateState: (state, action) => {
      state.data[action.payload.email] = action.payload.plansTarget;
    },
  },
});

export const { resetState, resetStateByEmail, success, updateState } = plansTargetSlice.actions;

export const data = (state) => state.plansTarget.data;

export default plansTargetSlice.reducer;
