import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Select, Tooltip, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import { ImExit } from 'react-icons/im';
import styles from './HeaderApp.module.scss';
import Logo from '../../assets/images/logo.png';
import useLogout from '../../hooks/useLogout';
import useAppState from '../../hooks/useAppState';
import useResponsive from '../../hooks/useResponsive';

const { useToken } = theme;

const HeaderApp = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const { logout } = useLogout();
  const [openModal, setOpenModal] = useState();
  const context = useAppState();
  const { isMobile } = useResponsive();

  const hi = () => {
    var data = [
      [22, t('is_very_later')],
      [20, t('good_night')],
      [14, t('good_afternoon')],
      [9, t('good_morning')],
      [5, t('getting_up_early')],
      [0, t('to_sleep')],
    ];
    const hr = new Date().getHours();
    for (let i = 0; i < data.length; i++) {
      if (hr >= data[i][0]) {
        return data[i][1];
      }
    }
  };

  const changeLang = (value) => {
    context.update('language', value);
    changeLanguage(value);
  };

  useEffect(() => {
    if (context.language === '') {
      context.update(
        'language',
        window.navigator.language ?? navigator.browserLanguage ?? 'en',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={styles.Header}
      style={{ backgroundColor: token.backgroundColor }}
    >
      <div className={styles.rightSide}>
        <div className={styles.logoWrapper}>
          <Link to={{ pathname: '/' }}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className={styles.gretting}>
          <span
            className={styles.welcome}
            style={{ color: token.titleTextColor }}
          >
            {hi()}
          </span>
          <Link to={{ pathname: '/profile' }} className={styles.person}>
            {` ${context.userLogged.displayName}`}
          </Link>
        </div>
      </div>

      <div className={styles.leftSide}>
        <Select
          defaultValue={context.language}
          value={
            context.language.includes('-')
              ? context.language.split('-'[0])
              : context.language
          }
          style={{ width: isMobile ? 60 : 70 }}
          bordered={false}
          options={[
            { value: 'es', label: 'ES' },
            { value: 'en', label: 'EN' },
          ]}
          onChange={(value) => changeLang(value)}
        />
        <Tooltip title={t('goOut')}>
          <Button
            type="primary"
            style={{
              fontSize: 'large',
              cursor: 'pointer',
              color: token.colorIcon,
            }}
            icon={<ImExit />}
            onClick={() => setOpenModal(true)}
          />
        </Tooltip>
      </div>
      <Modal
        title={t('close_session')}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setOpenModal(false)}>
            {t('no')}
          </Button>,
          <Button key="confirm" type="primary" onClick={logout}>
            {t('yes')}
          </Button>,
        ]}
      >
        {t('really_leaving')}
      </Modal>
    </div>
  );
};

export default HeaderApp;
