import { Col, Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import './TableSkeleton.scss';

const TableSkeleton = ({ rowNumber, colNumber, visible }) => {
  if (visible) {
    return (
      <>
        {[...Array(rowNumber)].map((e, index) => (
          <Row key={index} gutter={[16, 24]}>
            {[...Array(colNumber)].map((e, i) => (
              <Col key={i} span={24 / colNumber}>
                <Skeleton.Input block active />
              </Col>
            ))}
          </Row>
        ))}
      </>
    );
  }
};

TableSkeleton.propTypes = {
  rowNumber: PropTypes.number.isRequired,
  colNumber: PropTypes.oneOf([2, 3, 4, 6, 8, 12]).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default TableSkeleton;
